import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, FormControl, RadioGroup, FormControlLabel, Radio, Typography, CircularProgress } from '@mui/material';
import Plotly from 'plotly.js-dist';
import { getUsaByStateTerritory, getUsaByCountry } from '../../networking/entity/entityGeoCalls';

const ResultsByGeography = ({ recipientData }) => {
  const recipientID = recipientData.recipient_id;
  const [mapType, setMapType] = useState('States & Territories');
  const [statesData, setStatesData] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [totalStatesAmount, setTotalStatesAmount] = useState(0);
  const [totalCountriesAmount, setTotalCountriesAmount] = useState(0);
  const [loading, setLoading] = useState(true); // State for loading indicator

  // Fetch data for States & Territories and Countries
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const [fetchedStatesData, fetchedCountriesData] = await Promise.all([
          getUsaByStateTerritory(recipientID),
          getUsaByCountry(recipientID)
        ]);

        const totalStates = fetchedStatesData.reduce((acc, state) => acc + state.amount, 0);
        const totalCountries = fetchedCountriesData.reduce((acc, country) => acc + country.amount, 0);

        setStatesData(fetchedStatesData);
        setCountriesData(fetchedCountriesData);
        setTotalStatesAmount(totalStates);
        setTotalCountriesAmount(totalCountries);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchData();
  }, [recipientID]);

  // Function to draw the map based on the selected map type
  const drawMap = () => {
    const data = mapType === 'States & Territories' ? [{
      type: 'choropleth',
      locationmode: 'USA-states',
      locations: statesData.map((item) => item.code),
      z: statesData.map((item) => item.amount),
      text: statesData.map((item) => item.name),
      colorscale: [
        [0, 'rgb(224,236,244)'],
        [1, 'rgb(8,48,107)']
      ],
      showscale: false,
      hovertemplate: '<b>%{text}</b><br>Spending: $%{z:,.0f}<extra></extra>',
    }] : [{
      type: 'choropleth',
      locationmode: 'ISO-3',
      locations: countriesData.map((item) => item.code),
      z: countriesData.map((item) => item.amount),
      text: countriesData.map((item) => item.name),
      colorscale: [
        [0, 'rgb(224,236,244)'],
        [1, 'rgb(8,48,107)']
      ],
      showscale: false,
      hovertemplate: '<b>%{text}</b><br>Spending: $%{z:,.0f}<extra></extra>',
    }];

    const layout = {
      geo: {
        scope: mapType === 'States & Territories' ? 'usa' : 'world',
        projection: { type: mapType === 'States & Territories' ? 'albers usa' : 'mercator' },
        showlakes: true,
        showframe: true,
        showcoastlines: true,
        lakecolor: 'rgb(255, 255, 255)',
        dragmode: false,
        resolution: 30,
        lataxis: { range: [-30, 90] },
      },
      autosize: true,
      margin: { t: 0, r: 0, l: 0, b: 0 },
    };

    const config = { scrollZoom: false, responsive: true, displayModeBar: false };

    Plotly.newPlot('mapDiv', data, layout, config);
  };

  useEffect(() => {
    if (!loading) {
      drawMap(); // Draw the map whenever mapType changes
    }
  }, [mapType, statesData, countriesData, loading]);

  return (
    <Box sx={{ p: 3 }}>
      {/* Display loading spinner while data is loading */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Radio Button Group for Map Type */}
          <FormControl component="fieldset" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 3 }}>
            <RadioGroup
              row
              aria-label="map type"
              name="map-type"
              value={mapType}
              onChange={(e) => setMapType(e.target.value)}
              sx={{ '& .MuiFormControlLabel-root': { mx: 2, '& .MuiTypography-root': { fontSize: '1.25rem' } } }}
            >
              <FormControlLabel value="States & Territories" control={<Radio size="medium" />} label="States & Territories" />
              <FormControlLabel value="Countries" control={<Radio size="medium" />} label="Countries" />
            </RadioGroup>
          </FormControl>

          {/* Map Display */}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 3 }}>
            <div id="mapDiv" style={{ width: '100%', height: '60vh' }}></div>
          </Box>

          {/* Tables Below the Map */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  States & Territories
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Name</TableCell>
                        <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Awarded Amount</TableCell>
                        <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>% of Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {statesData.map((state, index) => (
                        <TableRow key={index}>
                          <TableCell>{state.name}</TableCell>
                          <TableCell>{`$${state.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</TableCell>
                          <TableCell>{`${((state.amount / totalStatesAmount) * 100).toFixed(2)}%`}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Countries
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Name</TableCell>
                        <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Awarded Amount</TableCell>
                        <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>% of Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {countriesData.map((country, index) => (
                        <TableRow key={index}>
                          <TableCell>{country.name}</TableCell>
                          <TableCell>{`$${country.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</TableCell>
                          <TableCell>{`${((country.amount / totalCountriesAmount) * 100).toFixed(2)}%`}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default ResultsByGeography;