import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, app } from '../../firebase'; // Ensure you have your Firebase app initialized

// Initialize Firebase Functions
const functions = getFunctions(app);

// Define a function to call the getDashboardAwardsWithFilters cloud function
export const getDashboardAwardsWithFilters = async (filterObject, awardType, page) => {
  // Reference to the cloud function

  // console.log("dashCalls keywords: " + JSON.stringify(keywords));
  // console.log("dashCalls awardType: " + awardType);

  const fetchDashboardAwards = httpsCallable(functions, 'getDashboardAwardsWithFilters');
  
  try {
    // Call the cloud function with the required data
    const result = await fetchDashboardAwards({ filterObject, awardType, page });
    
    return result.data.awards; // Assuming the function returns data in result.data
  } catch (error) {
    console.error('Error calling cloud function:', error);
    throw error; // Handle or rethrow the error as needed
  }
};




// Define a function to call the getUsaOfficesForAgency cloud function
export const getUsaOfficesForAgency = async (agency) => {
  // Reference to the cloud function

  // console.log("dashCalls keywords: " + JSON.stringify(keywords));
  // console.log("dashCalls awardType: " + awardType);

  const getOfficesForAgency = httpsCallable(functions, 'getUsaOfficesForAgency');
  
  try {
    // Call the cloud function with the required data
    const result = await getOfficesForAgency({ agency });

    console.log("result.data.offices: " + result.data.offices.length);
    
    return result.data.offices; // Assuming the function returns data in result.data
  } catch (error) {
    console.error('Error calling cloud function:', error);
    throw error; // Handle or rethrow the error as needed
  }
};






// export const realtimeSearchOfficesForAgency = async (searchString) => {
//   try {
//     const officesRef = collection(db, 'officesForAgency'); // Get a reference to the collection
//     const q = query(officesRef, where('searchableName', 'array-contains', searchString)); // Construct query
//     const querySnapshot = await getDocs(q); // Get documents based on the query
    
//     // Map over the querySnapshot to return an array of office documents
//     const offices = querySnapshot.docs.map(doc => ({
//       id: doc.id,
//       ...doc.data()
//     }));

//     console.log("office count: " + offices.length);
    
//     return offices;
//   } catch (error) {
//     console.error('Error fetching offices: ', error);
//     return [];
//   }
// };

export const realtimeSearchOfficesForAgency = async (searchString) => {
  try {
    const officesRef = collection(db, 'officesForAgency');
    const q = query(officesRef, where('searchableName', 'array-contains', searchString.toLowerCase()));
    const querySnapshot = await getDocs(q);
    
    const offices = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    
    return offices;
  } catch (error) {
    console.error('Error fetching offices: ', error);
    return [];
  }
};