import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, CircularProgress } from '@mui/material';
import { getUsaAwardDetails } from '../../networking/contract/contractCalls';

const ContractDetails = ({ awardID, tabValue, awardDetails }) => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading indicator
  const fetchedRef = useRef(false);

  const selectedDashboardTab = JSON.parse(sessionStorage.getItem('selectedDashboardTab')) || 0;

  useEffect(() => {
    if (awardDetails) {
      parseAwardDetails(awardDetails);
      setLoading(false); // Stop loading once details are parsed
    }
  }, [awardDetails]);

  const parseAwardDetails = (details) => {
    const cityName = details.place_of_performance?.city_name || '';
    const stateCode = details.place_of_performance?.state_code || '';

    // Construct placeOfPerformance based on the availability of cityName and stateCode
    let placeOfPerformance = '';
    if (cityName && stateCode) {
      placeOfPerformance = `${cityName}, ${stateCode}`;
    } else if (stateCode) {
      placeOfPerformance = stateCode;
    } else if (cityName) {
      placeOfPerformance = cityName;
    }

    setDetails({
      contractTitle: details.description || '',
      obligatedAmount:
        selectedDashboardTab === 0
          ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(details.total_account_outlay || 0)
          : Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(details.total_obligation || 0),
      contractObligatedAmount:
        selectedDashboardTab === 0
          ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(details.total_account_obligation || 0)
          : Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(details.base_exercised_options || 0),
      potentialAwardAmount: Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(details.base_and_all_options || 0),
      startDate: details.period_of_performance?.start_date || '',
      orderingPeriodEndDate: details.period_of_performance?.end_date || '',
      placeOfPerformance, // Use the constructed placeOfPerformance
    });
  };

  // Show loading spinner while data is loading
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6">{awardDetails.piid || ''}</Typography>
      <Typography variant="subtitle1">{details.contractTitle}</Typography>

      <Paper sx={{ p: 2, mt: 3 }}>
        <Typography variant="h6" gutterBottom>
          Award Details
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Obligated Amount</TableCell>
                <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>{details.obligatedAmount}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Contract Obligated Amount</TableCell>
                <TableCell>{details.contractObligatedAmount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Potential Award Amount</TableCell>
                <TableCell>{details.potentialAwardAmount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell>{details.startDate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ordering Period End Date</TableCell>
                <TableCell>{details.orderingPeriodEndDate}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Place of Performance</TableCell>
                <TableCell>{details.placeOfPerformance}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

ContractDetails.RightTable = ({ awardDetails }) => {
  if (!awardDetails) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const agencyDetailsData = {
    agencyName: awardDetails.awarding_agency?.toptier_agency?.name || '',
    contractType: awardDetails.latest_transaction_contract_data?.type_of_contract_pricing_description 
      || awardDetails.type_description 
      || '',
    awardType: awardDetails.type_description || '',
    recipient: `${awardDetails.recipient?.recipient_name || ''}\n${awardDetails.recipient?.location?.address_line1 || ''}\n${awardDetails.recipient?.location?.city_name || ''}, ${awardDetails.recipient?.location?.state_code || ''} ${awardDetails.recipient?.location?.zip5 || ''}`,
    uei: awardDetails.recipient?.recipient_uei || '',
    naicsCode: `${awardDetails.latest_transaction_contract_data?.naics || ''}: ${awardDetails.latest_transaction_contract_data?.naics_description || ''}`,
    psc: `${awardDetails.psc_hierarchy?.base_code?.code || ''}: ${awardDetails.psc_hierarchy?.base_code?.description || ''}`,
    submittingAgencyCode: awardDetails.awarding_agency?.subtier_agency?.code || '',
    subAgency: awardDetails.awarding_agency?.subtier_agency?.name || '',
    awardOffice: awardDetails.awarding_agency?.office_agency_name || '',
    solicitationId: awardDetails.latest_transaction_contract_data?.solicitation_identifier || '',
    setAsideType: awardDetails.latest_transaction_contract_data?.type_set_aside_description || '',
    solicitationProcedures: awardDetails.latest_transaction_contract_data?.solicitation_procedures || '',
    extentCompleted: awardDetails.latest_transaction_contract_data?.extent_competed_description || '',
    offersReceived: awardDetails.latest_transaction_contract_data?.number_of_offers_received || 0,
  };

  const formattedRecipient = agencyDetailsData.recipient.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <Paper sx={{ p: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Awarding Agency</TableCell>
              <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>{agencyDetailsData.agencyName}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Contract Type</TableCell>
              <TableCell>{agencyDetailsData.contractType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Award Type</TableCell>
              <TableCell>{agencyDetailsData.awardType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Recipient</TableCell>
              <TableCell>{formattedRecipient}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>UEI</TableCell>
              <TableCell>{agencyDetailsData.uei}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>NAICS Code</TableCell>
              <TableCell>{agencyDetailsData.naicsCode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PSC</TableCell>
              <TableCell>{agencyDetailsData.psc}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Submitting Agency Identifier Code</TableCell>
              <TableCell>{agencyDetailsData.submittingAgencyCode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sub-Agency</TableCell>
              <TableCell>{agencyDetailsData.subAgency}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Award Office</TableCell>
              <TableCell>{agencyDetailsData.awardOffice}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Solicitation ID</TableCell>
              <TableCell>{agencyDetailsData.solicitationId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Set-Aside Type</TableCell>
              <TableCell>{agencyDetailsData.setAsideType}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Solicitation Procedures</TableCell>
              <TableCell>{agencyDetailsData.solicitationProcedures}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Extent Completed</TableCell>
              <TableCell>{agencyDetailsData.extentCompleted}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Number of Offers Received</TableCell>
              <TableCell>{agencyDetailsData.offersReceived}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ContractDetails;