import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import { calculateAwardingAgencies } from '../../utils/helpers/entityHelpers';
import { getUsaAwardingAgenciesData, getUsaProductServiceCodesData, getUsaNaicsCodesData } from '../../networking/entity/entityCalls';

const EntityDetails = ({ recipientName, recipientData }) => {
  const [awardingAgencies, setAwardingAgencies] = useState([]);
  const [productServiceCodes, setProductServiceCodes] = useState([]);
  const [naicsCodes, setNaicsCodes] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading indicator

  var agencyTotal = 0;
  for (var i = 0; i < awardingAgencies.length; i++) {
    agencyTotal += awardingAgencies[i].amount;
  }

  var pscTotal = 0;
  for (var i = 0; i < productServiceCodes.length; i++) {
    pscTotal += productServiceCodes[i].amount;
  }

  var naicsTotal = 0;
  for (var i = 0; i < naicsCodes.length; i++) {
    naicsTotal += naicsCodes[i].amount;
  }

  useEffect(() => {
    // Perform all queries at the same time for speed
    const fetchData = async () => {
      try {
        const [agenciesData, pscData, naicsData] = await Promise.all([
          getUsaAwardingAgenciesData(recipientData.recipient_id),
          getUsaProductServiceCodesData(recipientData.recipient_id),
          getUsaNaicsCodesData(recipientData.recipient_id),
        ]);

        setAwardingAgencies(agenciesData);
        setProductServiceCodes(pscData);
        setNaicsCodes(naicsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Stop loading when data fetching is complete
      }
    };

    fetchData();
  }, [recipientData]);

  // Show loading spinner while data is loading
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={3}>
      {/* Left-side tables */}
      <Grid item xs={12} md={6}>
        {/* Entity Table */}
        <Paper sx={{ p: 2, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Recipient
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                    Recipient Identifier
                  </TableCell>
                  <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                    {recipientData.uei} [UEI]<br />{recipientData.duns} [Legacy DUNS]
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Address</TableCell>
                  <TableCell>
                    {recipientData.location.address_line1}<br />
                    {recipientData.location.city_name}, {recipientData.location.state_code} {recipientData.location.zip}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Business Types</TableCell>
                  <TableCell>
                    {recipientData.business_types.map((type, index) => (
                      <React.Fragment key={index}>
                        {type
                          .split('_') // Split the string by underscores
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
                          .join(' ')} {/* Join the capitalized words with a space */}
                        <br /> {/* Add a line break after each type */}
                      </React.Fragment>
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Congressional District</TableCell>
                  <TableCell>{recipientData.location.state_code}-{recipientData.location.congressional_code}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* NAICS Codes Table */}
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            NAICS Codes
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Awarded Amount</TableCell>
                  <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>% of Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {naicsCodes.map((naics, index) => (
                  <TableRow key={index}>
                    <TableCell>{naics.name}</TableCell>
                    <TableCell>
                      {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(naics.amount)}
                    </TableCell>
                    <TableCell>
                      {Math.round((naics.amount / naicsTotal) * 100)}%
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>

      {/* Right-side tables */}
      <Grid item xs={12} md={6}>
        <Grid container spacing={3}>
          {/* Awarding Agencies Table */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Awarding Agencies
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Name</TableCell>
                      <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Awarded Amount</TableCell>
                      <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>% of Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {awardingAgencies.map((agency, index) => (
                      <TableRow key={index}>
                        <TableCell>{agency.name}</TableCell>
                        <TableCell>
                          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(agency.amount)}
                        </TableCell>
                        <TableCell>
                          {Math.round((agency.amount / agencyTotal) * 100)}%
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          {/* Product Service Codes Table */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Product Service Codes
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Name</TableCell>
                      <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Awarded Amount</TableCell>
                      <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>% of Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productServiceCodes.map((psc, index) => (
                      <TableRow key={index}>
                        <TableCell>{psc.code} - {psc.name}</TableCell>
                        <TableCell>
                          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(psc.amount)}
                        </TableCell>
                        <TableCell>
                          {Math.round((psc.amount / pscTotal) * 100)}%
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EntityDetails;