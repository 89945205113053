import { getFunctions, httpsCallable } from 'firebase/functions';
import { db, app } from '../../firebase'; // Ensure you have your Firebase app initialized

// Initialize Firebase Functions
const functions = getFunctions(app);




// Define a function to call the getUsaRecipientDetails cloud function
export const getUsaRecipientDetails = async (recipientID) => {

  // Reference to the cloud function
  const getRecipientDetails = httpsCallable(functions, 'getUsaRecipientDetails');
  
  try {
    // Call the cloud function with the required data
    const result = await getRecipientDetails({ recipientID });
    
    return result.data.recipientDetail; // Assuming the function returns data in result.data
  } catch (error) {
    console.error('Error calling cloud function:', error);
    throw error; // Handle or rethrow the error as needed
  }
};






// Define a function to call the getUsaAwardingAgenciesData cloud function
export const getUsaAwardingAgenciesData = async (recipientID) => {

    // Reference to the cloud function
    const getAwardingAgenciesData = httpsCallable(functions, 'getUsaAwardingAgenciesData');
    
    try {
      // Call the cloud function with the required data
      const result = await getAwardingAgenciesData({ recipientID });

    //   console.log("result.data.awardingAgenciesData: " + JSON.stringify(result.data.awardingAgenciesData));
      
      return result.data.awardingAgenciesData; // Assuming the function returns data in result.data
    } catch (error) {
      console.error('Error calling cloud function:', error);
      throw error; // Handle or rethrow the error as needed
    }
  };





  // Define a function to call the getUsaProductServiceCodesData cloud function
export const getUsaProductServiceCodesData = async (recipientID) => {

    // Reference to the cloud function
    const getProductServiceCodesData = httpsCallable(functions, 'getUsaProductServiceCodesData');
    
    try {
      // Call the cloud function with the required data
      const result = await getProductServiceCodesData({ recipientID });
      
      return result.data.productServiceCodesData; // Assuming the function returns data in result.data
    } catch (error) {
      console.error('Error calling cloud function:', error);
      throw error; // Handle or rethrow the error as needed
    }
  };





  // Define a function to call the getUsaNaicsCodesData cloud function
export const getUsaNaicsCodesData = async (recipientID) => {

    // Reference to the cloud function
    const getNaicsCodesData = httpsCallable(functions, 'getUsaNaicsCodesData');
    
    try {
      // Call the cloud function with the required data
      const result = await getNaicsCodesData({ recipientID });
      
      return result.data.naicsCodesData; // Assuming the function returns data in result.data
    } catch (error) {
      console.error('Error calling cloud function:', error);
      throw error; // Handle or rethrow the error as needed
    }
  };