import { getFunctions, httpsCallable } from 'firebase/functions';
import { db, app } from '../../firebase'; // Ensure you have your Firebase app initialized

// Initialize Firebase Functions
const functions = getFunctions(app);




// Define a function to call the getUsaRecipientAwards cloud function
// export const getUsaRecipientAwards = async (recipientID, page) => {

//   // Reference to the cloud function
//   const getRecipientAwards = httpsCallable(functions, 'getUsaRecipientAwards');
  
//   try {
//     // Call the cloud function with the required data
//     const result = await getRecipientAwards({ recipientID, page });

//     // console.log("result.data.spendingDataOverTime: " + JSON.stringify(result.data.spendingDataOverTime));
    
//     return result.data.awards; // Assuming the function returns data in result.data
//   } catch (error) {
//     console.error('Error calling cloud function:', error);
//     throw error; // Handle or rethrow the error as needed
//   }
// };



// Define a function to call the getUsaRecipientAwards cloud function
export const getUsaRecipientAwardsByType = async (awardType, recipientID, page) => {

  // Reference to the cloud function
  const getRecipientAwardsByType = httpsCallable(functions, 'getUsaRecipientAwardsByType');
  
  try {
    // Call the cloud function with the required data
    const result = await getRecipientAwardsByType({ awardType, recipientID, page });

    // console.log("result.data.spendingDataOverTime: " + JSON.stringify(result.data.spendingDataOverTime));
    
    return result.data.awards; // Assuming the function returns data in result.data
  } catch (error) {
    console.error('Error calling cloud function:', error);
    throw error; // Handle or rethrow the error as needed
  }
};