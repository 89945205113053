import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const BreadcrumbsNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathnames = location.pathname.split('/').filter((x) => x);

  const breadcrumbs = [];

  // Add Home link
  breadcrumbs.push(
    <Link
      key="home"
      color="inherit"
      onClick={() => navigate('/')}
      style={{ cursor: 'pointer', fontSize: '0.875rem', color: '#1976d2' }} // Adjusted font size and color
      underline="hover" // Underline on hover
    >
      HOME
    </Link>
  );

  // Function to check if a path segment looks like an ID (UUIDs, numeric IDs, etc.)
  const isID = (segment) => {
    const uuidRegex = /^[0-9a-fA-F-]{36}$/; // Matches UUIDs
    const numericRegex = /^\d+$/; // Matches numeric IDs
    const customIdRegex = /^[0-9a-fA-F-]{20,}$/; // Matches custom IDs of a certain length

    return uuidRegex.test(segment) || numericRegex.test(segment) || customIdRegex.test(segment);
  };

  // Iterate through the pathnames to build the full breadcrumb trail
  pathnames.forEach((value, index) => {
    let to = `/${pathnames.slice(0, index + 1).join('/')}`;

    // Handle entity breadcrumb to retain recipient name
    if (value === 'entity' && pathnames[index + 1]) {
      to = `/${value}/${pathnames[index + 1]}`;
    }

    // Handle contract breadcrumb to retain contract ID
    if (value === 'contract' && pathnames[index + 1]) {
      to = `/${value}/${pathnames[index + 1]}`;
    }

    // Handle delivery-order breadcrumb to retain delivery order ID
    if (value === 'delivery-order' && pathnames[index + 1]) {
      to = `/${pathnames.slice(0, index).join('/')}/delivery-order/${pathnames[index + 1]}`;
    }

    // Decode the URI component for display
    const decodedValue = decodeURIComponent(value);

    // Check if it's the last item
    if (index === pathnames.length - 1) {
      breadcrumbs.push(
        <Typography
          key={to}
          color="text.primary"
          style={{ fontSize: '0.875rem', fontWeight: 'bold' }} // Bold and smaller font for the last item
        >
          {decodedValue.toUpperCase()}
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          key={to}
          color="inherit"
          onClick={() => navigate(to)}
          style={{ cursor: 'pointer', fontSize: '0.875rem', color: '#1976d2' }} // Adjusted font size and color
          underline="hover" // Underline on hover
        >
          {decodedValue.toUpperCase()}
        </Link>
      );
    }
  });

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        marginBottom: 2,
        padding: '8px 16px', // Add some padding around the breadcrumbs
        backgroundColor: '#f5f5f5', // Light gray background
        borderRadius: '4px', // Rounded corners
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)', // Subtle shadow
      }}
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

export default BreadcrumbsNav;