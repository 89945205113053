
export const FEDERAL_AGENCIES = [
    {
      "parent": "Department of Defense",
      "abbreviation": "DOD",
      "subAgencies": [
        {
          "parent": "Department of the Navy",
          "abbreviation": "USN",
          "subAgencies": []
        },
        {
          "parent": "Department of the Air Force",
          "abbreviation": "USAF",
          "subAgencies": []
        },
        {
          "parent": "Department of the Army",
          "abbreviation": "USA",
          "subAgencies": []
        },
        {
          "parent": "Defense Logistics Agency",
          "abbreviation": "DLA",
          "subAgencies": []
        },
        {
          "parent": "Defense Health Agency",
          "abbreviation": "DHA",
          "subAgencies": []
        },
        {
          "parent": "Missile Defense Agency",
          "abbreviation": "MDA",
          "subAgencies": []
        },
        {
          "parent": "Defense Information Systems Agency",
          "abbreviation": "DISA",
          "subAgencies": []
        },
        {
          "parent": "USTRANSCOM",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "U.S. Special Operations Command",
          "abbreviation": "USSOCOM",
          "subAgencies": []
        },
        {
          "parent": "Washington Headquarters Services",
          "abbreviation": "WHS",
          "subAgencies": []
        },
        {
          "parent": "Defense Advanced Research Projects Agency",
          "abbreviation": "DARPA",
          "subAgencies": []
        },
        {
          "parent": "Defense Threat Reduction Agency",
          "abbreviation": "DTRA",
          "subAgencies": []
        },
        {
          "parent": "Defense Microelectronics Activity",
          "abbreviation": "DMEA",
          "subAgencies": []
        },
        {
          "parent": "Defense Counterintelligence and Security Agency",
          "abbreviation": "DCSA",
          "subAgencies": []
        },
        {
          "parent": "Defense Finance and Accounting Service",
          "abbreviation": "DFAS",
          "subAgencies": []
        },
        {
          "parent": "Office of Local Defense Community Cooperation",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Uniformed Services University of the Health Sciences",
          "abbreviation": "USUHS",
          "subAgencies": []
        },
        {
          "parent": "Defense Contract Management Agency",
          "abbreviation": "DCMA",
          "subAgencies": []
        },
        {
          "parent": "Defense Commissary Agency",
          "abbreviation": "DECA",
          "subAgencies": []
        },
        {
          "parent": "Department of Defense Education Activity",
          "abbreviation": "DODEA",
          "subAgencies": []
        },
        {
          "parent": "Defense Human Resources Activity",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "U.S. Cyber Command",
          "abbreviation": "USCYBERCOM",
          "subAgencies": []
        },
        {
          "parent": "Defense Media Activity",
          "abbreviation": "DMA",
          "subAgencies": []
        },
        {
          "parent": "Defense Prisoner of War/Missing Personnel Office",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Office of the Inspector General",
          "abbreviation": "OIG",
          "subAgencies": []
        },
        {
          "parent": "Immediate Office of the Secretary of Defense",
          "abbreviation": "SECDEF",
          "subAgencies": []
        },
        {
          "parent": "National Geospatial-Intelligence Agency",
          "abbreviation": "NGA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of Agriculture",
      "abbreviation": "USDA",
      "subAgencies": [
        {
          "parent": "Food and Nutrition Service",
          "abbreviation": "FNS",
          "subAgencies": []
        },
        {
          "parent": "Forest Service",
          "abbreviation": "FS",
          "subAgencies": []
        },
        {
          "parent": "Agricultural Marketing Service",
          "abbreviation": "AMS",
          "subAgencies": []
        },
        {
          "parent": "Farm Service Agency",
          "abbreviation": "FSA",
          "subAgencies": []
        },
        {
          "parent": "Natural Resources Conservation Service",
          "abbreviation": "NRCS",
          "subAgencies": []
        },
        {
          "parent": "National Institute of Food and Agriculture",
          "abbreviation": "NIFA",
          "subAgencies": []
        },
        {
          "parent": "Rural Housing Service",
          "abbreviation": "RHS",
          "subAgencies": []
        },
        {
          "parent": "Rural Utilities Service",
          "abbreviation": "RUS",
          "subAgencies": []
        },
        {
          "parent": "Office of the Chief Financial Officer",
          "abbreviation": "OCFO",
          "subAgencies": []
        },
        {
          "parent": "Animal and Plant Health Inspection Service",
          "abbreviation": "APHIS",
          "subAgencies": []
        },
        {
          "parent": "Rural Business Cooperative Service",
          "abbreviation": "RBS",
          "subAgencies": []
        },
        {
          "parent": "Risk Management Agency",
          "abbreviation": "RMA",
          "subAgencies": []
        },
        {
          "parent": "Agricultural Research Service",
          "abbreviation": "ARS",
          "subAgencies": []
        },
        {
          "parent": "Foreign Agricultural Service",
          "abbreviation": "FAS",
          "subAgencies": []
        },
        {
          "parent": "Under Secretary for Farm and Foreign Agricultural Services",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Food Safety and Inspection Service",
          "abbreviation": "FSIS",
          "subAgencies": []
        },
        {
          "parent": "National Agricultural Statistics Service",
          "abbreviation": "NASS",
          "subAgencies": []
        },
        {
          "parent": "Office of the Inspector General",
          "abbreviation": "OIG",
          "subAgencies": []
        },
        {
          "parent": "Economic Research Service",
          "abbreviation": "ERS",
          "subAgencies": []
        },
        {
          "parent": "Immediate Office of the Secretary of Agriculture",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Office of Partnerships and Public Engagement",
          "abbreviation": "OAO",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of Health and Human Services",
      "abbreviation": "HHS",
      "subAgencies": [
        {
          "parent": "Centers for Medicare and Medicaid Services",
          "abbreviation": "CMS",
          "subAgencies": []
        },
        {
          "parent": "Administration for Children and Families",
          "abbreviation": "ACF",
          "subAgencies": []
        },
        {
          "parent": "National Institutes of Health",
          "abbreviation": "NIH",
          "subAgencies": []
        },
        {
          "parent": "Centers for Disease Control and Prevention",
          "abbreviation": "CDC",
          "subAgencies": []
        },
        {
          "parent": "Health Resources and Services Administration",
          "abbreviation": "HRSA",
          "subAgencies": []
        },
        {
          "parent": "Indian Health Service",
          "abbreviation": "IHS",
          "subAgencies": []
        },
        {
          "parent": "Substance Abuse and Mental Health Services Administration",
          "abbreviation": "SAMHSA",
          "subAgencies": []
        },
        {
          "parent": "Office of Assistant Secretary for Preparedness and Response",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Administration for Community Living",
          "abbreviation": "ACL",
          "subAgencies": []
        },
        {
          "parent": "Food and Drug Administration",
          "abbreviation": "FDA",
          "subAgencies": []
        },
        {
          "parent": "Office of the Assistant Secretary for Administration",
          "abbreviation": "ASA",
          "subAgencies": []
        },
        {
          "parent": "Office of the Inspector General",
          "abbreviation": "OIG",
          "subAgencies": []
        },
        {
          "parent": "Agency for Healthcare Research and Quality",
          "abbreviation": "AHRQ",
          "subAgencies": []
        },
        {
          "parent": "Office of Assistant Secretary for Health",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Office of the National Coordinator for Health Information Technology",
          "abbreviation": "ONC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of Homeland Security",
      "abbreviation": "DHS",
      "subAgencies": [
        {
          "parent": "Federal Emergency Management Agency",
          "abbreviation": "FEMA",
          "subAgencies": []
        },
        {
          "parent": "U.S. Customs and Border Protection",
          "abbreviation": "CBP",
          "subAgencies": []
        },
        {
          "parent": "Office of Procurement Operations",
          "abbreviation": "OPO",
          "subAgencies": []
        },
        {
          "parent": "U.S. Coast Guard",
          "abbreviation": "USCG",
          "subAgencies": []
        },
        {
          "parent": "U.S. Immigration and Customs Enforcement",
          "abbreviation": "ICE",
          "subAgencies": []
        },
        {
          "parent": "Transportation Security Administration",
          "abbreviation": "TSA",
          "subAgencies": []
        },
        {
          "parent": "U.S. Citizenship and Immigration Services",
          "abbreviation": "USCIS",
          "subAgencies": []
        },
        {
          "parent": "U.S. Secret Service",
          "abbreviation": "USSS",
          "subAgencies": []
        },
        {
          "parent": "Federal Law Enforcement Training Center",
          "abbreviation": "FLETC",
          "subAgencies": []
        },
        {
          "parent": "Office of the Under Secretary for Science and Technology",
          "abbreviation": "S&T",
          "subAgencies": []
        },
        {
          "parent": "Countering Weapons of Mass Destruction",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Office of the Inspector General",
          "abbreviation": "IG",
          "subAgencies": []
        },
        {
          "parent": "Cybersecurity and Infrastructure Security Agency",
          "abbreviation": "",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of the Interior",
      "abbreviation": "DOI",
      "subAgencies": [
        {
          "parent": "Departmental Offices",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Bureau of Indian Affairs and Bureau of Indian Education",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "U.S. Fish and Wildlife Service",
          "abbreviation": "USFWS",
          "subAgencies": []
        },
        {
          "parent": "Bureau of Reclamation",
          "abbreviation": "BOR",
          "subAgencies": []
        },
        {
          "parent": "National Park Service",
          "abbreviation": "NPS",
          "subAgencies": []
        },
        {
          "parent": "Office of Surface Mining, Reclamation and Enforcement",
          "abbreviation": "OSMRE",
          "subAgencies": []
        },
        {
          "parent": "Insular Affairs",
          "abbreviation": "OIA",
          "subAgencies": []
        },
        {
          "parent": "Bureau of Land Management",
          "abbreviation": "BLM",
          "subAgencies": []
        },
        {
          "parent": "U.S. Geological Survey",
          "abbreviation": "USGS",
          "subAgencies": []
        },
        {
          "parent": "Bureau of Safety and Environmental Enforcement",
          "abbreviation": "BSEE",
          "subAgencies": []
        },
        {
          "parent": "Bureau of Ocean Energy Management",
          "abbreviation": "BOEM",
          "subAgencies": []
        },
        {
          "parent": "Office of the Inspector General",
          "abbreviation": "OIG",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of Transportation",
      "abbreviation": "DOT",
      "subAgencies": [
        {
          "parent": "Federal Highway Administration",
          "abbreviation": "FHWA",
          "subAgencies": []
        },
        {
          "parent": "Federal Transit Administration",
          "abbreviation": "FTA",
          "subAgencies": []
        },
        {
          "parent": "Federal Aviation Administration",
          "abbreviation": "FAA",
          "subAgencies": []
        },
        {
          "parent": "Federal Railroad Administration",
          "abbreviation": "FRA",
          "subAgencies": []
        },
        {
          "parent": "Maritime Administration",
          "abbreviation": "MARAD",
          "subAgencies": []
        },
        {
          "parent": "Immediate Office of the Secretary of Transportation",
          "abbreviation": "OST",
          "subAgencies": []
        },
        {
          "parent": "National Highway Traffic Safety Administration",
          "abbreviation": "NHTSA",
          "subAgencies": []
        },
        {
          "parent": "Federal Motor Carrier Safety Administration",
          "abbreviation": "FMCSA",
          "subAgencies": []
        },
        {
          "parent": "Pipeline and Hazardous Materials Safety Administration",
          "abbreviation": "PHMSA",
          "subAgencies": []
        },
        {
          "parent": "Saint Lawrence Seaway Development Corporation",
          "abbreviation": "SLSDC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of Commerce",
      "abbreviation": "DOC",
      "subAgencies": [
        {
          "parent": "National Institute of Standards and Technology",
          "abbreviation": "NIST",
          "subAgencies": []
        },
        {
          "parent": "National Oceanic and Atmospheric Administration",
          "abbreviation": "NOAA",
          "subAgencies": []
        },
        {
          "parent": "U.S. Patent and Trademark Office",
          "abbreviation": "USPTO",
          "subAgencies": []
        },
        {
          "parent": "Office of the Secretary",
          "abbreviation": "OS",
          "subAgencies": []
        },
        {
          "parent": "U.S. Census Bureau",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Minority Business Development Agency",
          "abbreviation": "MBDA",
          "subAgencies": []
        },
        {
          "parent": "Economic Development Administration",
          "abbreviation": "EDA",
          "subAgencies": []
        },
        {
          "parent": "International Trade Administration",
          "abbreviation": "ITA",
          "subAgencies": []
        },
        {
          "parent": "National Telecommunications and Information Administration",
          "abbreviation": "NTIA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of Justice",
      "abbreviation": "DOJ",
      "subAgencies": [
        {
          "parent": "Federal Prison System / Bureau of Prisons",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Offices, Boards and Divisions",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Federal Bureau of Investigation",
          "abbreviation": "FBI",
          "subAgencies": []
        },
        {
          "parent": "U.S. Marshals Service",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Drug Enforcement Administration",
          "abbreviation": "DEA",
          "subAgencies": []
        },
        {
          "parent": "Office of Justice Programs",
          "abbreviation": "OJP",
          "subAgencies": []
        },
        {
          "parent": "Bureau of Alcohol, Tobacco, Firearms and Explosives Acquisition and Property Management Division",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Federal Prison Industries / Unicor",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Office of the Inspector General",
          "abbreviation": "OIG",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of Labor",
      "abbreviation": "DOL",
      "subAgencies": [
        {
          "parent": "Employment and Training Administration",
          "abbreviation": "ETA",
          "subAgencies": []
        },
        {
          "parent": "Office of the Assistant Secretary for Administration and Management",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Veterans Employment and Training Services",
          "abbreviation": "VETS",
          "subAgencies": []
        },
        {
          "parent": "Occupational Safety and Health Administration",
          "abbreviation": "OSHA",
          "subAgencies": []
        },
        {
          "parent": "Bureau of Labor Statistics",
          "abbreviation": "BLS",
          "subAgencies": []
        },
        {
          "parent": "President's Committee on Employment of the Handicapped",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Office of Inspector General",
          "abbreviation": "OIG",
          "subAgencies": []
        },
        {
          "parent": "Mine Safety and Health Administration",
          "abbreviation": "MSHA",
          "subAgencies": []
        },
        {
          "parent": "Office of Job Corps",
          "abbreviation": "",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of the Treasury",
      "abbreviation": "TREAS",
      "subAgencies": [
        {
          "parent": "Internal Revenue Service",
          "abbreviation": "IRS",
          "subAgencies": []
        },
        {
          "parent": "United States Mint",
          "abbreviation": "USMINT",
          "subAgencies": []
        },
        {
          "parent": "Bureau of Engraving and Printing",
          "abbreviation": "BEP",
          "subAgencies": []
        },
        {
          "parent": "Bureau of the Fiscal Service",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Community Development Financial Institutions Fund",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Office of the Comptroller of the Currency",
          "abbreviation": "OCC",
          "subAgencies": []
        },
        {
          "parent": "Office of Inspector General",
          "abbreviation": "OIG",
          "subAgencies": []
        },
        {
          "parent": "Departmental Offices",
          "abbreviation": "DO",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of Housing and Urban Development",
      "abbreviation": "HUD",
      "subAgencies": [
        {
          "parent": "Assistant Secretary for Public and Indian Housing",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Assistant Secretary for Housing--Federal Housing Commissioner",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Assistant Secretary for Community Planning and Development",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Department of Housing and Urban Development",
          "abbreviation": "HUD",
          "subAgencies": []
        },
        {
          "parent": "Office of Lead Hazard Control and Healthy Homes",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Assistant Secretary for Fair Housing and Equal Opportunity",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Assistant Secretary for Policy Development and Research",
          "abbreviation": "",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of Energy",
      "abbreviation": "DOE",
      "subAgencies": [
        {
          "parent": "Department of Energy",
          "abbreviation": "DOE",
          "subAgencies": []
        },
        {
          "parent": "Federal Energy Regulatory Commission",
          "abbreviation": "FERC",
          "subAgencies": []
        },
        {
          "parent": "Assistant Secretary for Fossil Energy",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Assistant Secretary for Defense Programs",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Assistant Secretary for Congressional and Intergovernmental Affairs",
          "abbreviation": "",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "General Services Administration",
      "abbreviation": "GSA",
      "subAgencies": [
        {
          "parent": "Federal Acquisition Service",
          "abbreviation": "FAS",
          "subAgencies": []
        },
        {
          "parent": "Public Buildings Service",
          "abbreviation": "PBS",
          "subAgencies": []
        },
        {
          "parent": "Office of Administrative Services",
          "abbreviation": "OAS",
          "subAgencies": []
        },
        {
          "parent": "Office of Inspector General",
          "abbreviation": "OIG",
          "subAgencies": []
        },
        {
          "parent": "General Services Administration",
          "abbreviation": "GSA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of Veterans Affairs",
      "abbreviation": "VA",
      "subAgencies": [
        {
          "parent": "Under Secretary for Benefits/Veterans Benefits Administration",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Department of Veterans Affairs",
          "abbreviation": "VA",
          "subAgencies": []
        },
        {
          "parent": "Under Secretary for Health/Veterans Health Administration",
          "abbreviation": "",
          "subAgencies": []
        },
        {
          "parent": "Directory/National Cemetery Administration",
          "abbreviation": "NCA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Court Services and Offender Supervision Agency",
      "abbreviation": "CSOSA",
      "subAgencies": [
        {
          "parent": "Court Services and Offender Supervision Agency",
          "abbreviation": "CSOSA",
          "subAgencies": []
        },
        {
          "parent": "Pretrial Services Agency",
          "abbreviation": "PSA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of State",
      "abbreviation": "DOS",
      "subAgencies": [
        {
          "parent": "Department of State",
          "abbreviation": "DOS",
          "subAgencies": []
        },
        {
          "parent": "International Boundary and Water Commission: U.S.-Mexico",
          "abbreviation": "IBWC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Executive Office of the President",
      "abbreviation": "EOP",
      "subAgencies": [
        {
          "parent": "Office of the National Drug Control Policy",
          "abbreviation": "ONDCP",
          "subAgencies": []
        },
        {
          "parent": "Executive Office of the President",
          "abbreviation": "EOP",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Institute of Museum and Library Services",
      "abbreviation": "IMLS",
      "subAgencies": [
        {
          "parent": "Institute of Museum and Library Services",
          "abbreviation": "IMLS",
          "subAgencies": []
        },
        {
          "parent": "Institute of Museum and Library Services",
          "abbreviation": "IMLS",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "National Archives and Records Administration",
      "abbreviation": "NARA",
      "subAgencies": [
        {
          "parent": "National Archives and Records Administration",
          "abbreviation": "NARA",
          "subAgencies": []
        },
        {
          "parent": "National Historical Publications and Records Commission",
          "abbreviation": "NHPRC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Agency for International Development",
      "abbreviation": "USAID",
      "subAgencies": [
        {
          "parent": "Agency for International Development",
          "abbreviation": "USAID",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Appalachian Regional Commission",
      "abbreviation": "ARC",
      "subAgencies": [
        {
          "parent": "Appalachian Regional Commission",
          "abbreviation": "ARC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Committee for Purchase from People Who Are Blind or Severely Disabled",
      "abbreviation": "ABILITYONE",
      "subAgencies": [
        {
          "parent": "Committee for Purchase from People Who Are Blind or Severely Disabled",
          "abbreviation": "ABILITYONE",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Commodity Futures Trading Commission",
      "abbreviation": "CFTC",
      "subAgencies": [
        {
          "parent": "Commodity Futures Trading Commission",
          "abbreviation": "CFTC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Consumer Financial Protection Bureau",
      "abbreviation": "CFPB",
      "subAgencies": [
        {
          "parent": "Consumer Financial Protection Bureau",
          "abbreviation": "CFPB",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Consumer Product Safety Commission",
      "abbreviation": "CPSC",
      "subAgencies": [
        {
          "parent": "Consumer Product Safety Commission",
          "abbreviation": "CPSC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Corporation for National and Community Service",
      "abbreviation": "CNCS",
      "subAgencies": [
        {
          "parent": "Corporation for National and Community Service",
          "abbreviation": "CNCS",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Council of the Inspectors General on Integrity and Efficiency",
      "abbreviation": "CIGIE",
      "subAgencies": [
        {
          "parent": "Council of the Inspectors General on Integrity and Efficiency",
          "abbreviation": "CIGIE",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Defense Nuclear Facilities Safety Board",
      "abbreviation": "DNFSB",
      "subAgencies": [
        {
          "parent": "Defense Nuclear Facilities Safety Board",
          "abbreviation": "DNFSB",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Delta Regional Authority",
      "abbreviation": "DRA",
      "subAgencies": [
        {
          "parent": "Delta Regional Authority",
          "abbreviation": "DRA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Denali Commission",
      "abbreviation": "DENALI",
      "subAgencies": [
        {
          "parent": "Denali Commission",
          "abbreviation": "DC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Department of Education",
      "abbreviation": "ED",
      "subAgencies": [
        {
          "parent": "Department of Education",
          "abbreviation": "ED",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "District of Columbia Courts",
      "abbreviation": "DC COURTS",
      "subAgencies": [
        {
          "parent": "District of Columbia Courts",
          "abbreviation": "",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Election Assistance Commission",
      "abbreviation": "EAC",
      "subAgencies": [
        {
          "parent": "Election Assistance Commission",
          "abbreviation": "EAC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Environmental Protection Agency",
      "abbreviation": "EPA",
      "subAgencies": [
        {
          "parent": "Environmental Protection Agency",
          "abbreviation": "EPA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Equal Employment Opportunity Commission",
      "abbreviation": "EEOC",
      "subAgencies": [
        {
          "parent": "Equal Employment Opportunity Commission",
          "abbreviation": "EEOC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Export-Import Bank of the United States",
      "abbreviation": "EXIM",
      "subAgencies": [
        {
          "parent": "Export-Import Bank of the United States",
          "abbreviation": "EXIM",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Federal Communications Commission",
      "abbreviation": "FCC",
      "subAgencies": [
        {
          "parent": "Federal Communications Commission",
          "abbreviation": "FCC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Federal Election Commission",
      "abbreviation": "FEC",
      "subAgencies": [
        {
          "parent": "Federal Election Commission",
          "abbreviation": "FEC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Federal Labor Relations Authority",
      "abbreviation": "FLRA",
      "subAgencies": [
        {
          "parent": "Federal Labor Relations Authority",
          "abbreviation": "FLRA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Federal Mediation and Conciliation Service",
      "abbreviation": "FMCS",
      "subAgencies": [
        {
          "parent": "Federal Mediation and Conciliation Service",
          "abbreviation": "FMCS",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Federal Mine Safety and Health Review Commission",
      "abbreviation": "FMSHRC",
      "subAgencies": [
        {
          "parent": "Federal Mine Safety and Health Review Commission",
          "abbreviation": "MSHFRC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Federal Trade Commission",
      "abbreviation": "FTC",
      "subAgencies": [
        {
          "parent": "Federal Trade Commission",
          "abbreviation": "FTC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Inter-American Foundation",
      "abbreviation": "IAF",
      "subAgencies": [
        {
          "parent": "Inter-American Foundation",
          "abbreviation": "IAF",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "International Trade Commission",
      "abbreviation": "USITC",
      "subAgencies": [
        {
          "parent": "International Trade Commission",
          "abbreviation": "USITC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "John F. Kennedy Center for the Performing Arts",
      "abbreviation": "KENNEDY CENTER",
      "subAgencies": [
        {
          "parent": "John F. Kennedy Center for the Performing Arts",
          "abbreviation": "KENNEDY CENTER",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Merit Systems Protection Board",
      "abbreviation": "MSPB",
      "subAgencies": [
        {
          "parent": "Merit Systems Protection Board",
          "abbreviation": "MSPB",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Millennium Challenge Corporation",
      "abbreviation": "MCC",
      "subAgencies": [
        {
          "parent": "Millennium Challenge Corporation",
          "abbreviation": "MCC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Morris K. Udall and Stewart L. Udall Foundation",
      "abbreviation": "UDALL",
      "subAgencies": [
        {
          "parent": "Morris K. Udall and Stewart L. Udall Foundation",
          "abbreviation": "UDALL",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "National Aeronautics and Space Administration",
      "abbreviation": "NASA",
      "subAgencies": [
        {
          "parent": "National Aeronautics and Space Administration",
          "abbreviation": "NASA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "National Credit Union Administration",
      "abbreviation": "NCUA",
      "subAgencies": [
        {
          "parent": "National Credit Union Administration",
          "abbreviation": "NCUA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "National Endowment for the Arts",
      "abbreviation": "NEA",
      "subAgencies": [
        {
          "parent": "National Endowment for the Arts",
          "abbreviation": "NEA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "National Endowment for the Humanities",
      "abbreviation": "NEH",
      "subAgencies": [
        {
          "parent": "National Endowment for the Humanities",
          "abbreviation": "NEH",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "National Labor Relations Board",
      "abbreviation": "NLRB",
      "subAgencies": [
        {
          "parent": "National Labor Relations Board",
          "abbreviation": "NLRB",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "National Science Foundation",
      "abbreviation": "NSF",
      "subAgencies": [
        {
          "parent": "National Science Foundation",
          "abbreviation": "NSF",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "National Transportation Safety Board",
      "abbreviation": "NTSB",
      "subAgencies": [
        {
          "parent": "National Transportation Safety Board",
          "abbreviation": "NTSB",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Nuclear Regulatory Commission",
      "abbreviation": "NRC",
      "subAgencies": [
        {
          "parent": "Nuclear Regulatory Commission",
          "abbreviation": "NRC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Occupational Safety and Health Review Commission",
      "abbreviation": "OSHRC",
      "subAgencies": [
        {
          "parent": "Occupational Safety and Health Review Commission",
          "abbreviation": "OSHRC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Office of Personnel Management",
      "abbreviation": "OPM",
      "subAgencies": [
        {
          "parent": "Office of Personnel Management",
          "abbreviation": "OPM",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Peace Corps",
      "abbreviation": "PC",
      "subAgencies": [
        {
          "parent": "Peace Corps",
          "abbreviation": "PC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Pension Benefit Guaranty Corporation",
      "abbreviation": "PBGC",
      "subAgencies": [
        {
          "parent": "Pension Benefit Guaranty Corporation",
          "abbreviation": "PBGC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Railroad Retirement Board",
      "abbreviation": "RRB",
      "subAgencies": [
        {
          "parent": "Railroad Retirement Board",
          "abbreviation": "RRB",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Securities and Exchange Commission",
      "abbreviation": "SEC",
      "subAgencies": [
        {
          "parent": "Securities and Exchange Commission",
          "abbreviation": "SEC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Selective Service System",
      "abbreviation": "SSS",
      "subAgencies": [
        {
          "parent": "Selective Service System",
          "abbreviation": "SSS",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Small Business Administration",
      "abbreviation": "SBA",
      "subAgencies": [
        {
          "parent": "Small Business Administration",
          "abbreviation": "SBA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Social Security Administration",
      "abbreviation": "SSA",
      "subAgencies": [
        {
          "parent": "Social Security Administration",
          "abbreviation": "SSA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "U.S. Agency for Global Media",
      "abbreviation": "USAGM",
      "subAgencies": [
        {
          "parent": "U.S. Agency for Global Media",
          "abbreviation": "USAGM",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "U.S. International Development Finance Corporation",
      "abbreviation": "DFC",
      "subAgencies": [
        {
          "parent": "U.S. International Development Finance Corporation",
          "abbreviation": "DFC",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "United States Chemical Safety Board",
      "abbreviation": "CSB",
      "subAgencies": [
        {
          "parent": "United States Chemical Safety Board",
          "abbreviation": "CSB",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "United States Trade and Development Agency",
      "abbreviation": "USTDA",
      "subAgencies": [
        {
          "parent": "United States Trade and Development Agency",
          "abbreviation": "USTDA",
          "subAgencies": []
        }
      ]
    },
    {
      "parent": "Access Board",
      "abbreviation": "USAB",
      "subAgencies": []
    },
    {
      "parent": "Administrative Conference of the U.S.",
      "abbreviation": "ACUS",
      "subAgencies": []
    },
    {
      "parent": "Advisory Council on Historic Preservation",
      "abbreviation": "ACHP",
      "subAgencies": []
    },
    {
      "parent": "African Development Foundation",
      "abbreviation": "USADF",
      "subAgencies": []
    },
    {
      "parent": "American Battle Monuments Commission",
      "abbreviation": "ABMC",
      "subAgencies": []
    },
    {
      "parent": "Armed Forces Retirement Home",
      "abbreviation": "AFRH",
      "subAgencies": []
    },
    {
      "parent": "Barry Goldwater Scholarship and Excellence In Education Foundation",
      "abbreviation": "GOLDWATER",
      "subAgencies": []
    },
    {
      "parent": "Commission for the Preservation of America's Heritage Abroad",
      "abbreviation": "HERITAGEABROAD",
      "subAgencies": []
    },
    {
      "parent": "Commission of Fine Arts",
      "abbreviation": "CFA",
      "subAgencies": []
    },
    {
      "parent": "Commission on Civil Rights",
      "abbreviation": "USCCR",
      "subAgencies": []
    },
    {
      "parent": "Corps of Engineers - Civil Works",
      "abbreviation": "USACE",
      "subAgencies": []
    },
    {
      "parent": "Farm Credit System Insurance Corporation",
      "abbreviation": "FCSIC",
      "subAgencies": []
    },
    {
      "parent": "Federal Deposit Insurance Corporation",
      "abbreviation": "FDIC",
      "subAgencies": []
    },
    {
      "parent": "Federal Financial Institutions Examination Council",
      "abbreviation": "FFIEC",
      "subAgencies": []
    },
    {
      "parent": "Federal Maritime Commission",
      "abbreviation": "FMC",
      "subAgencies": []
    },
    {
      "parent": "Federal Permitting Improvement Steering Council",
      "abbreviation": "FPISC",
      "subAgencies": []
    },
    {
      "parent": "Government Accountability Office",
      "abbreviation": "GAO",
      "subAgencies": []
    },
    {
      "parent": "Gulf Coast Ecosystem Restoration Council",
      "abbreviation": "GCERC",
      "subAgencies": []
    },
    {
      "parent": "Harry S Truman Scholarship Foundation",
      "abbreviation": "TRUMAN",
      "subAgencies": []
    },
    {
      "parent": "James Madison Memorial Fellowship Foundation",
      "abbreviation": "MADISON",
      "subAgencies": []
    },
    {
      "parent": "Japan-United States Friendship Commission",
      "abbreviation": "JUSFC",
      "subAgencies": []
    },
    {
      "parent": "Marine Mammal Commission",
      "abbreviation": "MMC",
      "subAgencies": []
    },
    {
      "parent": "National Capital Planning Commission",
      "abbreviation": "NCPC",
      "subAgencies": []
    },
    {
      "parent": "National Council on Disability",
      "abbreviation": "NCD",
      "subAgencies": []
    },
    {
      "parent": "National Mediation Board",
      "abbreviation": "NMB",
      "subAgencies": []
    },
    {
      "parent": "Northern Border Regional Commission",
      "abbreviation": "NBRC",
      "subAgencies": []
    },
    {
      "parent": "Nuclear Waste Technical Review Board",
      "abbreviation": "NWTRB",
      "subAgencies": []
    },
    {
      "parent": "Office of Government Ethics",
      "abbreviation": "OGE",
      "subAgencies": []
    },
    {
      "parent": "Office of Navajo and Hopi Indian Relocation",
      "abbreviation": "ONHIR",
      "subAgencies": []
    },
    {
      "parent": "Office of Special Counsel",
      "abbreviation": "OSC",
      "subAgencies": []
    },
    {
      "parent": "Overseas Private Investment Corporation",
      "abbreviation": "OPIC",
      "subAgencies": []
    },
    {
      "parent": "Patient-Centered Outcomes Research Trust Fund",
      "abbreviation": "PCORTF",
      "subAgencies": []
    },
    {
      "parent": "Presidio Trust",
      "abbreviation": "PRESIDIO",
      "subAgencies": []
    },
    {
      "parent": "Privacy and Civil Liberties Oversight Board",
      "abbreviation": "PCLOB",
      "subAgencies": []
    },
    {
      "parent": "Public Buildings Reform Board",
      "abbreviation": "PBRB",
      "subAgencies": []
    },
    {
      "parent": "Surface Transportation Board",
      "abbreviation": "STB",
      "subAgencies": []
    },
    {
      "parent": "U.S. Interagency Council on Homelessness",
      "abbreviation": "USICH",
      "subAgencies": []
    },
    {
      "parent": "United States Court of Appeals for Veterans Claims",
      "abbreviation": "CAVC",
      "subAgencies": []
    },
    {
      "parent": "Vietnam Education Foundation",
      "abbreviation": "VEF",
      "subAgencies": []
    }
  ];
  




























// export const FEDERAL_AGENCIES = [
//     { parent: "Department of Agriculture", abbreviation: "USDA", subAgencies: [{ parent: "Food and Nutrition Service", abbreviation: "FNS", subAgencies: [] },
//         { parent: "Forest Service", abbreviation: "FS", subAgencies: [] },
//         { parent: "Agricultural Research Service", abbreviation: "ARS", subAgencies: [] },
//         { parent: "Animal and Plant Health Inspection Service", abbreviation: "APHIS", subAgencies: [] },
//         { parent: "Farm Service Agency", abbreviation: "FSA", subAgencies: [] },
//         { parent: "Natural Resources Conservation Service", abbreviation: "NRCS", subAgencies: [] },
//         { parent: "Rural Development", abbreviation: "RD", subAgencies: [] },
//         { parent: "Risk Management Agency", abbreviation: "RMA", subAgencies: [] },
//         { parent: "National Agricultural Statistics Service", abbreviation: "NASS", subAgencies: [] },
//         { parent: "Grain Inspection, Packers and Stockyards Administration", abbreviation: "GIPSA", subAgencies: [] },
//         { parent: "Economic Research Service", abbreviation: "ERS", subAgencies: [] },
//         { parent: "Foreign Agricultural Service", abbreviation: "FAS", subAgencies: [] },
//         { parent: "Office of the Chief Economist", abbreviation: "OCE", subAgencies: [] },
//         { parent: "Office of the Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office of the General Counsel", abbreviation: "OGC", subAgencies: [] },
//         { parent: "Agricultural Marketing Service", abbreviation: "AMS", subAgencies: [] },] 
//     },
//     { parent: "Department of Commerce", abbreviation: "DOC", subAgencies: [{ parent: "Bureau of Economic Analysis", abbreviation: "BEA", subAgencies: [] },
//         { parent: "Bureau of Industry and Security", abbreviation: "BIS", subAgencies: [] },
//         { parent: "U.S. Census Bureau", abbreviation: "Census", subAgencies: [] },
//         { parent: "Economic Development Administration", abbreviation: "EDA", subAgencies: [] },
//         { parent: "International Trade Administration", abbreviation: "ITA", subAgencies: [] },
//         { parent: "Minority Business Development Agency", abbreviation: "MBDA", subAgencies: [] },
//         { parent: "National Institute of Standards and Technology", abbreviation: "NIST", subAgencies: [] },
//         { parent: "National Oceanic and Atmospheric Administration", abbreviation: "NOAA", subAgencies: [] },
//         { parent: "National Technical Information Service", abbreviation: "NTIS", subAgencies: [] },
//         { parent: "National Telecommunications and Information Administration", abbreviation: "NTIA", subAgencies: [] },
//         { parent: "Patent and Trademark Office", abbreviation: "USPTO", subAgencies: [] },
//         { parent: "Office of the Secretary", abbreviation: "OS", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },] 
//     },
//     { parent: "Department of Defense", abbreviation: "DOD", subAgencies: [{ parent: "Department of the Army", abbreviation: "Army", subAgencies: [] },
//         { parent: "Department of the Navy", abbreviation: "Navy", subAgencies: [] },
//         { parent: "Department of the Air Force", abbreviation: "Air Force", subAgencies: [] },
//         { parent: "Defense Intelligence Agency", abbreviation: "DIA", subAgencies: [] },
//         { parent: "National Security Agency", abbreviation: "NSA", subAgencies: [] },
//         { parent: "Defense Advanced Research Projects Agency", abbreviation: "DARPA", subAgencies: [] },
//         { parent: "Defense Logistics Agency", abbreviation: "DLA", subAgencies: [] },
//         { parent: "Defense Contract Management Agency", abbreviation: "DCMA", subAgencies: [] },
//         { parent: "Missile Defense Agency", abbreviation: "MDA", subAgencies: [] },
//         { parent: "National Geospatial-Intelligence Agency", abbreviation: "NGA", subAgencies: [] },
//         { parent: "Defense Health Agency", abbreviation: "DHA", subAgencies: [] },
//         { parent: "U.S. Cyber Command", abbreviation: "USCYBERCOM", subAgencies: [] },
//         { parent: "U.S. Special Operations Command", abbreviation: "USSOCOM", subAgencies: [] },
//         { parent: "U.S. Transportation Command", abbreviation: "USTRANSCOM", subAgencies: [] },
//         { parent: "Pentagon Force Protection Agency", abbreviation: "PFPA", subAgencies: [] },] 
//     },
//     { parent: "Department of Education", abbreviation: "ED", subAgencies: [{ parent: "Office of Elementary and Secondary Education", abbreviation: "OESE", subAgencies: [] },
//         { parent: "Office of Special Education and Rehabilitative Services", abbreviation: "OSERS", subAgencies: [] },
//         { parent: "Office of Postsecondary Education", abbreviation: "OPE", subAgencies: [] },
//         { parent: "Office of Federal Student Aid", abbreviation: "FSA", subAgencies: [] },
//         { parent: "Institute of Education Sciences", abbreviation: "IES", subAgencies: [] },
//         { parent: "Office for Civil Rights", abbreviation: "OCR", subAgencies: [] },
//         { parent: "Office of English Language Acquisition", abbreviation: "OELA", subAgencies: [] },
//         { parent: "Office of Career, Technical, and Adult Education", abbreviation: "OCTAE", subAgencies: [] },
//         { parent: "Office of Educational Technology", abbreviation: "OET", subAgencies: [] },
//         { parent: "Office of the General Counsel", abbreviation: "OGC", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office of Innovation and Improvement", abbreviation: "OII", subAgencies: [] },
//         { parent: "Office of Planning, Evaluation, and Policy Development", abbreviation: "OPEPD", subAgencies: [] },
//         { parent: "Office of Communications and Outreach", abbreviation: "OCO", subAgencies: [] },
//         { parent: "Office of Management", abbreviation: "OM", subAgencies: [] },] 
//     },
//     { parent: "Department of Energy", abbreviation: "DOE", subAgencies: [{ parent: "Office of Energy Efficiency and Renewable Energy", abbreviation: "EERE", subAgencies: [] },
//         { parent: "Office of Fossil Energy and Carbon Management", abbreviation: "FECM", subAgencies: [] },
//         { parent: "Office of Nuclear Energy", abbreviation: "NE", subAgencies: [] },
//         { parent: "Office of Science", abbreviation: "SC", subAgencies: [] },
//         { parent: "National Nuclear Security Administration", abbreviation: "NNSA", subAgencies: [] },
//         { parent: "Office of Environmental Management", abbreviation: "EM", subAgencies: [] },
//         { parent: "Office of Cybersecurity, Energy Security, and Emergency Response", abbreviation: "CESER", subAgencies: [] },
//         { parent: "Office of Electricity", abbreviation: "OE", subAgencies: [] },
//         { parent: "Office of Indian Energy Policy and Programs", abbreviation: "IE", subAgencies: [] },
//         { parent: "Office of Policy", abbreviation: "OP", subAgencies: [] },
//         { parent: "Office of Intelligence and Counterintelligence", abbreviation: "IN", subAgencies: [] },
//         { parent: "Office of International Affairs", abbreviation: "IA", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Loan Programs Office", abbreviation: "LPO", subAgencies: [] },
//         { parent: "Office of Legacy Management", abbreviation: "LM", subAgencies: [] },] 
//     },
//     { parent: "Department of Health and Human Services", abbreviation: "HHS", subAgencies: [{ parent: "Administration for Children and Families", abbreviation: "ACF", subAgencies: [] },
//         { parent: "Administration for Community Living", abbreviation: "ACL", subAgencies: [] },
//         { parent: "Agency for Healthcare Research and Quality", abbreviation: "AHRQ", subAgencies: [] },
//         { parent: "Agency for Toxic Substances and Disease Registry", abbreviation: "ATSDR", subAgencies: [] },
//         { parent: "Centers for Disease Control and Prevention", abbreviation: "CDC", subAgencies: [] },
//         { parent: "Centers for Medicare and Medicaid Services", abbreviation: "CMS", subAgencies: [] },
//         { parent: "Food and Drug Administration", abbreviation: "FDA", subAgencies: [] },
//         { parent: "Health Resources and Services Administration", abbreviation: "HRSA", subAgencies: [] },
//         { parent: "Indian Health Service", abbreviation: "IHS", subAgencies: [] },
//         { parent: "National Institutes of Health", abbreviation: "NIH", subAgencies: [] },
//         { parent: "Office of the Assistant Secretary for Health", abbreviation: "OASH", subAgencies: [] },
//         { parent: "Office of the Assistant Secretary for Preparedness and Response", abbreviation: "ASPR", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office for Civil Rights", abbreviation: "OCR", subAgencies: [] },
//         { parent: "Substance Abuse and Mental Health Services Administration", abbreviation: "SAMHSA", subAgencies: [] },] 
//     },
//     { parent: "Department of Homeland Security", abbreviation: "DHS", subAgencies: [{ parent: "U.S. Customs and Border Protection", abbreviation: "CBP", subAgencies: [] },
//         { parent: "Federal Emergency Management Agency", abbreviation: "FEMA", subAgencies: [] },
//         { parent: "Transportation Security Administration", abbreviation: "TSA", subAgencies: [] },
//         { parent: "U.S. Citizenship and Immigration Services", abbreviation: "USCIS", subAgencies: [] },
//         { parent: "U.S. Immigration and Customs Enforcement", abbreviation: "ICE", subAgencies: [] },
//         { parent: "United States Secret Service", abbreviation: "USSS", subAgencies: [] },
//         { parent: "Cybersecurity and Infrastructure Security Agency", abbreviation: "CISA", subAgencies: [] },
//         { parent: "Coast Guard", abbreviation: "USCG", subAgencies: [] },
//         { parent: "Federal Law Enforcement Training Centers", abbreviation: "FLETC", subAgencies: [] },
//         { parent: "Office of Intelligence and Analysis", abbreviation: "I&A", subAgencies: [] },
//         { parent: "Office of Operations Coordination", abbreviation: "OPS", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Science and Technology Directorate", abbreviation: "S&T", subAgencies: [] },
//         { parent: "Management Directorate", abbreviation: "MGMT", subAgencies: [] },
//         { parent: "Office of Policy", abbreviation: "PLCY", subAgencies: [] },] 
//     },
//     { parent: "Department of Housing and Urban Development", abbreviation: "HUD", subAgencies: [{ parent: "Office of Housing", abbreviation: "OH", subAgencies: [] },
//         { parent: "Office of Public and Indian Housing", abbreviation: "PIH", subAgencies: [] },
//         { parent: "Office of Community Planning and Development", abbreviation: "CPD", subAgencies: [] },
//         { parent: "Office of Fair Housing and Equal Opportunity", abbreviation: "FHEO", subAgencies: [] },
//         { parent: "Government National Mortgage Association (Ginnie Mae)", abbreviation: "GNMA", subAgencies: [] },
//         { parent: "Office of Policy Development and Research", abbreviation: "PD&R", subAgencies: [] },
//         { parent: "Office of Lead Hazard Control and Healthy Homes", abbreviation: "OLHCHH", subAgencies: [] },
//         { parent: "Office of the Chief Financial Officer", abbreviation: "OCFO", subAgencies: [] },
//         { parent: "Office of the Chief Information Officer", abbreviation: "OCIO", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office of General Counsel", abbreviation: "OGC", subAgencies: [] },
//         { parent: "Office of Field Policy and Management", abbreviation: "FPM", subAgencies: [] },
//         { parent: "Office of Administration", abbreviation: "OA", subAgencies: [] },] 
//     },
//     { parent: "Department of the Interior", abbreviation: "DOI", subAgencies: [{ parent: "Bureau of Indian Affairs", abbreviation: "BIA", subAgencies: [] },
//         { parent: "Bureau of Land Management", abbreviation: "BLM", subAgencies: [] },
//         { parent: "Bureau of Ocean Energy Management", abbreviation: "BOEM", subAgencies: [] },
//         { parent: "Bureau of Reclamation", abbreviation: "BOR", subAgencies: [] },
//         { parent: "Bureau of Safety and Environmental Enforcement", abbreviation: "BSEE", subAgencies: [] },
//         { parent: "National Park Service", abbreviation: "NPS", subAgencies: [] },
//         { parent: "Office of Surface Mining Reclamation and Enforcement", abbreviation: "OSMRE", subAgencies: [] },
//         { parent: "U.S. Fish and Wildlife Service", abbreviation: "FWS", subAgencies: [] },
//         { parent: "U.S. Geological Survey", abbreviation: "USGS", subAgencies: [] },
//         { parent: "Office of the Solicitor", abbreviation: "SOL", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office of the Special Trustee for American Indians", abbreviation: "OST", subAgencies: [] },
//         { parent: "Office of Natural Resources Revenue", abbreviation: "ONRR", subAgencies: [] },
//         { parent: "Office of Policy, Management, and Budget", abbreviation: "PMB", subAgencies: [] },
//         { parent: "Office of Wildland Fire", abbreviation: "OWF", subAgencies: [] },] 
//     },
//     { parent: "Department of Justice", abbreviation: "DOJ", subAgencies: [{ parent: "Federal Bureau of Investigation", abbreviation: "FBI", subAgencies: [] },
//         { parent: "Drug Enforcement Administration", abbreviation: "DEA", subAgencies: [] },
//         { parent: "Bureau of Alcohol, Tobacco, Firearms and Explosives", abbreviation: "ATF", subAgencies: [] },
//         { parent: "U.S. Marshals Service", abbreviation: "USMS", subAgencies: [] },
//         { parent: "Office of Justice Programs", abbreviation: "OJP", subAgencies: [] },
//         { parent: "Office of the Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Executive Office for Immigration Review", abbreviation: "EOIR", subAgencies: [] },
//         { parent: "Bureau of Prisons", abbreviation: "BOP", subAgencies: [] },
//         { parent: "Civil Rights Division", abbreviation: "CRT", subAgencies: [] },
//         { parent: "Criminal Division", abbreviation: "CRM", subAgencies: [] },
//         { parent: "National Security Division", abbreviation: "NSD", subAgencies: [] },
//         { parent: "Antitrust Division", abbreviation: "ATR", subAgencies: [] },
//         { parent: "Tax Division", abbreviation: "TAX", subAgencies: [] },
//         { parent: "Environment and Natural Resources Division", abbreviation: "ENRD", subAgencies: [] },
//         { parent: "Community Oriented Policing Services", abbreviation: "COPS", subAgencies: [] },] 
//     },
//     { parent: "Department of Labor", abbreviation: "DOL", subAgencies: [{ parent: "Bureau of Labor Statistics", abbreviation: "BLS", subAgencies: [] },
//         { parent: "Employee Benefits Security Administration", abbreviation: "EBSA", subAgencies: [] },
//         { parent: "Employment and Training Administration", abbreviation: "ETA", subAgencies: [] },
//         { parent: "Mine Safety and Health Administration", abbreviation: "MSHA", subAgencies: [] },
//         { parent: "Occupational Safety and Health Administration", abbreviation: "OSHA", subAgencies: [] },
//         { parent: "Office of Federal Contract Compliance Programs", abbreviation: "OFCCP", subAgencies: [] },
//         { parent: "Office of Labor-Management Standards", abbreviation: "OLMS", subAgencies: [] },
//         { parent: "Office of Workers' Compensation Programs", abbreviation: "OWCP", subAgencies: [] },
//         { parent: "Veterans' Employment and Training Service", abbreviation: "VETS", subAgencies: [] },
//         { parent: "Wage and Hour Division", abbreviation: "WHD", subAgencies: [] },
//         { parent: "Women's Bureau", abbreviation: "WB", subAgencies: [] },
//         { parent: "Office of the Solicitor", abbreviation: "SOL", subAgencies: [] },
//         { parent: "Office of Disability Employment Policy", abbreviation: "ODEP", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office of Public Affairs", abbreviation: "OPA", subAgencies: [] },] 
//     },
//     { parent: "Department of State", abbreviation: "DOS", subAgencies: [{ parent: "Bureau of Consular Affairs", abbreviation: "CA", subAgencies: [] },
//         { parent: "Bureau of Diplomatic Security", abbreviation: "DS", subAgencies: [] },
//         { parent: "Bureau of Intelligence and Research", abbreviation: "INR", subAgencies: [] },
//         { parent: "Bureau of International Narcotics and Law Enforcement Affairs", abbreviation: "INL", subAgencies: [] },
//         { parent: "Bureau of Population, Refugees, and Migration", abbreviation: "PRM", subAgencies: [] },
//         { parent: "Bureau of Economic and Business Affairs", abbreviation: "EB", subAgencies: [] },
//         { parent: "Bureau of Educational and Cultural Affairs", abbreviation: "ECA", subAgencies: [] },
//         { parent: "Bureau of Oceans and International Environmental and Scientific Affairs", abbreviation: "OES", subAgencies: [] },
//         { parent: "Bureau of Political-Military Affairs", abbreviation: "PM", subAgencies: [] },
//         { parent: "Bureau of International Organization Affairs", abbreviation: "IO", subAgencies: [] },
//         { parent: "Office of the Legal Adviser", abbreviation: "L", subAgencies: [] },
//         { parent: "Office of Management Strategy and Solutions", abbreviation: "M", subAgencies: [] },
//         { parent: "Office of the Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office of the Under Secretary for Public Diplomacy and Public Affairs", abbreviation: "R", subAgencies: [] },
//         { parent: "Office of Foreign Missions", abbreviation: "OFM", subAgencies: [] },] 
//     },
//     { parent: "Department of Transportation", abbreviation: "DOT", subAgencies: [{ parent: "Federal Aviation Administration", abbreviation: "FAA", subAgencies: [] },
//         { parent: "Federal Highway Administration", abbreviation: "FHWA", subAgencies: [] },
//         { parent: "Federal Motor Carrier Safety Administration", abbreviation: "FMCSA", subAgencies: [] },
//         { parent: "Federal Railroad Administration", abbreviation: "FRA", subAgencies: [] },
//         { parent: "Federal Transit Administration", abbreviation: "FTA", subAgencies: [] },
//         { parent: "Maritime Administration", abbreviation: "MARAD", subAgencies: [] },
//         { parent: "National Highway Traffic Safety Administration", abbreviation: "NHTSA", subAgencies: [] },
//         { parent: "Pipeline and Hazardous Materials Safety Administration", abbreviation: "PHMSA", subAgencies: [] },
//         { parent: "Office of the Secretary of Transportation", abbreviation: "OST", subAgencies: [] },
//         { parent: "Saint Lawrence Seaway Development Corporation", abbreviation: "SLSDC", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office of Public Affairs", abbreviation: "OPA", subAgencies: [] },
//         { parent: "Office of Intelligence, Security, and Emergency Response", abbreviation: "OISER", subAgencies: [] },
//         { parent: "Research and Innovative Technology Administration", abbreviation: "RITA", subAgencies: [] },] 
//     },
//     { parent: "Department of the Treasury", abbreviation: "Treasury", subAgencies: [{ parent: "Internal Revenue Service", abbreviation: "IRS", subAgencies: [] },
//         { parent: "Office of the Comptroller of the Currency", abbreviation: "OCC", subAgencies: [] },
//         { parent: "Bureau of Engraving and Printing", abbreviation: "BEP", subAgencies: [] },
//         { parent: "Bureau of the Fiscal Service", abbreviation: "BFS", subAgencies: [] },
//         { parent: "Financial Crimes Enforcement Network", abbreviation: "FinCEN", subAgencies: [] },
//         { parent: "Office of Foreign Assets Control", abbreviation: "OFAC", subAgencies: [] },
//         { parent: "U.S. Mint", abbreviation: "Mint", subAgencies: [] },
//         { parent: "Alcohol and Tobacco Tax and Trade Bureau", abbreviation: "TTB", subAgencies: [] },
//         { parent: "Community Development Financial Institutions Fund", abbreviation: "CDFI", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Treasury Inspector General for Tax Administration", abbreviation: "TIGTA", subAgencies: [] },
//         { parent: "Office of International Affairs", abbreviation: "IA", subAgencies: [] },
//         { parent: "Office of Terrorism and Financial Intelligence", abbreviation: "TFI", subAgencies: [] },
//         { parent: "Office of Domestic Finance", abbreviation: "ODF", subAgencies: [] },
//         { parent: "Office of Economic Policy", abbreviation: "OEP", subAgencies: [] },] 
//     },
//     { parent: "Department of Veterans Affairs", abbreviation: "VA", subAgencies: [{ parent: "Veterans Health Administration", abbreviation: "VHA", subAgencies: [] },
//         { parent: "Veterans Benefits Administration", abbreviation: "VBA", subAgencies: [] },
//         { parent: "National Cemetery Administration", abbreviation: "NCA", subAgencies: [] },
//         { parent: "Office of Information and Technology", abbreviation: "OIT", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Board of Veterans' Appeals", abbreviation: "BVA", subAgencies: [] },
//         { parent: "Office of Acquisition, Logistics, and Construction", abbreviation: "OALC", subAgencies: [] },
//         { parent: "Office of Congressional and Legislative Affairs", abbreviation: "OCLA", subAgencies: [] },
//         { parent: "Office of Human Resources and Administration", abbreviation: "OHRA", subAgencies: [] },
//         { parent: "Office of Public and Intergovernmental Affairs", abbreviation: "OPIA", subAgencies: [] },
//         { parent: "Office of Policy and Planning", abbreviation: "OPP", subAgencies: [] },
//         { parent: "Office of General Counsel", abbreviation: "OGC", subAgencies: [] },
//         { parent: "Center for Women Veterans", abbreviation: "CWV", subAgencies: [] },
//         { parent: "Center for Minority Veterans", abbreviation: "CMV", subAgencies: [] },
//         { parent: "Office of Small and Disadvantaged Business Utilization", abbreviation: "OSDBU", subAgencies: [] },] 
//     },
//     { parent: "Environmental Protection Agency", abbreviation: "EPA", subAgencies: [{ parent: "Office of Air and Radiation", abbreviation: "OAR", subAgencies: [] },
//         { parent: "Office of Chemical Safety and Pollution Prevention", abbreviation: "OCSPP", subAgencies: [] },
//         { parent: "Office of the Chief Financial Officer", abbreviation: "OCFO", subAgencies: [] },
//         { parent: "Office of Enforcement and Compliance Assurance", abbreviation: "OECA", subAgencies: [] },
//         { parent: "Office of General Counsel", abbreviation: "OGC", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office of International and Tribal Affairs", abbreviation: "OITA", subAgencies: [] },
//         { parent: "Office of Land and Emergency Management", abbreviation: "OLEM", subAgencies: [] },
//         { parent: "Office of Mission Support", abbreviation: "OMS", subAgencies: [] },
//         { parent: "Office of Research and Development", abbreviation: "ORD", subAgencies: [] },
//         { parent: "Office of Water", abbreviation: "OW", subAgencies: [] },
//         { parent: "Office of Environmental Information", abbreviation: "OEI", subAgencies: [] },
//         { parent: "Office of Policy", abbreviation: "OP", subAgencies: [] },
//         { parent: "Office of Public Affairs", abbreviation: "OPA", subAgencies: [] },
//         { parent: "Office of Small and Disadvantaged Business Utilization", abbreviation: "OSDBU", subAgencies: [] },] 
//     },
//     { parent: "General Services Administration", abbreviation: "GSA", subAgencies: [{ parent: "Public Buildings Service", abbreviation: "PBS", subAgencies: [] },
//         { parent: "Federal Acquisition Service", abbreviation: "FAS", subAgencies: [] },
//         { parent: "Office of Government-wide Policy", abbreviation: "OGP", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office of Mission Assurance", abbreviation: "OMA", subAgencies: [] },
//         { parent: "Office of Congressional and Intergovernmental Affairs", abbreviation: "OCIA", subAgencies: [] },
//         { parent: "Office of Small Business Utilization", abbreviation: "OSBU", subAgencies: [] },
//         { parent: "Office of the Chief Financial Officer", abbreviation: "OCFO", subAgencies: [] },
//         { parent: "Office of General Counsel", abbreviation: "OGC", subAgencies: [] },
//         { parent: "Office of Human Resources Management", abbreviation: "OHRM", subAgencies: [] },
//         { parent: "Office of Strategic Communication", abbreviation: "OSC", subAgencies: [] },
//         { parent: "Technology Transformation Services", abbreviation: "TTS", subAgencies: [] },
//         { parent: "Office of Administrative Services", abbreviation: "OAS", subAgencies: [] },
//         { parent: "Office of Information Technology", abbreviation: "OIT", subAgencies: [] },
//         { parent: "Office of Civil Rights", abbreviation: "OCR", subAgencies: [] },] 
//     },
//     { parent: "National Aeronautics and Space Administration", abbreviation: "NASA", subAgencies: [{ parent: "Aeronautics Research Mission Directorate", abbreviation: "ARMD", subAgencies: [] },
//         { parent: "Human Exploration and Operations Mission Directorate", abbreviation: "HEOMD", subAgencies: [] },
//         { parent: "Science Mission Directorate", abbreviation: "SMD", subAgencies: [] },
//         { parent: "Space Technology Mission Directorate", abbreviation: "STMD", subAgencies: [] },
//         { parent: "Office of the Chief Financial Officer", abbreviation: "OCFO", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office of the Chief Information Officer", abbreviation: "OCIO", subAgencies: [] },
//         { parent: "Office of Communications", abbreviation: "OCOMM", subAgencies: [] },
//         { parent: "Office of International and Interagency Relations", abbreviation: "OIIR", subAgencies: [] },
//         { parent: "Office of Safety and Mission Assurance", abbreviation: "OSMA", subAgencies: [] },
//         { parent: "Office of Strategic Infrastructure", abbreviation: "OSI", subAgencies: [] },
//         { parent: "Ames Research Center", abbreviation: "ARC", subAgencies: [] },
//         { parent: "Armstrong Flight Research Center", abbreviation: "AFRC", subAgencies: [] },
//         { parent: "Glenn Research Center", abbreviation: "GRC", subAgencies: [] },
//         { parent: "Goddard Space Flight Center", abbreviation: "GSFC", subAgencies: [] },
//         { parent: "Jet Propulsion Laboratory", abbreviation: "JPL", subAgencies: [] },
//         { parent: "Johnson Space Center", abbreviation: "JSC", subAgencies: [] },
//         { parent: "Kennedy Space Center", abbreviation: "KSC", subAgencies: [] },
//         { parent: "Langley Research Center", abbreviation: "LaRC", subAgencies: [] },
//         { parent: "Marshall Space Flight Center", abbreviation: "MSFC", subAgencies: [] },
//         { parent: "Stennis Space Center", abbreviation: "SSC", subAgencies: [] },] 
//     },
//     { parent: "Small Business Administration", abbreviation: "SBA", subAgencies: [{ parent: "Office of Capital Access", abbreviation: "OCA", subAgencies: [] },
//         { parent: "Office of Disaster Assistance", abbreviation: "ODA", subAgencies: [] },
//         { parent: "Office of Entrepreneurial Development", abbreviation: "OED", subAgencies: [] },
//         { parent: "Office of Government Contracting and Business Development", abbreviation: "GCBD", subAgencies: [] },
//         { parent: "Office of Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office of Advocacy", abbreviation: "OA", subAgencies: [] },
//         { parent: "Office of Investment and Innovation", abbreviation: "OII", subAgencies: [] },
//         { parent: "Office of International Trade", abbreviation: "OIT", subAgencies: [] },
//         { parent: "Office of the Chief Financial Officer", abbreviation: "OCFO", subAgencies: [] },
//         { parent: "Office of Field Operations", abbreviation: "OFO", subAgencies: [] },
//         { parent: "Office of the General Counsel", abbreviation: "OGC", subAgencies: [] },
//         { parent: "Office of Veterans Business Development", abbreviation: "OVBD", subAgencies: [] },
//         { parent: "Office of Women's Business Ownership", abbreviation: "OWBO", subAgencies: [] },
//         { parent: "Office of Communications and Public Liaison", abbreviation: "OCPL", subAgencies: [] },
//         { parent: "Office of Performance Management and Chief Financial Officer", abbreviation: "OPMCFO", subAgencies: [] },] 
//     },
//     { parent: "Social Security Administration", abbreviation: "SSA", subAgencies: [{ parent: "Office of Retirement and Disability Policy", abbreviation: "ORDP", subAgencies: [] },
//         { parent: "Office of the Chief Actuary", abbreviation: "OCACT", subAgencies: [] },
//         { parent: "Office of the Chief Financial Officer", abbreviation: "OCFO", subAgencies: [] },
//         { parent: "Office of the Inspector General", abbreviation: "OIG", subAgencies: [] },
//         { parent: "Office of Operations", abbreviation: "OO", subAgencies: [] },
//         { parent: "Office of Communications", abbreviation: "OCOMM", subAgencies: [] },
//         { parent: "Office of Hearings Operations", abbreviation: "OHO", subAgencies: [] },
//         { parent: "Office of Analytics, Review, and Oversight", abbreviation: "OARO", subAgencies: [] },
//         { parent: "Office of the General Counsel", abbreviation: "OGC", subAgencies: [] },
//         { parent: "Office of Systems", abbreviation: "OS", subAgencies: [] },
//         { parent: "Office of Disability Adjudication and Review", abbreviation: "ODAR", subAgencies: [] },
//         { parent: "Office of Human Resources", abbreviation: "OHR", subAgencies: [] },
//         { parent: "Office of International Programs", abbreviation: "OIP", subAgencies: [] },
//         { parent: "Office of Civil Rights and Equal Opportunity", abbreviation: "OCREO", subAgencies: [] },
//         { parent: "Office of Quality Review", abbreviation: "OQR", subAgencies: [] },] 
//     },
//   ];

