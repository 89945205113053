import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Box, Button, Table, TableBody, TableCell, TableRow, Checkbox, Typography, FormControlLabel, IconButton } from '@mui/material'; // Add FormControlLabel here
import RestartAltIcon from '@mui/icons-material/RestartAlt'; // Import Reset Icon
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SetAsideTypeAccordion = ({ checkedSetAsides, setCheckedSetAsides, SET_ASIDE_MAPS }) => {
  // Handle checkbox changes for set-aside types
  const handleSetAsideChange = (typeCode) => {
    setCheckedSetAsides((prev) =>
      prev.includes(typeCode)
        ? prev.filter((code) => code !== typeCode)
        : [...prev, typeCode]
    );
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: '#f5f5f5', // Very light gray background color
        }}
      >
        {/* Navy Blue Circle with Checked Count */}
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: '#00274D', // Navy blue color
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1, // Margin right to add spacing between the badge and the text
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {checkedSetAsides.length}
          </Typography>
        </Box>

        <Typography>Type of Set-Aside</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Reset Button */}
        <IconButton onClick={() => setCheckedSetAsides([])} sx={{ ml: 1 }}>
          <RestartAltIcon />
          <Typography variant="caption" sx={{ ml: 0.5, fontSize: '0.75rem', textTransform: 'uppercase' }}>
            Uncheck All
          </Typography>
        </IconButton>

        {/* Scrollable Table */}
        <Box sx={{ maxHeight: 300, overflowY: 'auto' }}> {/* Limit height and make scrollable */}
          <Table size="small">
            <TableBody>
              {SET_ASIDE_MAPS.map((setAside, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedSetAsides.includes(setAside.typeCode)}
                          onChange={() => handleSetAsideChange(setAside.typeCode)}
                        />
                      }
                      label={setAside.typeDesc}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SetAsideTypeAccordion;