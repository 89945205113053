import { getFunctions, httpsCallable } from 'firebase/functions';
import { db, app } from '../../firebase'; // Ensure you have your Firebase app initialized

// Initialize Firebase Functions
const functions = getFunctions(app);




// Define a function to call the getUsaDataOverTime cloud function
export const getUsaDataOverTime = async (recipientID, group) => {

  // Reference to the cloud function
  const getDataOverTime = httpsCallable(functions, 'getUsaDataOverTime');
  
  try {
    // Call the cloud function with the required data
    const result = await getDataOverTime({ recipientID, group });

    // console.log("result.data.spendingDataOverTime: " + JSON.stringify(result.data.spendingDataOverTime));
    
    return result.data.spendingDataOverTime; // Assuming the function returns data in result.data
  } catch (error) {
    console.error('Error calling cloud function:', error);
    throw error; // Handle or rethrow the error as needed
  }
};




// Define a function to call the getUsaDataOverTime cloud function
export const getUsaNewAwardsOverTime = async (recipientID, group) => {

    // Reference to the cloud function
    const getNewAwardsOverTime = httpsCallable(functions, 'getUsaNewAwardsOverTime');
    
    try {
      // Call the cloud function with the required data
      const result = await getNewAwardsOverTime({ recipientID, group });

    //   console.log("result.data.newAwardsOverTime: " + JSON.stringify(result.data.newAwardsOverTime));
      
      return result.data.newAwardsOverTime; // Assuming the function returns data in result.data
    } catch (error) {
      console.error('Error calling cloud function:', error);
      throw error; // Handle or rethrow the error as needed
    }
  };