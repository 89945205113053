import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AwardIDAccordion = ({ awardIDsList, setAwardIDsList }) => {
  const [awardID, setAwardID] = useState(''); // State to manage input for a single Award ID

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: '#f5f5f5', // Very light gray color
        }}
      >
        {/* Navy Blue Circle with Item Count */}
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: '#00274D', // Navy blue color
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1, // Margin right to add spacing between the badge and the text
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {awardIDsList.length}
          </Typography>
        </Box>

        <Typography>Award ID</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mb: 2 }}>
          {/* Award ID Input Field and Buttons */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Enter Award ID"
              value={awardID} // Connect input value to state
              onChange={(e) => setAwardID(e.target.value)} // Update state on input change
            />
            <IconButton onClick={() => setAwardID('')} sx={{ ml: 1 }}>
              <ClearIcon />
            </IconButton>
          </Box>
          
          {/* Add Button Below the Input Field */}
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              if (awardID.trim()) { // Check if the input is not empty or just spaces
                setAwardIDsList([...awardIDsList, awardID.trim()]); // Add to award IDs list
                setAwardID(''); // Clear input field after adding
              }
            }}
            sx={{ mb: 2 }}
          >
            Add
          </Button>

          {/* Display the List of Added Award IDs */}
          {awardIDsList.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {item}
              </Typography>
              <IconButton onClick={() => setAwardIDsList(awardIDsList.filter((_, i) => i !== index))}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default AwardIDAccordion;