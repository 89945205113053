export const CONTRACT_PARENT_TYPES = ["idv", "contract", "grant"];
export const CONTRACT_PRICING_TYPES = ["Fixed-Price", "Cost-Reimbursement", "Time-and-Materials", "Other"];



export const CONTRACT_TYPES = [
  { code: 'F', description: 'Firm Fixed Price', type: 'Fixed-Price' },
  { code: 'J', description: 'Firm Fixed Price Level of Effort', type: 'Fixed-Price' },
  { code: 'V', description: 'Fixed Price Incentive', type: 'Fixed-Price' },
  { code: 'H', description: 'Fixed Price Award Fee', type: 'Fixed-Price' },
  { code: 'R', description: 'Fixed Price with Economic Price Adjustment', type: 'Fixed-Price' },
  { code: 'I', description: 'Fixed Price Redetermination', type: 'Fixed-Price' },
  { code: 'G', description: 'Fixed Price with Economic Price Adjustment', type: 'Fixed-Price' },
  { code: 'K', description: 'Order Dependent', type: 'Fixed-Price' },

  { code: 'A', description: 'Cost Plus Fixed Fee', type: 'Cost-Reimbursement' },
  { code: 'B', description: 'Cost Plus Incentive Fee', type: 'Cost-Reimbursement' },
  { code: 'S', description: 'Cost Plus Award Fee', type: 'Cost-Reimbursement' },
  { code: 'L', description: 'Cost Plus Incentive Fee', type: 'Cost-Reimbursement' },
  { code: 'D', description: 'Cost No Fee', type: 'Cost-Reimbursement' },
  { code: 'M', description: 'Cost Sharing', type: 'Cost-Reimbursement' },

  { code: 'T', description: 'Time and Materials', type: 'Time-and-Materials' },
  { code: 'U', description: 'Labor Hours', type: 'Time-and-Materials' },

  { code: 'Y', description: 'Combination', type: 'Other' },
  { code: 'N', description: 'Other', type: 'Other' }
];
