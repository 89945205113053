import React, { createContext, useContext, useEffect, useState } from 'react';
import { authStateListener, login, logout, signup } from '../networking/auth/auth';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = authStateListener(user => {
            setCurrentUser(user);
            setLoading(false);

            // Vivid and obvious logging for auth state
            if (user) {
                console.log('%c[AUTH STATUS] User is signed in:', 'color: green; font-weight: bold;', user);
            } else {
                console.log('%c[AUTH STATUS] User is signed out', 'color: red; font-weight: bold;');
            }
        });

        return unsubscribe;
    }, []);

    const handleLogin = (email, password) => {
        console.log('%c[AUTH ACTION] Attempting to log in...', 'color: blue; font-weight: bold;');
        return login(email, password);
    };

    const handleLogout = () => {
        console.log('%c[AUTH ACTION] Logging out...', 'color: orange; font-weight: bold;');
        return logout();
    };

    const handleSignup = (email, password) => {
        console.log('%c[AUTH ACTION] Attempting to sign up...', 'color: purple; font-weight: bold;');
        return signup(email, password);
    };

    const value = {
        currentUser,
        login: handleLogin,
        logout: handleLogout,
        signup: handleSignup,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};