import { getFunctions, httpsCallable } from 'firebase/functions';
import { db, app } from '../../firebase'; // Ensure you have your Firebase app initialized

// Initialize Firebase Functions
const functions = getFunctions(app);




// Define a function to call the getUsaByStateTerritory cloud function
export const getUsaByStateTerritory = async (recipientID) => {

  // Reference to the cloud function
  const getByStateTerritory = httpsCallable(functions, 'getUsaByStateTerritory');
  
  try {
    // Call the cloud function with the required data
    const result = await getByStateTerritory({ recipientID });
    
    return result.data.spendingDataByStateTerritory; // Assuming the function returns data in result.data
  } catch (error) {
    console.error('Error calling cloud function:', error);
    throw error; // Handle or rethrow the error as needed
  }
};




// Define a function to call the getUsaByCountry cloud function
export const getUsaByCountry = async (recipientID) => {

    // Reference to the cloud function
    const getByCountry = httpsCallable(functions, 'getUsaByCountry');
    
    try {
      // Call the cloud function with the required data
      const result = await getByCountry({ recipientID });
      
      return result.data.spendingDataByCountry; // Assuming the function returns data in result.data
    } catch (error) {
      console.error('Error calling cloud function:', error);
      throw error; // Handle or rethrow the error as needed
    }
  };