import React, { useState } from 'react';
import { Drawer, List, Box, Button } from '@mui/material';

// Import accordion components
import KeywordAccordion from './KeywordAccordion';
import AgencyAccordion from './AgencyAccordion';
import RecipientAccordion from './RecipientAccordion';
import AwardIDAccordion from './AwardIDAccordion';
import NAICSCodeAccordion from './NAICSCodeAccordion';
import TypeOfContractPricingAccordion from './TypeOfContractPricingAccordion';
import SetAsideTypeAccordion from './SetAsideTypeAccordion';
import SolicitationNumberAccordion from './SolicitationNumberAccordion';

import { SET_ASIDE_MAPS } from '../../utils/constants/setAsides';
import { CONTRACT_TYPES } from '../../utils/constants/contractTypes';

import TabbedTable from '../TabbedTable';

const drawerWidth = 350;

const Sidebar = ({ selectedTab, onApply }) => {
  const [selectedAgencies, setSelectedAgencies] = useState([]); // Store agencies, subagencies, and offices
  const [keyword, setKeyword] = useState('');
  const [keywordsList, setKeywordsList] = useState([]);
  const [recipient, setRecipient] = useState('');
  const [recipientsList, setRecipientsList] = useState([]);
  const [awardID, setAwardID] = useState('');
  const [awardIDsList, setAwardIDsList] = useState([]);
  const [checkedNAICS, setCheckedNAICS] = useState([]);
  const [checkedContractTypes, setCheckedContractTypes] = useState([]);
  const [checkedSetAsides, setCheckedSetAsides] = useState([]);
  const [solicitationNumber, setSolicitationNumber] = useState('');
  const [solicitationNumbers, setSolicitationNumbers] = useState([]);

  // Function to compile data for the "Apply" button
  const handleApply = () => {
    const compiledData = [];
    let keywordsListCopy = [...keywordsList]; // Create a copy of keywords list to modify

    // console.log("selectedAgencies: " + JSON.stringify(selectedAgencies));

    // Add selected Agencies, Subagencies, and Offices
    const agenciesObjects = selectedAgencies
      .filter((selected) => !selected.office) // Filter out objects with 'office' property
      .map((selected) => {
        return {
          type: "awarding", // or "funding", depending on your context
          tier: selected.tier,
          name: selected.name,
          toptier_name: selected.toptier_name, // Name of the toptier agency
        };
      });

    if (agenciesObjects.length > 0) {
      compiledData.push({ agencies: agenciesObjects });
    }

    // For those with 'office' property
    selectedAgencies.forEach((selected) => {
      if (selected.office) {
        keywordsListCopy.push(selected.office);
      }
    });

    // Add Solicitation Numbers
    if (solicitationNumbers.length > 0) {
      keywordsListCopy = [...keywordsListCopy, ...solicitationNumbers];
    }

    // Use the updated keywordsListCopy to ensure the office is included
    if (keywordsListCopy.length > 0) {
      compiledData.push({ keywords: keywordsListCopy });
    }

    // Add Recipients
    if (recipientsList.length > 0) {
      compiledData.push({ recipient_search_text: recipientsList });
    }

    // Add Award IDs
    if (awardIDsList.length > 0) {
      compiledData.push({ award_ids: awardIDsList });
    }

    // Add Checked NAICS Codes
    if (checkedNAICS.length > 0) {
      compiledData.push({ naics_codes: checkedNAICS });
    }

    // Add Checked Contract Types
    const contractTypeObjects = checkedContractTypes
      .filter((type) => CONTRACT_TYPES.some((item) => item.code === type)); // Ensure the type exists in CONTRACT_TYPES

    if (contractTypeObjects.length > 0) {
      compiledData.push({ contract_pricing_type_codes: contractTypeObjects });
    }

    // Add Checked Set-Asides
    const setAsideObjects = checkedSetAsides
      .map((setAside) => {
        const foundSetAside = SET_ASIDE_MAPS.find((item) => item.typeCode === setAside);
        // Convert to uppercase if found, otherwise return an empty string
        return foundSetAside ? foundSetAside.typeCode.toUpperCase() : '';
      })
      .filter(Boolean); // Remove any empty strings

    if (setAsideObjects.length > 0) {
      compiledData.push({ set_aside_type_codes: setAsideObjects });
    }

    console.log(JSON.stringify(compiledData));

    // Trigger the onApply callback prop to notify parent of the compiled data
    onApply(compiledData);
  };

  const handleNAICSCheckboxChange = (code) => {
    setCheckedNAICS((prevChecked) =>
      prevChecked.includes(code)
        ? prevChecked.filter((item) => item !== code)
        : [...prevChecked, code]
    );
  };

  const handleContractTypeCheckboxChange = (code) => {
    setCheckedContractTypes((prev) =>
      prev.includes(code) ? prev.filter((item) => item !== code) : [...prev, code]
    );
  };

  const handleSetAsideChange = (typeCode) => {
    setCheckedSetAsides((prev) =>
      prev.includes(typeCode) ? prev.filter((code) => code !== typeCode) : [...prev, typeCode]
    );
  };

  // Clear all selections for the Sidebar
  const handleClearAll = () => {
    setSelectedAgencies([]); // Clear selected agencies
    setCheckedNAICS([]); // Clear NAICS selections
    setCheckedContractTypes([]); // Clear contract types
    setCheckedSetAsides([]); // Clear set asides
    setKeywordsList([]); // Clear keywords
    setRecipientsList([]); // Clear recipients
    setAwardIDsList([]); // Clear award IDs
    setSolicitationNumbers([]); // Clear solicitation numbers

    handleApply();
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          top: '64px',
        },
      }}
    >
      <List>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
          {/* Apply Button */}
          <Button variant="contained" color="primary" onClick={handleApply}>
            Apply
          </Button>

          {/* Clear Button */}
          <Button variant="outlined" color="secondary" onClick={handleClearAll}>
            Clear
          </Button>
        </Box>

        {/* Accordion Components */}
        <KeywordAccordion
          keyword={keyword}
          setKeyword={setKeyword}
          keywordsList={keywordsList}
          setKeywordsList={setKeywordsList}
        />

        <AgencyAccordion
          selectedAgency={selectedAgencies}
          setSelectedAgency={setSelectedAgencies}
        />

        <RecipientAccordion
          recipient={recipient}
          setRecipient={setRecipient}
          recipientsList={recipientsList}
          setRecipientsList={setRecipientsList}
        />

        <AwardIDAccordion
          awardID={awardID}
          setAwardID={setAwardID}
          awardIDsList={awardIDsList}
          setAwardIDsList={setAwardIDsList}
        />

        <NAICSCodeAccordion
          checkedNAICS={checkedNAICS}
          setCheckedNAICS={setCheckedNAICS}
          handleNAICSCheckboxChange={handleNAICSCheckboxChange}
        />

        <TypeOfContractPricingAccordion
          checkedContractTypes={checkedContractTypes}
          setCheckedContractTypes={setCheckedContractTypes}
        />

        <SetAsideTypeAccordion 
          checkedSetAsides={checkedSetAsides} 
          setCheckedSetAsides={setCheckedSetAsides} 
          SET_ASIDE_MAPS={SET_ASIDE_MAPS} 
        />

        <SolicitationNumberAccordion
          solicitationNumber={solicitationNumber}
          setSolicitationNumber={setSolicitationNumber}
          solicitationNumbers={solicitationNumbers}
          setSolicitationNumbers={setSolicitationNumbers}
        />
      </List>
    </Drawer>
    
  );
};

export default Sidebar;