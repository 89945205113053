import { doc, getDoc, setDoc, updateDoc, collection, query, where, getDocs, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';  // Import the necessary functions
import { db, app } from '../firebase'; // Assuming you have firebase initialized here

// Initialize Firebase Functions
const functions = getFunctions(app);  // Initialize Firebase Functions with the app



const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const queryTempStripeUser = async (email) => {

    console.log("queryTempStripeUser: " + email);
    
    let retries = 0;
    const maxRetries = 5;
  
    while (retries < maxRetries) {
      // Step 1: Create a query for the 'tempStripeUser' collection where the 'email' field equals the input email
      const tempStripeUserQuery = query(
        collection(db, 'tempStripeUser'),
        where('email', '==', email)
      );
  
      // Step 2: Execute the query and get matching documents
      const querySnapshot = await getDocs(tempStripeUserQuery);
  
      // Step 3: Check if any document exists
      if (!querySnapshot.empty) {
        const docSnapshot = querySnapshot.docs[0];  // Get the first matching document
        const data = docSnapshot.data();
  
        return {
          priceId: data.priceId,
          stripeCustomerId: data.stripeCustomerId,
          subscriptionStatus: data.subscriptionStatus,
          subscriptionId: data.subscriptionId,
          subscriptionCreated: data.subscriptionCreated,
          subscriptionProduct: data.subscriptionProduct,
          subscriptionCurrentPeriodEnd: data.subscriptionCurrentPeriodEnd,
        };
      }
  
      retries++;
      if (retries < maxRetries) {
        await wait(2000); // Wait for 2 seconds before retrying
      }
    }
  
    return null; // If no matching document is found after retries
  };




  export const updateUserAccount = async (email, tempStripeUserData) => {
    // Step 1: Query for the user document where the email field matches the provided email
    const userQuery = query(
      collection(db, 'user'),
      where('email', '==', email)
    );
  
    // Step 2: Execute the query and get matching documents
    const querySnapshot = await getDocs(userQuery);
  
    // Step 3: Check if a document with the provided email exists
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0]; // Get the first matching document
      const userRef = userDoc.ref; // Get the reference of the user document
  
      // Step 4: Update the document with the tempStripeUserData
      await updateDoc(userRef, {
        priceId: tempStripeUserData.priceId,
        stripeCustomerId: tempStripeUserData.stripeCustomerId,
        subscriptionStatus: tempStripeUserData.subscriptionStatus,
        subscriptionId: tempStripeUserData.subscriptionId,
        subscriptionCreated: tempStripeUserData.subscriptionCreated,
        subscriptionProduct: tempStripeUserData.subscriptionProduct,
        subscriptionCurrentPeriodEnd: tempStripeUserData.subscriptionCurrentPeriodEnd,
        isDigestPaid: true, // Set isDigestPaid to true
      });
  
      return true; // Return true if successfully updated
    }
  
    return false; // Return false if no document with the provided email was found
  };




  export const createUserDocument = async (userID, email, company, tempStripeUserData) => {
    const timestampSignup = Date.now(); // Get the current timestamp
  
    const userRef = doc(db, 'user', userID);
  
    await setDoc(userRef, {
      userID: userID,
      email: email,
      company: company,
      isDigestPaid: true,
      timestampSignup: timestampSignup,
      timestampLastUser: timestampSignup,
      priceId: tempStripeUserData.priceId,
      stripeCustomerId: tempStripeUserData.stripeCustomerId,
      subscriptionStatus: tempStripeUserData.subscriptionStatus,
      subscriptionId: tempStripeUserData.subscriptionId,
      subscriptionCreated: tempStripeUserData.subscriptionCreated,
      subscriptionProduct: tempStripeUserData.subscriptionProduct,
      subscriptionCurrentPeriodEnd: tempStripeUserData.subscriptionCurrentPeriodEnd,
    });
  
    return true; // Return true if document creation was successful
  };


  



// This function will call the getSolDashSearchResults cloud function
export const sendSupportEmailToAdmin = async (email, message) => {
  try {
    // Prepare the callable cloud function using httpsCallable
    const sendPlatformSupportEmailToAdmin = httpsCallable(functions, 'sendPlatformSupportEmailToAdmin');

    const data = {
        email: email,
        message: message
    };

    // Call the cloud function with the prepared data
    const result = await sendPlatformSupportEmailToAdmin(data);

    return result.data;
  } catch (error) {
    console.error('Error fetching search results:', error);
    throw error;  // Optionally throw the error for further handling
  }
};




// Function to get subscription details based on the user's email
export const getSubscriptionDetails = async (userID) => {
    try {
      const getSubscriptionDetailsFunc = httpsCallable(functions, 'getSubscriptionDetails');
      const response = await getSubscriptionDetailsFunc({ userID }); // Pass email as parameter
      return response.data; // Return the data from the cloud function
    } catch (error) {
      console.error('Error getting subscription details:', error);
      throw new Error('Failed to retrieve subscription details.');
    }
  };




  
// Function to change the user's subscription by userID and newPriceId
export const changeSubscription = async (userID, newPriceId) => {
    try {
      const changeSubscriptionFunc = httpsCallable(functions, 'changeSubscription');
      const response = await changeSubscriptionFunc({ userID, newPriceId }); // Pass userID and newPriceId as parameters
      return response.data; // Return the data from the cloud function
    } catch (error) {
      console.error('Error changing subscription:', error);
      throw new Error('Failed to change subscription.');
    }
  };