import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SolicitationNumberAccordion = ({
  solicitationNumbers,
  setSolicitationNumbers,
}) => {
  const [solicitationNumber, setSolicitationNumber] = useState(''); // Local state for the input field

  const handleAddSolicitationNumber = () => {
    if (solicitationNumber.trim()) {
      setSolicitationNumbers((prev) => [...prev, solicitationNumber.trim()]);
      setSolicitationNumber(''); // Clear input field after adding
    }
  };

  const handleRemoveSolicitationNumber = (indexToRemove) => {
    setSolicitationNumbers((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: '#f5f5f5', // Very light gray background color
        }}
      >
        {/* Navy Blue Circle with Item Count */}
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: '#00274D', // Navy blue color
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1, // Margin right to add spacing between the badge and the text
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {solicitationNumbers.length}
          </Typography>
        </Box>

        <Typography>Solicitation Number</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mb: 2 }}>
          {/* Solicitation Number Input Field and Buttons */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Enter Solicitation Number"
              value={solicitationNumber}
              onChange={(e) => setSolicitationNumber(e.target.value)}
            />
            <IconButton onClick={() => setSolicitationNumber('')} sx={{ ml: 1 }}>
              <ClearIcon />
            </IconButton>
          </Box>

          {/* Add Button Below the Input Field */}
          <Button
            fullWidth
            variant="contained"
            onClick={handleAddSolicitationNumber}
            sx={{ mb: 2 }}
          >
            Add
          </Button>

          {/* Display the List of Added Solicitation Numbers */}
          {solicitationNumbers.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {item}
              </Typography>
              <IconButton onClick={() => handleRemoveSolicitationNumber(index)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SolicitationNumberAccordion;