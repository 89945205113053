import React from 'react';
import { Tabs, Tab } from '@mui/material';

const TabControl = ({ tabValue, handleTabChange }) => {
  return (
    <Tabs value={tabValue} onChange={handleTabChange} aria-label="Table Tabs">
      <Tab label="IDVs" />
      <Tab label="Contracts" />
      <Tab label="Grants" />
    </Tabs>
  );
};

export default TabControl;