import React, { useState, useEffect } from 'react';
import { Box, ButtonGroup, Button, Typography, CircularProgress } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, LineController, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getUsaDataOverTime, getUsaNewAwardsOverTime } from '../../networking/entity/entityTotCalls';

// Register the required components
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, LineController, Title, Tooltip, Legend);

const TransactionsOverTime = ({ recipientData }) => {
  const recipientID = recipientData.recipient_id;

  const [timePeriod, setTimePeriod] = useState('Year');
  const [chartData, setChartData] = useState({
    labels: [], 
    datasets: []
  });
  const [loading, setLoading] = useState(true); // State for loading indicator

  // Fetch data based on the selected time period
  const fetchData = async (period) => {
    setLoading(true); // Start loading
    let group;
    switch (period) {
      case 'Year':
        group = 'fiscal_year';
        break;
      case 'Quarter':
        group = 'quarter';
        break;
      case 'Month':
        group = 'month';
        break;
      default:
        group = 'fiscal_year';
    }

    try {
      const [dataOverTimeResponse, newAwardsOverTimeResponse] = await Promise.all([
        getUsaDataOverTime(recipientID, group),
        getUsaNewAwardsOverTime(recipientID, group)
      ]);

      if (!Array.isArray(dataOverTimeResponse) || !Array.isArray(newAwardsOverTimeResponse)) {
        console.error('API responses are not in expected array format:', dataOverTimeResponse, newAwardsOverTimeResponse);
        return;
      }

      const formattedChartData = formatChartData(dataOverTimeResponse, newAwardsOverTimeResponse, period);
      setChartData(formattedChartData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const formatChartData = (dataOverTimeResults = [], newAwardsResults = [], period) => {
    const labels = (dataOverTimeResults || []).map((result) => {
      if (period === 'Year') return `FY ${result?.time_period?.fiscal_year || ''}`;
      if (period === 'Quarter') return `Q${result?.time_period?.quarter || ''} ${result?.time_period?.fiscal_year || ''}`;
      if (period === 'Month') return `${new Date(result?.time_period?.month || '').toLocaleString('default', { month: 'short' })} ${result?.time_period?.fiscal_year || ''}`;
      return '';
    });

    const cumulativeData = (dataOverTimeResults || []).map((result) => Math.max(0, result?.aggregated_amount || 0));
    const newAwardsData = (newAwardsResults || []).map((result) => Math.max(0, result?.new_award_count_in_period || 0));

    return {
      labels,
      datasets: [
        {
          type: 'line',
          label: 'New Awards Count',
          borderColor: 'rgb(75, 192, 192)',
          borderWidth: 2,
          fill: false,
          data: newAwardsData,
          yAxisID: 'y2',
        },
        {
          type: 'bar',
          label: 'Cumulative Amount',
          backgroundColor: 'rgb(54, 162, 235)',
          data: cumulativeData,
          borderColor: 'white',
          borderWidth: 2,
          yAxisID: 'y1',
        },
      ],
    };
  };

  const handleTimePeriodChange = (period) => {
    setTimePeriod(period);
    fetchData(period); 
  };

  useEffect(() => {
    fetchData(timePeriod);
  }, [timePeriod]);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="subtitle1" gutterBottom>
        This graph shows trends over time for all transactions to this recipient. Hover over the bars for more detailed information.
      </Typography>

      <ButtonGroup variant="contained" sx={{ mb: 3 }}>
        <Button onClick={() => handleTimePeriodChange('Year')} color={timePeriod === 'Year' ? 'primary' : 'secondary'}>Year</Button>
        <Button onClick={() => handleTimePeriodChange('Quarter')} color={timePeriod === 'Quarter' ? 'primary' : 'secondary'}>Quarter</Button>
        <Button onClick={() => handleTimePeriodChange('Month')} color={timePeriod === 'Month' ? 'primary' : 'secondary'}>Month</Button>
      </ButtonGroup>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Bar 
          data={chartData} 
          options={{
            responsive: true,
            scales: {
              y1: {
                beginAtZero: true,
                position: 'left',
                title: {
                  display: true,
                  text: 'Award Amount (USD)'
                },
                ticks: {
                  callback: function (value) {
                    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value);
                  }
                },
              },
              y2: {
                beginAtZero: true,
                position: 'right',
                title: {
                  display: true,
                  text: 'New Awards Count'
                },
                grid: {
                  drawOnChartArea: false,
                }
              }
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const label = context.dataset.label || '';
                    const value = context.raw;
                    return `${label}: ${label === 'Cumulative Amount' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value) : value}`;
                  }
                }
              }
            }
          }} 
        />
      )}
    </Box>
  );
};

export default TransactionsOverTime;