import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Checkbox,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestartAltIcon from '@mui/icons-material/RestartAlt'; // Import Reset Icon
import { NAICS_MAPS } from '../../utils/constants/naics';

const NAICSCodeAccordion = ({ checkedNAICS, setCheckedNAICS }) => {
  const [naicsSearchTerm, setNaicsSearchTerm] = useState(''); // State for search term

  // Function to handle NAICS checkbox changes
  const handleNAICSCheckboxChange = (code) => {
    setCheckedNAICS((prevChecked) =>
      prevChecked.includes(code)
        ? prevChecked.filter((item) => item !== code) // Uncheck if already checked
        : [...prevChecked, code] // Add to checked list
    );
  };

  // Filter the NAICS codes based on the search term
  const filteredNAICS = NAICS_MAPS.filter(
    (item) =>
      item.code.toLowerCase().includes(naicsSearchTerm.toLowerCase()) ||
      item.description.toLowerCase().includes(naicsSearchTerm.toLowerCase())
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: '#f5f5f5', // Very light gray background color
        }}
      >
        {/* Navy Blue Circle with Item Count */}
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: '#00274D', // Navy blue color
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1, // Margin right for spacing
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {checkedNAICS.length} {/* Display the count of checked items */}
          </Typography>
        </Box>

        <Typography>NAICS Code</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Search Bar with Clear Button and Reset Button */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Search NAICS Codes"
            value={naicsSearchTerm}
            onChange={(e) => setNaicsSearchTerm(e.target.value)}
            sx={{ flexGrow: 1 }}
          />
          <IconButton onClick={() => setNaicsSearchTerm('')} sx={{ ml: 1 }}>
            <ClearIcon />
          </IconButton>
          <IconButton onClick={() => setCheckedNAICS([])} sx={{ ml: 1 }}>
            <RestartAltIcon />
          </IconButton>
        </Box>

        {/* Filtered Results Table with Scrollable Container */}
        <TableContainer component={Paper} sx={{ maxHeight: 400, overflowY: 'auto' }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Include</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredNAICS.slice(0, 10).map((item, index) => ( // Limit to 10 items
                <TableRow key={index}>
                  <TableCell>{item.code}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={checkedNAICS.includes(item.code)} // Check if the code is in the checked list
                      onChange={() => handleNAICSCheckboxChange(item.code)} // Handle checkbox change
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default NAICSCodeAccordion;