import React, { forwardRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from '@mui/material';

const DataTable = forwardRef(({ sortedAwards, handleSort, handlePrimeAwardClick, handleRecipientClick, orderBy, order, tabValue }, ref) => {
  return (
    <TableContainer component={Paper} sx={{ height: 'calc(90vh - 250px)' }} ref={ref}>
      <Table stickyHeader aria-label="data table">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'Award ID'}
                direction={order}
                onClick={() => handleSort('Award ID')}
              >
                Prime Award ID
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'Recipient Name'}
                direction={order}
                onClick={() => handleSort('Recipient Name')}
              >
                Recipient Name
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ minWidth: 150, maxWidth: 300, overflowWrap: 'break-word' }}>
              <TableSortLabel
                active={orderBy === 'Description'}
                direction={order}
                onClick={() => handleSort('Award Description')}
              >
                Award Description
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'Contract Award Type' || orderBy === 'Award Type'}
                direction={order}
                onClick={() => handleSort('Contract Award Type')}
              >
                Award Type
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'PoP'}
                direction={order}
                onClick={() => handleSort(tabValue === 0 ? 'Last Date to Order' : 'End Date')}
              >
                PoP
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'Obligated Amount'}
                direction={order}
                onClick={() => handleSort('Obligated Amount')}
              >
                Obligated Amount
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedAwards.map((award) => (
            <TableRow key={award["internal_id"]}>
              <TableCell sx={{ cursor: 'pointer', color: 'blue' }} onClick={() => handlePrimeAwardClick(award["internal_id"])}>
                {award["Award ID"]}
              </TableCell>
              <TableCell sx={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleRecipientClick(award["Recipient Name"], award["recipient_id"])}>
                {award["Recipient Name"]}
              </TableCell>
              <TableCell sx={{ minWidth: 150, maxWidth: 300, overflowWrap: 'break-word' }}>
                {award["Description"]}
              </TableCell>
              <TableCell>
                {award['Contract Award Type'] ? award['Contract Award Type'] : award['Award Type']}
              </TableCell>
              <TableCell>
                {`${new Date(award["Start Date"]).toLocaleDateString('en-US')} - ${
                  tabValue === 0
                    ? new Date(award["Last Date to Order"]).toLocaleDateString('en-US')
                    : new Date(award["End Date"]).toLocaleDateString('en-US')
                }`}
              </TableCell>
              <TableCell>
                {tabValue === 0 && award["Award Amount"] === 0
                  ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(award["Total Outlays"])
                  : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(award["Award Amount"])}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default DataTable;