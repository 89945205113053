import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import { FEDERAL_AGENCIES } from '../../utils/constants/agencies';
import { realtimeSearchOfficesForAgency } from '../../networking/dash/dashCalls';

const AgencyAccordion = ({ selectedAgency, setSelectedAgency }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSubAgencies, setFilteredSubAgencies] = useState([]);
  const [selectedSubAgency, setSelectedSubAgency] = useState(null);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [offices, setOffices] = useState([]);
  const [filteredOffices, setFilteredOffices] = useState([]);
  const [showOffices, setShowOffices] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonsVisible, setButtonsVisible] = useState(false);

  // When the selectedAgency prop changes, reset local state if it gets cleared
  useEffect(() => {
    if (selectedAgency.length === 0) {
      // Reset local states when the Clear button is pressed
      setSelectedSubAgency(null);
      setSelectedOffice(null);
      setSearchTerm('');
      setShowOffices(false);
      setButtonsVisible(false);
    }
  }, [selectedAgency]);

  // Filter subagencies based on search term
  useEffect(() => {
    if (searchTerm && !showOffices) {
      const filtered = FEDERAL_AGENCIES.flatMap((agency) =>
        agency.subAgencies
          .filter((sub) =>
            sub.parent.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((sub) => ({ parent: agency.parent, subagency: sub.parent }))
      );
      setFilteredSubAgencies(filtered);
    } else {
      setFilteredSubAgencies([]);
    }
  }, [searchTerm, showOffices]);

  // Query Firestore for offices on text change if 3 or more characters are entered
  useEffect(() => {
    if (showOffices && searchTerm.length >= 3) {
      setLoading(true); // Start loading
      realtimeSearchOfficesForAgency(searchTerm)
        .then((data) => {
          setOffices(data);
          setFilteredOffices(data); // Show all matching offices
        })
        .catch((error) => console.error('Error fetching offices:', error))
        .finally(() => setLoading(false)); // Stop loading
    } else {
      setFilteredOffices([]); // Clear filtered offices if fewer than 3 characters
    }
  }, [searchTerm, showOffices]);

  // Clear search term
  const clearSearch = () => {
    setSearchTerm('');
    setFilteredSubAgencies([]);
    setFilteredOffices([]); // Reset to all offices when cleared
  };

  // Handle selection of a subagency
  const handleSelectSubAgency = (agency) => {
    setSelectedSubAgency(agency);
    setSearchTerm('');
    setShowOffices(true);
    setButtonsVisible(true); // Show Continue Without Office button
  };

  const handleSelectOffice = (office) => {
    setSelectedOffice(office);

    // Create the selected object with the office
    const selectedObj = {
      type: "awarding", // or "funding" depending on context
      tier: "subtier",
      name: selectedSubAgency.subagency,
      toptier_name: selectedSubAgency.parent,
      office: office.code, // Add office if selected
    };

    // Update the selected items in the parent component
    setSelectedAgency((prevItems) => [...prevItems, selectedObj]);
    setShowOffices(false);
    setSearchTerm('');
    setButtonsVisible(false); // Hide buttons after selection
  };

  // Handle adding selected subagency without an office
  const handleAddSubAgencyWithoutOffice = () => {
    const selectedObj = {
      type: "awarding", // or "funding" depending on context
      tier: "subtier",
      name: selectedSubAgency.subagency,
      toptier_name: selectedSubAgency.parent,
    };

    // Add selected object without office
    setSelectedAgency((prevItems) => [...prevItems, selectedObj]);
    setShowOffices(false);
    setSearchTerm('');
    setButtonsVisible(false); // Hide buttons after selection
  };

  // Handle clearing all selections
  const handleClearSelection = () => {
    setSelectedSubAgency(null);
    setSelectedOffice(null);
    setSearchTerm('');
    setShowOffices(false);
    setButtonsVisible(false); // Hide buttons when reset
    setSelectedAgency([]); // Clear all selected items in parent state
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ backgroundColor: '#f5f5f5' }}>
        {/* Badge with Count */}
        <Box sx={{ width: 24, height: 24, borderRadius: '50%', backgroundColor: '#00274D', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 1 }}>
          <Typography variant="body2" sx={{ color: 'white' }}>{selectedAgency.length}</Typography>
        </Box>
        <Typography>Agency</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Search Bar with Clear Button */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label={showOffices ? "Search Offices" : "Search Subagencies"}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <IconButton onClick={clearSearch} sx={{ ml: 1 }}>
            <ClearIcon />
          </IconButton>
        </Box>

        {/* Display Selected Agency/Subagency/Office */}
        {(selectedSubAgency || selectedOffice) && (
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Typography variant="body2">
              {`${selectedSubAgency?.parent || ''} / ${selectedSubAgency?.subagency || ''}${selectedOffice ? ` / ${selectedOffice.name}` : ''}`}
            </Typography>
            <IconButton onClick={handleClearSelection}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        )}

        {/* Vertically stacked buttons without outline */}
        {buttonsVisible && (
          <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Button 
              variant="contained" 
              onClick={handleAddSubAgencyWithoutOffice}
            >
              Continue Without Office
            </Button>
          </Box>
        )}

        {/* Show loading spinner while fetching offices */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50px' }}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Box sx={{ maxHeight: '250px', overflowY: 'auto' }}>
            {showOffices ? (
              // Display filtered offices with scrolling window
              filteredOffices.map((office, index) => (
                <Typography key={index} onClick={() => handleSelectOffice(office)}>
                  {office.name} - {office.code}
                </Typography>
              ))
            ) : (
              // Display filtered subagencies
              filteredSubAgencies.map((agency, index) => (
                <Typography key={index} onClick={() => handleSelectSubAgency(agency)}>
                  {`${agency.parent} / ${agency.subagency}`}
                </Typography>
              ))
            )}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AgencyAccordion;