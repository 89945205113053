import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RestartAltIcon from '@mui/icons-material/RestartAlt'; // Import Reset Icon
import { CONTRACT_TYPES } from '../../utils/constants/contractTypes';

const TypeOfContractPricingAccordion = ({
  checkedContractTypes,
  setCheckedContractTypes,
}) => {
  const handleContractTypeCheckboxChange = (code) => {
    setCheckedContractTypes((prev) =>
      prev.includes(code) ? prev.filter((item) => item !== code) : [...prev, code]
    );
  };

  const handleResetContractTypes = () => {
    setCheckedContractTypes([]);
  };

  // Helper to calculate the number of checked items for a given type
  const getCheckedCountForType = (type) => {
    return checkedContractTypes.filter(
      (code) => CONTRACT_TYPES.find((item) => item.code === code).type === type
    ).length;
  };

  // Calculate total checked items for all types
  const totalCheckedCount = checkedContractTypes.length;

  // Get unique types from CONTRACT_TYPES
  const uniqueTypes = [...new Set(CONTRACT_TYPES.map((item) => item.type))];

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: '#f5f5f5', // Very light gray background color
        }}
      >
        {/* Solid Navy Blue Circle with Total Item Count */}
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: '#00274D', // Navy blue color
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1, // Margin right to add spacing between the badge and the text
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {totalCheckedCount}
          </Typography>
        </Box>

        <Typography>Type of Contract Pricing</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* Reset Button */}
        <IconButton onClick={handleResetContractTypes} sx={{ ml: 1 }}>
          <RestartAltIcon />
          <Typography variant="caption" sx={{ ml: 0.5, fontSize: '0.75rem', textTransform: 'uppercase' }}>
            Uncheck All
          </Typography>
        </IconButton>

        {/* Sub-Accordions for Each Contract Type */}
        {uniqueTypes.map((type, index) => {
          const checkedCountForType = getCheckedCountForType(type); // Checked count for this type

          return (
            <Accordion key={index} sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {/* Outlined Navy Blue Circle with Checked Count for Each Type */}
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    border: '2px solid #00274D', // Navy blue border
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mr: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ color: '#00274D' }}>
                    {checkedCountForType}
                  </Typography>
                </Box>

                <Typography>{type}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {CONTRACT_TYPES.filter((item) => item.type === type).map((contract, idx) => (
                  <FormControlLabel
                    key={idx}
                    control={
                      <Checkbox
                        checked={checkedContractTypes.includes(contract.code)}
                        onChange={() => handleContractTypeCheckboxChange(contract.code)}
                      />
                    }
                    label={contract.description}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default TypeOfContractPricingAccordion;