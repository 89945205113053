import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const LoadingSpinner = ({ loading }) => {
  return (
    loading && (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
        }}
      >
        <CircularProgress />
      </Box>
    )
  );
};

export default LoadingSpinner;