import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();

  const location = useLocation(); // Get the current location
  const queryParams = new URLSearchParams(location.search); // Parse the URL parameters
  const status = queryParams.get('status'); // Get the 'status' parameter

  // If status is 'success', redirect to CompleteAccount page
  if (status === 'success') {
    return <Navigate to="/complete-account" replace />;
  }

  return currentUser ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;