import { auth } from '../../firebase';  // Adjust the import path to point to firebase.js
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged, getAuth, sendPasswordResetEmail } from 'firebase/auth';

// Forgot password
export const forgotPassword = async (email) => {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      switch (error.code) {
        case 'auth/invalid-email':
          alert('Invalid email address.');
          break;
        case 'auth/user-not-found':
          alert('No account found with this email.');
          break;
        default:
          alert('Error sending password reset email. Please try again later.');
      }
    }
  };

// Function to sign up a new user
export const signup = (email, password) => createUserWithEmailAndPassword(auth, email, password);

// Function to log in an existing user
export const login = (email, password) => signInWithEmailAndPassword(auth, email, password);

// Function to log out the user
export const logout = () => signOut(auth);

// Function to listen to authentication state changes
export const authStateListener = (callback) => onAuthStateChanged(auth, callback);

export default auth;