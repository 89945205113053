import React, { useState, useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import Sidebar from '../components/Sidebar/Sidebar';
import TabbedTable from '../components/TabbedTable';
import BreadcrumbsNav from '../components/BreadcrumbsNav';

const Dashboard = () => {
  const [awards, setAwards] = useState(() => {
    const savedAwards = sessionStorage.getItem('awards');
    return savedAwards ? JSON.parse(savedAwards) : [];
  });

  const [selectedTab, setSelectedTab] = useState(() => {
    const savedTab = sessionStorage.getItem('selectedDashboardTab');
    return savedTab ? JSON.parse(savedTab) : 0;
  });

  const [keywords, setKeywords] = useState([]); // Store keywords from Sidebar
  const tabbedTableRef = useRef();

  const handleTabChange = (newTab, resetAwards = []) => {
    setSelectedTab(newTab);
    sessionStorage.setItem('selectedDashboardTab', JSON.stringify(newTab));

    if (resetAwards.length === 0) {
      setAwards([]);
      sessionStorage.removeItem('awards');
    }
  };

  const handleSetAwards = (newAwards) => {
    setAwards(newAwards);
    sessionStorage.setItem('awards', JSON.stringify(newAwards));
  };

  useEffect(() => {
    sessionStorage.setItem('awards', JSON.stringify(awards));
  }, [awards]);

  const handleApply = (compiledData) => {
    setKeywords(compiledData); // Set keywords from Sidebar sidebarKeywords
    tabbedTableRef.current.fetchAwardsData(compiledData, selectedTab, 1); // Trigger fetch with new keywords
  };

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)' }}>
      <Sidebar selectedTab={selectedTab} onApply={handleApply} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: 'hidden' }}>
        <BreadcrumbsNav />
        <TabbedTable
          ref={tabbedTableRef}
          onTabChange={handleTabChange}
          awards={awards}
          setAwards={handleSetAwards}
          selectedTab={selectedTab}
          keywords={keywords} // Pass keywords as props
        />
      </Box>
    </Box>
  );
};

export default Dashboard;