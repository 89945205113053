import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, 
  TableContainer, 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Paper, 
  Button, 
  TableSortLabel, 
  Tabs, 
  Tab 
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { getUsaRecipientAwardsByType } from '../../networking/entity/entityAwardCalls';

const Awards = ({ recipientData }) => {
  const navigate = useNavigate();
  
  const [awards, setAwards] = useState({
    idv: [],
    contract: [],
    grant: [],
  });

  const [page, setPage] = useState({
    idv: 1,
    contract: 1,
    grant: 1,
  });

  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('Award ID');
  const [selectedTab, setSelectedTab] = useState(0);
  const recipientID = recipientData.name;

  const alreadyFetched = useRef({
    idv: false,
    contract: false,
    grant: false,
  });

  // Determine the award type based on the selected tab
  const getAwardType = (tabIndex) => {
    return tabIndex === 0 ? 'idv' : tabIndex === 1 ? 'contract' : 'grant';
  };

  // Cache the award type for the current tab
  const awardType = getAwardType(selectedTab);

  // Fetch awards data only if not already fetched
  const fetchAwardsData = async (reset = false) => {
    if (!reset && alreadyFetched.current[awardType]) {
      return; // Don't fetch again if data has already been loaded
    }

    setLoading(true);

    try {
      const awardsData = await getUsaRecipientAwardsByType(awardType, recipientID, page[awardType]);

      setAwards((prevAwards) => ({
        ...prevAwards,
        [awardType]: reset ? awardsData : [...prevAwards[awardType], ...awardsData],
      }));
      
      alreadyFetched.current[awardType] = true; // Mark this tab's data as fetched
      setLoading(false);
    } catch (error) {
      console.error('Error fetching awards data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data on tab/recipient change if not already fetched
    if (!alreadyFetched.current[awardType]) {
      fetchAwardsData(true);
    }
  }, [recipientID, selectedTab]);

  // Handle sorting
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    sortData(property, isAsc ? 'desc' : 'asc');
  };

  const sortData = (property, sortOrder) => {
    const sortedAwards = [...awards[awardType]].sort((a, b) => {
      if (b[property] < a[property]) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (b[property] > a[property]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setAwards((prevAwards) => ({
      ...prevAwards,
      [awardType]: sortedAwards,
    }));
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Handle "Load More" functionality
  const handleLoadMore = () => {
    setPage((prevPage) => ({
      ...prevPage,
      [awardType]: prevPage[awardType] + 1,
    }));
  };

  // Handle navigation to a specific award page
  const handlePrimeAwardClick = (id) => {
    navigate(`/contract/${id}`);
  };

  const currentTabData = awards[awardType];

  return (
    <Box sx={{ p: 3 }}>
      {/* Tabs for IDVs, Contracts, Grants */}
      <Tabs value={selectedTab} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label="IDVs" />
        <Tab label="Contracts" />
        <Tab label="Grants" />
      </Tabs>

      <Box sx={{ position: 'relative' }}>
        {/* Table for displaying data */}
        <TableContainer component={Paper} sx={{ opacity: loading ? 0.5 : 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'Award ID'}
                    direction={orderBy === 'Award ID' ? order : 'asc'}
                    onClick={() => handleSort('Award ID')}
                  >
                    Prime Award ID
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'Awarding Agency'}
                    direction={orderBy === 'Awarding Agency' ? order : 'asc'}
                    onClick={() => handleSort('Awarding Agency')}
                  >
                    Awarding Agency
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'Awarding Sub Agency'}
                    direction={orderBy === 'Awarding Sub Agency' ? order : 'asc'}
                    onClick={() => handleSort('Awarding Sub Agency')}
                  >
                    Awarding Subagency
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'Description'}
                    direction={orderBy === 'Description' ? order : 'asc'}
                    onClick={() => handleSort('Description')}
                  >
                    Award Description
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'Contract Award Type' || orderBy === 'Award Type'}
                    direction={order}
                    onClick={() => handleSort('Contract Award Type')}
                  >
                    Award Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'Award Amount'}
                    direction={orderBy === 'Award Amount' ? order : 'desc'}
                    onClick={() => handleSort('Award Amount')}
                  >
                    Obligations
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentTabData.map((award, index) => (
                <TableRow key={index} sx={{ height: '40px' }}>
                  <TableCell
                    sx={{ cursor: 'pointer', color: 'blue' }}
                    onClick={() => handlePrimeAwardClick(award['internal_id'])}
                  >
                    {award['Award ID']}
                  </TableCell>
                  <TableCell>{award['Awarding Agency']}</TableCell>
                  <TableCell>{award['Awarding Sub Agency']}</TableCell>
                  <TableCell>{award['Description']}</TableCell>
                  <TableCell>
                    {award['Contract Award Type'] ? award['Contract Award Type'] : award['Award Type']}
                  </TableCell>
                  <TableCell>
                    {award["Award Amount"] === 0
                      ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                          award["Total Outlays"]
                        )
                      : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                          award["Award Amount"]
                        )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Display loading spinner over the table when loading */}
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>

      {/* Load More Button */}
      {currentTabData.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleLoadMore}
            disabled={loading}
          >
            {loading ? 'Loading...' : 'Load More'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Awards;