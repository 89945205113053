export const NAICS_MAPS = [
    {
      "code": "0",
      "description": ""
    },
    {
      "code": "111110",
      "description": "Soybean Farming"
    },
    {
      "code": "111120",
      "description": "Oilseed (except Soybean) Farming"
    },
    {
      "code": "111130",
      "description": "Dry Pea and Bean Farming"
    },
    {
      "code": "111140",
      "description": "Wheat Farming"
    },
    {
      "code": "111150",
      "description": "Corn Farming"
    },
    {
      "code": "111160",
      "description": "Rice Farming"
    },
    {
      "code": "111190",
      "description": "Other Grain Farming"
    },
    {
      "code": "111211",
      "description": "Potato Farming"
    },
    {
      "code": "111219",
      "description": "Other Vegetable (except Potato) and Melon Farming"
    },
    {
      "code": "111310",
      "description": "Orange Groves"
    },
    {
      "code": "111320",
      "description": "Citrus (except Orange) Groves"
    },
    {
      "code": "111330",
      "description": "Non-Citrus Fruit and Tree Nut Farming"
    },
    {
      "code": "111411",
      "description": "Mushroom Production"
    },
    {
      "code": "111419",
      "description": "Other Food Crops Grown Under Cover"
    },
    {
      "code": "111421",
      "description": "Nursery and Tree Production"
    },
    {
      "code": "111422",
      "description": "Floriculture Production"
    },
    {
      "code": "111910",
      "description": "Tobacco Farming"
    },
    {
      "code": "111920",
      "description": "Cotton Farming"
    },
    {
      "code": "111930",
      "description": "Sugar Cane Farming"
    },
    {
      "code": "111940",
      "description": "Hay Farming"
    },
    {
      "code": "111993",
      "description": "Fruit and Vegetable Combination Farming"
    },
    {
      "code": "111994",
      "description": "Maple Syrup and Products Production"
    },
    {
      "code": "111999",
      "description": "All Other Miscellaneous Crop Farming"
    },
    {
      "code": "112110",
      "description": "\"Beef Cattle Ranching and Farming, including Feedlots\""
    },
    {
      "code": "112120",
      "description": "Dairy Cattle and Milk Production"
    },
    {
      "code": "112210",
      "description": "Hog and Pig Farming"
    },
    {
      "code": "112310",
      "description": "Chicken Egg Production"
    },
    {
      "code": "112320",
      "description": "Broiler and Other Meat-Type Chicken Production"
    },
    {
      "code": "112330",
      "description": "Turkey Production"
    },
    {
      "code": "112340",
      "description": "Poultry Hatcheries"
    },
    {
      "code": "112391",
      "description": "Combination Poultry and Egg Production"
    },
    {
      "code": "112399",
      "description": "All Other Poultry Production"
    },
    {
      "code": "112410",
      "description": "Sheep Farming"
    },
    {
      "code": "112420",
      "description": "Goat Farming"
    },
    {
      "code": "112510",
      "description": "Aquaculture"
    },
    {
      "code": "112910",
      "description": "Apiculture"
    },
    {
      "code": "112920",
      "description": "Horse and Other Equine Production"
    },
    {
      "code": "112930",
      "description": "Fur-Bearing Animal and Rabbit Production"
    },
    {
      "code": "112991",
      "description": "Animal Combination Farming"
    },
    {
      "code": "112999",
      "description": "All Other Miscellaneous Animal Production"
    },
    {
      "code": "113110",
      "description": "Timber Tract Operations"
    },
    {
      "code": "113210",
      "description": "Forest Nurseries and Gathering of Forest Products"
    },
    {
      "code": "113311",
      "description": "Logging (except Contract)"
    },
    {
      "code": "113312",
      "description": "Contract Logging"
    },
    {
      "code": "114113",
      "description": "Salt Water Fishing"
    },
    {
      "code": "114114",
      "description": "Inland Fishing"
    },
    {
      "code": "114210",
      "description": "Hunting and Trapping"
    },
    {
      "code": "115110",
      "description": "Support Activities for Crop Production"
    },
    {
      "code": "115210",
      "description": "Support Activities for Animal Production"
    },
    {
      "code": "115310",
      "description": "Support Activities for Forestry"
    },
    {
      "code": "211113",
      "description": "Conventional Oil and Gas Extraction"
    },
    {
      "code": "211114",
      "description": "Non-Conventional Oil Extraction"
    },
    {
      "code": "212114",
      "description": "Bituminous Coal Mining"
    },
    {
      "code": "212115",
      "description": "Subbituminous Coal Mining"
    },
    {
      "code": "212116",
      "description": "Lignite Coal Mining"
    },
    {
      "code": "212210",
      "description": "Iron Ore Mining"
    },
    {
      "code": "212220",
      "description": "Gold and Silver Ore Mining"
    },
    {
      "code": "212231",
      "description": "Lead-Zinc Ore Mining"
    },
    {
      "code": "212232",
      "description": "Nickel-Copper Ore Mining"
    },
    {
      "code": "212233",
      "description": "Copper-Zinc Ore Mining"
    },
    {
      "code": "212291",
      "description": "Uranium Ore Mining"
    },
    {
      "code": "212299",
      "description": "All Other Metal Ore Mining"
    },
    {
      "code": "212314",
      "description": "Granite Mining and Quarrying"
    },
    {
      "code": "212315",
      "description": "Limestone Mining and Quarrying"
    },
    {
      "code": "212316",
      "description": "Marble Mining and Quarrying"
    },
    {
      "code": "212317",
      "description": "Sandstone Mining and Quarrying"
    },
    {
      "code": "212323",
      "description": "Sand and Gravel Mining and Quarrying"
    },
    {
      "code": "212326",
      "description": "\"Shale, Clay and Refractory Mineral Mining and Quarrying\""
    },
    {
      "code": "212392",
      "description": "Diamond Mining"
    },
    {
      "code": "212393",
      "description": "Salt Mining"
    },
    {
      "code": "212394",
      "description": "Asbestos Mining"
    },
    {
      "code": "212395",
      "description": "Gypsum Mining"
    },
    {
      "code": "212396",
      "description": "Potash Mining"
    },
    {
      "code": "212397",
      "description": "Peat Extraction"
    },
    {
      "code": "212398",
      "description": "All Other Non-Metallic Mineral Mining and Quarrying"
    },
    {
      "code": "213111",
      "description": "Oil and Gas Contract Drilling"
    },
    {
      "code": "213117",
      "description": "Contract Drilling (except Oil and Gas)"
    },
    {
      "code": "213118",
      "description": "Services to Oil and Gas Extraction"
    },
    {
      "code": "213119",
      "description": "Other Support Activities for Mining"
    },
    {
      "code": "221111",
      "description": "Hydro-Electric Power Generation"
    },
    {
      "code": "221112",
      "description": "Fossil-Fuel Electric Power Generation"
    },
    {
      "code": "221113",
      "description": "Nuclear Electric Power Generation"
    },
    {
      "code": "221119",
      "description": "Other Electric Power Generation"
    },
    {
      "code": "221121",
      "description": "Electric Bulk Power Transmission and Control"
    },
    {
      "code": "221122",
      "description": "Electric Power Distribution"
    },
    {
      "code": "221210",
      "description": "Natural Gas Distribution"
    },
    {
      "code": "221310",
      "description": "Water Supply and Irrigation Systems"
    },
    {
      "code": "221320",
      "description": "Sewage Treatment Facilities"
    },
    {
      "code": "221330",
      "description": "Steam and Air-Conditioning Supply"
    },
    {
      "code": "236110",
      "description": "Residential Building Construction"
    },
    {
      "code": "236210",
      "description": "Industrial Building and Structure Construction"
    },
    {
      "code": "236220",
      "description": "Commercial and Institutional Building Construction"
    },
    {
      "code": "237110",
      "description": "Water and Sewer Line and Related Structures Construction"
    },
    {
      "code": "237120",
      "description": "Oil and Gas Pipeline and Related Structures Construction"
    },
    {
      "code": "237130",
      "description": "Power and Communication Line and Related Structures Construction"
    },
    {
      "code": "237210",
      "description": "Land Subdivision"
    },
    {
      "code": "237310",
      "description": "\"Highway, Street and Bridge Construction\""
    },
    {
      "code": "237990",
      "description": "Other Heavy and Civil Engineering Construction"
    },
    {
      "code": "238110",
      "description": "Poured Concrete Foundation and Structure Contractors"
    },
    {
      "code": "238120",
      "description": "Structural Steel and Precast Concrete Contractors"
    },
    {
      "code": "238130",
      "description": "Framing Contractors"
    },
    {
      "code": "238140",
      "description": "Masonry Contractors"
    },
    {
      "code": "238150",
      "description": "Glass and Glazing Contractors"
    },
    {
      "code": "238160",
      "description": "Roofing Contractors"
    },
    {
      "code": "238170",
      "description": "Siding Contractors"
    },
    {
      "code": "238190",
      "description": "\"Other Foundation, Structure and Building Exterior Contractors\""
    },
    {
      "code": "238210",
      "description": "Electrical Contractors and Other Wiring Installation Contractors"
    },
    {
      "code": "238220",
      "description": "\"Plumbing, Heating and Air-Conditioning Contractors\""
    },
    {
      "code": "238291",
      "description": "Elevator and Escalator Installation Contractors"
    },
    {
      "code": "238299",
      "description": "All Other Building Equipment Contractors"
    },
    {
      "code": "238310",
      "description": "Drywall and Insulation Contractors"
    },
    {
      "code": "238320",
      "description": "Painting and Wall Covering Contractors"
    },
    {
      "code": "238330",
      "description": "Flooring Contractors"
    },
    {
      "code": "238340",
      "description": "Tile and Terrazzo Contractors"
    },
    {
      "code": "238350",
      "description": "Finish Carpentry Contractors"
    },
    {
      "code": "238390",
      "description": "Other Building Finishing Contractors"
    },
    {
      "code": "238910",
      "description": "Site Preparation Contractors"
    },
    {
      "code": "238990",
      "description": "All Other Specialty Trade Contractors"
    },
    {
      "code": "311111",
      "description": "Dog and Cat Food Manufacturing"
    },
    {
      "code": "311119",
      "description": "Other Animal Food Manufacturing"
    },
    {
      "code": "311211",
      "description": "Flour Milling"
    },
    {
      "code": "311214",
      "description": "Rice Milling and Malt Manufacturing"
    },
    {
      "code": "311221",
      "description": "Wet Corn Milling"
    },
    {
      "code": "311224",
      "description": "Oilseed Processing"
    },
    {
      "code": "311225",
      "description": "Fat and Oil Refining and Blending"
    },
    {
      "code": "311230",
      "description": "Breakfast Cereal Manufacturing"
    },
    {
      "code": "311310",
      "description": "Sugar Manufacturing"
    },
    {
      "code": "311320",
      "description": "Chocolate and Confectionery Manufacturing from Cacao Beans"
    },
    {
      "code": "311330",
      "description": "Confectionery Manufacturing from Purchased Chocolate"
    },
    {
      "code": "311340",
      "description": "Non-Chocolate Confectionery Manufacturing"
    },
    {
      "code": "311410",
      "description": "Frozen Food Manufacturing"
    },
    {
      "code": "311420",
      "description": "\"Fruit and Vegetable Canning, Pickling and Drying\""
    },
    {
      "code": "311511",
      "description": "Fluid Milk Manufacturing"
    },
    {
      "code": "311515",
      "description": "\"Butter, Cheese, and Dry and Condensed Dairy Product Manufacturing\""
    },
    {
      "code": "311520",
      "description": "Ice Cream and Frozen Dessert Manufacturing"
    },
    {
      "code": "311611",
      "description": "Animal (except Poultry) Slaughtering"
    },
    {
      "code": "311614",
      "description": "Rendering and Meat Processing from Carcasses"
    },
    {
      "code": "311615",
      "description": "Poultry Processing"
    },
    {
      "code": "311710",
      "description": "Seafood Product Preparation and Packaging"
    },
    {
      "code": "311811",
      "description": "Retail Bakeries"
    },
    {
      "code": "311814",
      "description": "Commercial Bakeries and Frozen Bakery Product Manufacturing"
    },
    {
      "code": "311821",
      "description": "Cookie and Cracker Manufacturing"
    },
    {
      "code": "311822",
      "description": "Flour Mixes and Dough Manufacturing from Purchased Flour"
    },
    {
      "code": "311823",
      "description": "Dry Pasta Manufacturing"
    },
    {
      "code": "311830",
      "description": "Tortilla Manufacturing"
    },
    {
      "code": "311911",
      "description": "Roasted Nut and Peanut Butter Manufacturing"
    },
    {
      "code": "311919",
      "description": "Other Snack Food Manufacturing"
    },
    {
      "code": "311920",
      "description": "Coffee and Tea Manufacturing"
    },
    {
      "code": "311930",
      "description": "Flavouring Syrup and Concentrate Manufacturing"
    },
    {
      "code": "311940",
      "description": "Seasoning and Dressing Manufacturing"
    },
    {
      "code": "311990",
      "description": "All Other Food Manufacturing"
    },
    {
      "code": "312110",
      "description": "Soft Drink and Ice Manufacturing"
    },
    {
      "code": "312120",
      "description": "Breweries"
    },
    {
      "code": "312130",
      "description": "Wineries"
    },
    {
      "code": "312140",
      "description": "Distilleries"
    },
    {
      "code": "312210",
      "description": "Tobacco Stemming and Redrying"
    },
    {
      "code": "312220",
      "description": "Tobacco Product Manufacturing"
    },
    {
      "code": "313110",
      "description": "\"Fibre, Yarn and Thread Mills\""
    },
    {
      "code": "313210",
      "description": "Broad-Woven Fabric Mills"
    },
    {
      "code": "313220",
      "description": "Narrow Fabric Mills and Schiffli Machine Embroidery"
    },
    {
      "code": "313230",
      "description": "Nonwoven Fabric Mills"
    },
    {
      "code": "313240",
      "description": "Knit Fabric Mills"
    },
    {
      "code": "313310",
      "description": "Textile and Fabric Finishing"
    },
    {
      "code": "313320",
      "description": "Fabric Coating"
    },
    {
      "code": "314110",
      "description": "Carpet and Rug Mills"
    },
    {
      "code": "314120",
      "description": "Curtain and Linen Mills"
    },
    {
      "code": "314910",
      "description": "Textile Bag and Canvas Mills"
    },
    {
      "code": "314990",
      "description": "All Other Textile Product Mills"
    },
    {
      "code": "315110",
      "description": "Hosiery and Sock Mills"
    },
    {
      "code": "315190",
      "description": "Other Clothing Knitting Mills"
    },
    {
      "code": "315210",
      "description": "Cut and Sew Clothing Contracting"
    },
    {
      "code": "315221",
      "description": "Men's and Boys' Cut and Sew Underwear and Nightwear Manufacturing"
    },
    {
      "code": "315222",
      "description": "\"Men's and Boys' Cut and Sew Suit, Coat and Overcoat Manufacturing\""
    },
    {
      "code": "315226",
      "description": "Men's and Boys' Cut and Sew Shirt Manufacturing"
    },
    {
      "code": "315227",
      "description": "\"Men's and Boys' Cut and Sew Trouser, Slack and Jean Manufacturing\""
    },
    {
      "code": "315229",
      "description": "Other Men's and Boys' Cut and Sew Clothing Manufacturing"
    },
    {
      "code": "315231",
      "description": "\"Women's and Girls' Cut and Sew Lingerie, Loungewear and Nightwear Manufacturing\""
    },
    {
      "code": "315232",
      "description": "Women's and Girls' Cut and Sew Blouse and Shirt Manufacturing"
    },
    {
      "code": "315233",
      "description": "Women's and Girls' Cut and Sew Dress Manufacturing"
    },
    {
      "code": "315234",
      "description": "\"Women's and Girls' Cut and Sew Suit, Coat, Tailored Jacket and Skirt Manufacturing\""
    },
    {
      "code": "315239",
      "description": "Other Women's and Girls' Cut and Sew Clothing Manufacturing"
    },
    {
      "code": "315291",
      "description": "Infants' Cut and Sew Clothing Manufacturing"
    },
    {
      "code": "315292",
      "description": "Fur and Leather Clothing Manufacturing"
    },
    {
      "code": "315299",
      "description": "All Other Cut and Sew Clothing Manufacturing"
    },
    {
      "code": "315990",
      "description": "Clothing Accessories and Other Clothing Manufacturing"
    },
    {
      "code": "316110",
      "description": "Leather and Hide Tanning and Finishing"
    },
    {
      "code": "316210",
      "description": "Footwear Manufacturing"
    },
    {
      "code": "316990",
      "description": "Other Leather and Allied Product Manufacturing"
    },
    {
      "code": "321111",
      "description": "Sawmills (except Shingle and Shake Mills)"
    },
    {
      "code": "321112",
      "description": "Shingle and Shake Mills"
    },
    {
      "code": "321114",
      "description": "Wood Preservation"
    },
    {
      "code": "321211",
      "description": "Hardwood Veneer and Plywood Mills"
    },
    {
      "code": "321212",
      "description": "Softwood Veneer and Plywood Mills"
    },
    {
      "code": "321215",
      "description": "Structural Wood Product Manufacturing"
    },
    {
      "code": "321216",
      "description": "Particle Board and Fibreboard Mills"
    },
    {
      "code": "321217",
      "description": "Waferboard Mills"
    },
    {
      "code": "321911",
      "description": "Wood Window and Door Manufacturing"
    },
    {
      "code": "321919",
      "description": "Other Millwork"
    },
    {
      "code": "321920",
      "description": "Wood Container and Pallet Manufacturing"
    },
    {
      "code": "321991",
      "description": "Manufactured (Mobile) Home Manufacturing"
    },
    {
      "code": "321992",
      "description": "Prefabricated Wood Building Manufacturing"
    },
    {
      "code": "321999",
      "description": "All Other Miscellaneous Wood Product Manufacturing"
    },
    {
      "code": "322111",
      "description": "Mechanical Pulp Mills"
    },
    {
      "code": "322112",
      "description": "Chemical Pulp Mills"
    },
    {
      "code": "322121",
      "description": "Paper (except Newsprint) Mills"
    },
    {
      "code": "322122",
      "description": "Newsprint Mills"
    },
    {
      "code": "322130",
      "description": "Paperboard Mills"
    },
    {
      "code": "322211",
      "description": "Corrugated and Solid Fibre Box Manufacturing"
    },
    {
      "code": "322212",
      "description": "Folding Paperboard Box Manufacturing"
    },
    {
      "code": "322219",
      "description": "Other Paperboard Container Manufacturing"
    },
    {
      "code": "322220",
      "description": "Paper Bag and Coated and Treated Paper Manufacturing"
    },
    {
      "code": "322230",
      "description": "Stationery Product Manufacturing"
    },
    {
      "code": "322291",
      "description": "Sanitary Paper Product Manufacturing"
    },
    {
      "code": "322299",
      "description": "All Other Converted Paper Product Manufacturing"
    },
    {
      "code": "323113",
      "description": "Commercial Screen Printing"
    },
    {
      "code": "323114",
      "description": "Quick Printing"
    },
    {
      "code": "323115",
      "description": "Digital Printing"
    },
    {
      "code": "323116",
      "description": "Manifold Business Forms Printing"
    },
    {
      "code": "323119",
      "description": "Other Printing"
    },
    {
      "code": "323120",
      "description": "Support Activities for Printing"
    },
    {
      "code": "324110",
      "description": "Petroleum Refineries"
    },
    {
      "code": "324121",
      "description": "Asphalt Paving Mixture and Block Manufacturing"
    },
    {
      "code": "324122",
      "description": "Asphalt Shingle and Coating Material Manufacturing"
    },
    {
      "code": "324190",
      "description": "Other Petroleum and Coal Product Manufacturing"
    },
    {
      "code": "325110",
      "description": "Petrochemical Manufacturing"
    },
    {
      "code": "325120",
      "description": "Industrial Gas Manufacturing"
    },
    {
      "code": "325130",
      "description": "Synthetic Dye and Pigment Manufacturing"
    },
    {
      "code": "325181",
      "description": "Alkali and Chlorine Manufacturing"
    },
    {
      "code": "325189",
      "description": "All Other Basic Inorganic Chemical Manufacturing"
    },
    {
      "code": "325190",
      "description": "Other Basic Organic Chemical Manufacturing"
    },
    {
      "code": "325210",
      "description": "Resin and Synthetic Rubber Manufacturing"
    },
    {
      "code": "325220",
      "description": "Artificial and Synthetic Fibres and Filaments Manufacturing"
    },
    {
      "code": "325313",
      "description": "Chemical Fertilizer (except Potash) Manufacturing"
    },
    {
      "code": "325314",
      "description": "Mixed Fertilizer Manufacturing"
    },
    {
      "code": "325320",
      "description": "Pesticide and Other Agricultural Chemical Manufacturing"
    },
    {
      "code": "325410",
      "description": "Pharmaceutical and Medicine Manufacturing"
    },
    {
      "code": "325510",
      "description": "Paint and Coating Manufacturing"
    },
    {
      "code": "325520",
      "description": "Adhesive Manufacturing"
    },
    {
      "code": "325610",
      "description": "Soap and Cleaning Compound Manufacturing"
    },
    {
      "code": "325620",
      "description": "Toilet Preparation Manufacturing"
    },
    {
      "code": "325910",
      "description": "Printing Ink Manufacturing"
    },
    {
      "code": "325920",
      "description": "Explosives Manufacturing"
    },
    {
      "code": "325991",
      "description": "Custom Compounding of Purchased Resins"
    },
    {
      "code": "325999",
      "description": "All Other Miscellaneous Chemical Product Manufacturing"
    },
    {
      "code": "326111",
      "description": "Plastic Bag and Pouch Manufacturing"
    },
    {
      "code": "326114",
      "description": "Plastic Film and Sheet Manufacturing"
    },
    {
      "code": "326121",
      "description": "Unlaminated Plastic Profile Shape Manufacturing"
    },
    {
      "code": "326122",
      "description": "Plastic Pipe and Pipe Fitting Manufacturing"
    },
    {
      "code": "326130",
      "description": "\"Laminated Plastic Plate, Sheet (except Packaging), and Shape Manufacturing\""
    },
    {
      "code": "326140",
      "description": "Polystyrene Foam Product Manufacturing"
    },
    {
      "code": "326150",
      "description": "Urethane and Other Foam Product (except Polystyrene) Manufacturing"
    },
    {
      "code": "326160",
      "description": "Plastic Bottle Manufacturing"
    },
    {
      "code": "326191",
      "description": "Plastic Plumbing Fixture Manufacturing"
    },
    {
      "code": "326193",
      "description": "Motor Vehicle Plastic Parts Manufacturing"
    },
    {
      "code": "326196",
      "description": "Plastic Window and Door Manufacturing"
    },
    {
      "code": "326198",
      "description": "All Other Plastic Product Manufacturing"
    },
    {
      "code": "326210",
      "description": "Tire Manufacturing"
    },
    {
      "code": "326220",
      "description": "Rubber and Plastic Hose and Belting Manufacturing"
    },
    {
      "code": "326290",
      "description": "Other Rubber Product Manufacturing"
    },
    {
      "code": "327110",
      "description": "\"Pottery, Ceramics and Plumbing Fixture Manufacturing\""
    },
    {
      "code": "327120",
      "description": "Clay Building Material and Refractory Manufacturing"
    },
    {
      "code": "327214",
      "description": "Glass Manufacturing"
    },
    {
      "code": "327215",
      "description": "Glass Product Manufacturing from Purchased Glass"
    },
    {
      "code": "327310",
      "description": "Cement Manufacturing"
    },
    {
      "code": "327320",
      "description": "Ready-Mix Concrete Manufacturing"
    },
    {
      "code": "327330",
      "description": "\"Concrete Pipe, Brick and Block Manufacturing\""
    },
    {
      "code": "327390",
      "description": "Other Concrete Product Manufacturing"
    },
    {
      "code": "327410",
      "description": "Lime Manufacturing"
    },
    {
      "code": "327420",
      "description": "Gypsum Product Manufacturing"
    },
    {
      "code": "327910",
      "description": "Abrasive Product Manufacturing"
    },
    {
      "code": "327990",
      "description": "All Other Non-Metallic Mineral Product Manufacturing"
    },
    {
      "code": "331110",
      "description": "Iron and Steel Mills and Ferro-Alloy Manufacturing"
    },
    {
      "code": "331210",
      "description": "Iron and Steel Pipes and Tubes Manufacturing from Purchased Steel"
    },
    {
      "code": "331221",
      "description": "Cold-Rolled Steel Shape Manufacturing"
    },
    {
      "code": "331222",
      "description": "Steel Wire Drawing"
    },
    {
      "code": "331313",
      "description": "Primary Production of Alumina and Aluminum"
    },
    {
      "code": "331317",
      "description": "\"Aluminum Rolling, Drawing, Extruding and Alloying\""
    },
    {
      "code": "331410",
      "description": "Non-Ferrous Metal (except Aluminum) Smelting and Refining"
    },
    {
      "code": "331420",
      "description": "\"Copper Rolling, Drawing, Extruding and Alloying\""
    },
    {
      "code": "331490",
      "description": "\"Non-Ferrous Metal (except Copper and Aluminum) Rolling, Drawing, Extruding and Alloying\""
    },
    {
      "code": "331511",
      "description": "Iron Foundries"
    },
    {
      "code": "331514",
      "description": "Steel Foundries"
    },
    {
      "code": "331523",
      "description": "Non-Ferrous Die-Casting Foundries"
    },
    {
      "code": "331529",
      "description": "Non-Ferrous Foundries (except Die-Casting)"
    },
    {
      "code": "332113",
      "description": "Forging"
    },
    {
      "code": "332118",
      "description": "Stamping"
    },
    {
      "code": "332210",
      "description": "Cutlery and Hand Tool Manufacturing"
    },
    {
      "code": "332311",
      "description": "Prefabricated Metal Building and Component Manufacturing"
    },
    {
      "code": "332314",
      "description": "Concrete Reinforcing Bar Manufacturing"
    },
    {
      "code": "332319",
      "description": "Other Plate Work and Fabricated Structural Product Manufacturing"
    },
    {
      "code": "332321",
      "description": "Metal Window and Door Manufacturing"
    },
    {
      "code": "332329",
      "description": "Other Ornamental and Architectural Metal Product Manufacturing"
    },
    {
      "code": "332410",
      "description": "Power Boiler and Heat Exchanger Manufacturing"
    },
    {
      "code": "332420",
      "description": "Metal Tank (Heavy Gauge) Manufacturing"
    },
    {
      "code": "332431",
      "description": "Metal Can Manufacturing"
    },
    {
      "code": "332439",
      "description": "Other Metal Container Manufacturing"
    },
    {
      "code": "332510",
      "description": "Hardware Manufacturing"
    },
    {
      "code": "332611",
      "description": "Spring (Heavy Gauge) Manufacturing"
    },
    {
      "code": "332619",
      "description": "Other Fabricated Wire Product Manufacturing"
    },
    {
      "code": "332710",
      "description": "Machine Shops"
    },
    {
      "code": "332720",
      "description": "\"Turned Product and Screw, Nut and Bolt Manufacturing\""
    },
    {
      "code": "332810",
      "description": "\"Coating, Engraving, Heat Treating and Allied Activities\""
    },
    {
      "code": "332910",
      "description": "Metal Valve Manufacturing"
    },
    {
      "code": "332991",
      "description": "Ball and Roller Bearing Manufacturing"
    },
    {
      "code": "332999",
      "description": "All Other Miscellaneous Fabricated Metal Product Manufacturing"
    },
    {
      "code": "333110",
      "description": "Agricultural Implement Manufacturing"
    },
    {
      "code": "333120",
      "description": "Construction Machinery Manufacturing"
    },
    {
      "code": "333130",
      "description": "Mining and Oil and Gas Field Machinery Manufacturing"
    },
    {
      "code": "333210",
      "description": "Sawmill and Woodworking Machinery Manufacturing"
    },
    {
      "code": "333220",
      "description": "Rubber and Plastics Industry Machinery Manufacturing"
    },
    {
      "code": "333291",
      "description": "Paper Industry Machinery Manufacturing"
    },
    {
      "code": "333299",
      "description": "All Other Industrial Machinery Manufacturing"
    },
    {
      "code": "333310",
      "description": "Commercial and Service Industry Machinery Manufacturing"
    },
    {
      "code": "333413",
      "description": "Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing"
    },
    {
      "code": "333416",
      "description": "Heating Equipment and Commercial Refrigeration Equipment Manufacturing"
    },
    {
      "code": "333511",
      "description": "Industrial Mould Manufacturing"
    },
    {
      "code": "333519",
      "description": "Other Metalworking Machinery Manufacturing"
    },
    {
      "code": "333611",
      "description": "Turbine and Turbine Generator Set Unit Manufacturing"
    },
    {
      "code": "333619",
      "description": "Other Engine and Power Transmission Equipment Manufacturing"
    },
    {
      "code": "333910",
      "description": "Pump and Compressor Manufacturing"
    },
    {
      "code": "333920",
      "description": "Material Handling Equipment Manufacturing"
    },
    {
      "code": "333990",
      "description": "All Other General-Purpose Machinery Manufacturing"
    },
    {
      "code": "334110",
      "description": "Computer and Peripheral Equipment Manufacturing"
    },
    {
      "code": "334210",
      "description": "Telephone Apparatus Manufacturing"
    },
    {
      "code": "334220",
      "description": "Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing"
    },
    {
      "code": "334290",
      "description": "Other Communications Equipment Manufacturing"
    },
    {
      "code": "334310",
      "description": "Audio and Video Equipment Manufacturing"
    },
    {
      "code": "334410",
      "description": "Semiconductor and Other Electronic Component Manufacturing"
    },
    {
      "code": "334511",
      "description": "Navigational and Guidance Instruments Manufacturing"
    },
    {
      "code": "334512",
      "description": "\"Measuring, Medical and Controlling Devices Manufacturing\""
    },
    {
      "code": "334610",
      "description": "Manufacturing and Reproducing Magnetic and Optical Media"
    },
    {
      "code": "335110",
      "description": "Electric Lamp Bulb and Parts Manufacturing"
    },
    {
      "code": "335120",
      "description": "Lighting Fixture Manufacturing"
    },
    {
      "code": "335210",
      "description": "Small Electrical Appliance Manufacturing"
    },
    {
      "code": "335223",
      "description": "Major Kitchen Appliance Manufacturing"
    },
    {
      "code": "335229",
      "description": "Other Major Appliance Manufacturing"
    },
    {
      "code": "335311",
      "description": "\"Power, Distribution and Specialty Transformers Manufacturing\""
    },
    {
      "code": "335312",
      "description": "Motor and Generator Manufacturing"
    },
    {
      "code": "335315",
      "description": "\"Switchgear and Switchboard, and Relay and Industrial Control Apparatus Manufacturing\""
    },
    {
      "code": "335910",
      "description": "Battery Manufacturing"
    },
    {
      "code": "335920",
      "description": "Communication and Energy Wire and Cable Manufacturing"
    },
    {
      "code": "335930",
      "description": "Wiring Device Manufacturing"
    },
    {
      "code": "335990",
      "description": "All Other Electrical Equipment and Component Manufacturing"
    },
    {
      "code": "336110",
      "description": "Automobile and Light-Duty Motor Vehicle Manufacturing"
    },
    {
      "code": "336120",
      "description": "Heavy-Duty Truck Manufacturing"
    },
    {
      "code": "336211",
      "description": "Motor Vehicle Body Manufacturing"
    },
    {
      "code": "336212",
      "description": "Truck Trailer Manufacturing"
    },
    {
      "code": "336215",
      "description": "\"Motor Home, Travel Trailer and Camper Manufacturing\""
    },
    {
      "code": "336310",
      "description": "Motor Vehicle Gasoline Engine and Engine Parts Manufacturing"
    },
    {
      "code": "336320",
      "description": "Motor Vehicle Electrical and Electronic Equipment Manufacturing"
    },
    {
      "code": "336330",
      "description": "Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing"
    },
    {
      "code": "336340",
      "description": "Motor Vehicle Brake System Manufacturing"
    },
    {
      "code": "336350",
      "description": "Motor Vehicle Transmission and Power Train Parts Manufacturing"
    },
    {
      "code": "336360",
      "description": "Motor Vehicle Seating and Interior Trim Manufacturing"
    },
    {
      "code": "336370",
      "description": "Motor Vehicle Metal Stamping"
    },
    {
      "code": "336390",
      "description": "Other Motor Vehicle Parts Manufacturing"
    },
    {
      "code": "336410",
      "description": "Aerospace Product and Parts Manufacturing"
    },
    {
      "code": "336510",
      "description": "Railroad Rolling Stock Manufacturing"
    },
    {
      "code": "336611",
      "description": "Ship Building and Repairing"
    },
    {
      "code": "336612",
      "description": "Boat Building"
    },
    {
      "code": "336990",
      "description": "Other Transportation Equipment Manufacturing"
    },
    {
      "code": "337110",
      "description": "Wood Kitchen Cabinet and Counter Top Manufacturing"
    },
    {
      "code": "337121",
      "description": "Upholstered Household Furniture Manufacturing"
    },
    {
      "code": "337123",
      "description": "Other Wood Household Furniture Manufacturing"
    },
    {
      "code": "337126",
      "description": "Household Furniture (except Wood and Upholstered) Manufacturing"
    },
    {
      "code": "337127",
      "description": "Institutional Furniture Manufacturing"
    },
    {
      "code": "337213",
      "description": "\"Wood Office Furniture, including Custom Architectural Woodwork, Manufacturing\""
    },
    {
      "code": "337214",
      "description": "Office Furniture (except Wood) Manufacturing"
    },
    {
      "code": "337215",
      "description": "\"Showcase, Partition, Shelving and Locker Manufacturing\""
    },
    {
      "code": "337910",
      "description": "Mattress Manufacturing"
    },
    {
      "code": "337920",
      "description": "Blind and Shade Manufacturing"
    },
    {
      "code": "339110",
      "description": "Medical Equipment and Supplies Manufacturing"
    },
    {
      "code": "411110",
      "description": "Live Animal Wholesaler-Distributors"
    },
    {
      "code": "411120",
      "description": "Oilseed and Grain Wholesaler-Distributors"
    },
    {
      "code": "411130",
      "description": "Nursery Stock and Plant Wholesaler-Distributors"
    },
    {
      "code": "411190",
      "description": "Other Farm Product Wholesaler-Distributors"
    },
    {
      "code": "412110",
      "description": "Petroleum Product Wholesaler-Distributors"
    },
    {
      "code": "413110",
      "description": "General-Line Food Wholesaler-Distributors"
    },
    {
      "code": "413120",
      "description": "Dairy and Milk Products Wholesaler-Distributors"
    },
    {
      "code": "413130",
      "description": "Poultry and Egg Wholesaler-Distributors"
    },
    {
      "code": "413140",
      "description": "Fish and Seafood Product Wholesaler-Distributors"
    },
    {
      "code": "413150",
      "description": "Fresh Fruit and Vegetable Wholesaler-Distributors"
    },
    {
      "code": "413160",
      "description": "Red Meat and Meat Product Wholesaler-Distributors"
    },
    {
      "code": "413190",
      "description": "Other Specialty-Line Food Wholesaler-Distributors"
    },
    {
      "code": "413210",
      "description": "Non-Alcoholic Beverage Wholesaler-Distributors"
    },
    {
      "code": "413220",
      "description": "Alcoholic Beverage Wholesaler-Distributors"
    },
    {
      "code": "413310",
      "description": "Cigarette and Tobacco Product Wholesaler-Distributors"
    },
    {
      "code": "414110",
      "description": "Clothing and Clothing Accessories Wholesaler-Distributors"
    },
    {
      "code": "414120",
      "description": "Footwear Wholesaler-Distributors"
    },
    {
      "code": "414130",
      "description": "\"Piece Goods, Notions and Other Dry Goods Wholesaler-Distributors\""
    },
    {
      "code": "414210",
      "description": "Home Entertainment Equipment Wholesaler-Distributors"
    },
    {
      "code": "414220",
      "description": "Household Appliance Wholesaler-Distributors"
    },
    {
      "code": "414310",
      "description": "\"China, Glassware, Crockery and Pottery Wholesaler-Distributors\""
    },
    {
      "code": "414320",
      "description": "Floor Covering Wholesaler-Distributors"
    },
    {
      "code": "414330",
      "description": "\"Linen, Drapery and Other Textile Furnishings Wholesaler-Distributors\""
    },
    {
      "code": "414390",
      "description": "Other Home Furnishings Wholesaler-Distributors"
    },
    {
      "code": "414410",
      "description": "Jewellery and Watch Wholesaler-Distributors"
    },
    {
      "code": "414420",
      "description": "\"Book, Periodical and Newspaper Wholesaler-Distributors\""
    },
    {
      "code": "414430",
      "description": "Photographic Equipment and Supplies Wholesaler-Distributors"
    },
    {
      "code": "414440",
      "description": "Sound Recording Wholesalers"
    },
    {
      "code": "414450",
      "description": "Video Cassette Wholesalers"
    },
    {
      "code": "414460",
      "description": "Toy and Hobby Goods Wholesaler-Distributors"
    },
    {
      "code": "414470",
      "description": "Amusement and Sporting Goods Wholesaler-Distributors"
    },
    {
      "code": "414510",
      "description": "Pharmaceuticals and Pharmacy Supplies Wholesaler-Distributors"
    },
    {
      "code": "414520",
      "description": "\"Toiletries, Cosmetics and Sundries Wholesaler-Distributors\""
    },
    {
      "code": "415110",
      "description": "New and Used Automobile and Light-Duty Truck Wholesaler-Distributors"
    },
    {
      "code": "415120",
      "description": "\"Truck, Truck Tractor and Bus Wholesaler-Distributors\""
    },
    {
      "code": "415190",
      "description": "Recreational and Other Motor Vehicles Wholesaler-Distributors"
    },
    {
      "code": "415210",
      "description": "Tire Wholesaler-Distributors"
    },
    {
      "code": "415290",
      "description": "Other New Motor Vehicle Parts and Accessories Wholesaler-Distributors"
    },
    {
      "code": "415310",
      "description": "Used Motor Vehicle Parts and Accessories Wholesaler-Distributors"
    },
    {
      "code": "416110",
      "description": "Electrical Wiring and Construction Supplies Wholesaler-Distributors"
    },
    {
      "code": "416120",
      "description": "\"Plumbing, Heating and Air-Conditioning Equipment and Supplies Wholesaler-Distributors\""
    },
    {
      "code": "416210",
      "description": "Metal Service Centres"
    },
    {
      "code": "416310",
      "description": "General-Line Building Supplies Wholesaler-Distributors"
    },
    {
      "code": "416320",
      "description": "\"Lumber, Plywood and Millwork Wholesaler-Distributors\""
    },
    {
      "code": "416330",
      "description": "Hardware Wholesaler-Distributors"
    },
    {
      "code": "416340",
      "description": "\"Paint, Glass and Wallpaper Wholesaler-Distributors\""
    },
    {
      "code": "416390",
      "description": "Other Specialty-Line Building Supplies Wholesaler-Distributors"
    },
    {
      "code": "417110",
      "description": "\"Farm, Lawn and Garden Machinery and Equipment Wholesaler-Distributors\""
    },
    {
      "code": "417210",
      "description": "\"Construction and Forestry Machinery, Equipment and Supplies Wholesaler-Distributors\""
    },
    {
      "code": "417220",
      "description": "\"Mining and Oil and Gas Well Machinery, Equipment and Supplies Wholesaler-Distributors\""
    },
    {
      "code": "417230",
      "description": "\"Industrial Machinery, Equipment and Supplies Wholesaler-Distributors\""
    },
    {
      "code": "417310",
      "description": "\"Computer, Computer Peripheral and Pre-Packaged Software Wholesaler-Distributors\""
    },
    {
      "code": "417320",
      "description": "\"Electronic Components, Navigational and Communications Equipment and Supplies Wholesaler-Distributors\""
    },
    {
      "code": "417910",
      "description": "Office and Store Machinery and Equipment Wholesaler-Distributors"
    },
    {
      "code": "417920",
      "description": "\"Service Establishment Machinery, Equipment and Supplies Wholesaler-Distributors\""
    },
    {
      "code": "417930",
      "description": "\"Professional Machinery, Equipment and Supplies Wholesaler-Distributors\""
    },
    {
      "code": "417990",
      "description": "\"All Other Machinery, Equipment and Supplies Wholesaler-Distributors\""
    },
    {
      "code": "418110",
      "description": "Recyclable Metal Wholesaler-Distributors"
    },
    {
      "code": "418120",
      "description": "Recyclable Paper and Paperboard Wholesaler-Distributors"
    },
    {
      "code": "418190",
      "description": "Other Recyclable Material Wholesaler-Distributors"
    },
    {
      "code": "418210",
      "description": "Stationery and Office Supplies Wholesaler-Distributors"
    },
    {
      "code": "418220",
      "description": "Other Paper and Disposable Plastic Product Wholesaler-Distributors"
    },
    {
      "code": "418310",
      "description": "Agricultural Feed Wholesaler-Distributors"
    },
    {
      "code": "418320",
      "description": "Seed Wholesaler-Distributors"
    },
    {
      "code": "418390",
      "description": "Agricultural Chemical and Other Farm Supplies Wholesaler-Distributors"
    },
    {
      "code": "418410",
      "description": "Chemical (except Agricultural) and Allied Product Wholesaler-Distributors"
    },
    {
      "code": "418910",
      "description": "Log and Wood Chip Wholesaler-Distributors"
    },
    {
      "code": "418920",
      "description": "\"Mineral, Ore and Precious Metal Wholesaler-Distributors\""
    },
    {
      "code": "418930",
      "description": "Second-Hand Goods (except Machinery and Automotive) Wholesaler-Distributors"
    },
    {
      "code": "418990",
      "description": "All Other Wholesaler-Distributors"
    },
    {
      "code": "419110",
      "description": "Business-to-Business Electronic Markets"
    },
    {
      "code": "419120",
      "description": "Wholesale Trade Agents and Brokers"
    },
    {
      "code": "441110",
      "description": "New Car Dealers"
    },
    {
      "code": "441120",
      "description": "Used Car Dealers"
    },
    {
      "code": "441210",
      "description": "Recreational Vehicle Dealers"
    },
    {
      "code": "441220",
      "description": "\"Motorcycle, Boat and Other Motor Vehicle Dealers\""
    },
    {
      "code": "441310",
      "description": "Automotive Parts and Accessories Stores"
    },
    {
      "code": "441320",
      "description": "Tire Dealers"
    },
    {
      "code": "442110",
      "description": "Furniture Stores"
    },
    {
      "code": "442210",
      "description": "Floor Covering Stores"
    },
    {
      "code": "442291",
      "description": "Window Treatment Stores"
    },
    {
      "code": "442292",
      "description": "Print and Picture Frame Stores"
    },
    {
      "code": "442298",
      "description": "All Other Home Furnishings Stores"
    },
    {
      "code": "443110",
      "description": "\"Appliance, Television and Other Electronics Stores\""
    },
    {
      "code": "443120",
      "description": "Computer and Software Stores"
    },
    {
      "code": "443130",
      "description": "Camera and Photographic Supplies Stores"
    },
    {
      "code": "444110",
      "description": "Home Centres"
    },
    {
      "code": "444120",
      "description": "Paint and Wallpaper Stores"
    },
    {
      "code": "444130",
      "description": "Hardware Stores"
    },
    {
      "code": "444190",
      "description": "Other Building Material Dealers"
    },
    {
      "code": "444210",
      "description": "Outdoor Power Equipment Stores"
    },
    {
      "code": "444220",
      "description": "Nursery Stores and Garden Centres"
    },
    {
      "code": "445110",
      "description": "Supermarkets and Other Grocery (except Convenience) Stores"
    },
    {
      "code": "445120",
      "description": "Convenience Stores"
    },
    {
      "code": "445210",
      "description": "Meat Markets"
    },
    {
      "code": "445220",
      "description": "Fish and Seafood Markets"
    },
    {
      "code": "445230",
      "description": "Fruit and Vegetable Markets"
    },
    {
      "code": "445291",
      "description": "Baked Goods Stores"
    },
    {
      "code": "445292",
      "description": "Confectionery and Nut Stores"
    },
    {
      "code": "445299",
      "description": "All Other Specialty Food Stores"
    },
    {
      "code": "445310",
      "description": "\"Beer, Wine and Liquor Stores\""
    },
    {
      "code": "446110",
      "description": "Pharmacies and Drug Stores"
    },
    {
      "code": "446120",
      "description": "\"Cosmetics, Beauty Supplies and Perfume Stores\""
    },
    {
      "code": "446130",
      "description": "Optical Goods Stores"
    },
    {
      "code": "446191",
      "description": "Food (Health) Supplement Stores"
    },
    {
      "code": "446199",
      "description": "All Other Health and Personal Care Stores"
    },
    {
      "code": "447110",
      "description": "Gasoline Stations with Convenience Stores"
    },
    {
      "code": "447190",
      "description": "Other Gasoline Stations"
    },
    {
      "code": "448110",
      "description": "Men's Clothing Stores"
    },
    {
      "code": "448120",
      "description": "Women's Clothing Stores"
    },
    {
      "code": "448130",
      "description": "Children's and Infants' Clothing Stores"
    },
    {
      "code": "448140",
      "description": "Family Clothing Stores"
    },
    {
      "code": "448150",
      "description": "Clothing Accessories Stores"
    },
    {
      "code": "448191",
      "description": "Fur Stores"
    },
    {
      "code": "448199",
      "description": "All Other Clothing Stores"
    },
    {
      "code": "448210",
      "description": "Shoe Stores"
    },
    {
      "code": "448310",
      "description": "Jewellery Stores"
    },
    {
      "code": "448320",
      "description": "Luggage and Leather Goods Stores"
    },
    {
      "code": "451110",
      "description": "Sporting Goods Stores"
    },
    {
      "code": "451120",
      "description": "\"Hobby, Toy and Game Stores\""
    },
    {
      "code": "451130",
      "description": "\"Sewing, Needlework and Piece Goods Stores\""
    },
    {
      "code": "451140",
      "description": "Musical Instrument and Supplies Stores"
    },
    {
      "code": "451210",
      "description": "Book Stores and News Dealers"
    },
    {
      "code": "451220",
      "description": "\"Pre-Recorded Tape, Compact Disc and Record Stores\""
    },
    {
      "code": "452110",
      "description": "Department Stores"
    },
    {
      "code": "452910",
      "description": "Warehouse Clubs and Superstores"
    },
    {
      "code": "452991",
      "description": "Home and Auto Supplies Stores"
    },
    {
      "code": "452999",
      "description": "All Other Miscellaneous General Merchandise Stores"
    },
    {
      "code": "453110",
      "description": "Florists"
    },
    {
      "code": "453210",
      "description": "Office Supplies and Stationery Stores"
    },
    {
      "code": "453220",
      "description": "\"Gift, Novelty and Souvenir Stores\""
    },
    {
      "code": "453310",
      "description": "Used Merchandise Stores"
    },
    {
      "code": "453910",
      "description": "Pet and Pet Supplies Stores"
    },
    {
      "code": "453920",
      "description": "Art Dealers"
    },
    {
      "code": "453930",
      "description": "Mobile Home Dealers"
    },
    {
      "code": "453992",
      "description": "Beer and Wine-Making Supplies Stores"
    },
    {
      "code": "453999",
      "description": "All Other Miscellaneous Store Retailers (except Beer and Wine-Making Supplies Stores)"
    },
    {
      "code": "454111",
      "description": "Internet Shopping"
    },
    {
      "code": "454112",
      "description": "Electronic Auctions"
    },
    {
      "code": "454113",
      "description": "Mail-Order Houses"
    },
    {
      "code": "454210",
      "description": "Vending Machine Operators"
    },
    {
      "code": "454311",
      "description": "Heating Oil Dealers"
    },
    {
      "code": "454312",
      "description": "Liquefied Petroleum Gas (Bottled Gas) Dealers"
    },
    {
      "code": "454319",
      "description": "Other Fuel Dealers"
    },
    {
      "code": "454390",
      "description": "Other Direct Selling Establishments"
    },
    {
      "code": "481110",
      "description": "Scheduled Air Transportation"
    },
    {
      "code": "481214",
      "description": "Non-Scheduled Chartered Air Transportation"
    },
    {
      "code": "481215",
      "description": "Non-Scheduled Specialty Flying Services"
    },
    {
      "code": "482112",
      "description": "Short-Haul Freight Rail Transportation"
    },
    {
      "code": "482113",
      "description": "Mainline Freight Rail Transportation"
    },
    {
      "code": "482114",
      "description": "Passenger Rail Transportation"
    },
    {
      "code": "483115",
      "description": "\"Deep Sea, Coastal and Great Lakes Water Transportation (except by Ferries)\""
    },
    {
      "code": "483116",
      "description": "\"Deep Sea, Coastal and Great Lakes Water Transportation by Ferries\""
    },
    {
      "code": "483213",
      "description": "Inland Water Transportation (except by Ferries)"
    },
    {
      "code": "483214",
      "description": "Inland Water Transportation by Ferries"
    },
    {
      "code": "484110",
      "description": "\"General Freight Trucking, Local\""
    },
    {
      "code": "484121",
      "description": "\"General Freight Trucking, Long Distance, Truck-Load\""
    },
    {
      "code": "484122",
      "description": "\"General Freight Trucking, Long Distance, Less Than Truck-Load\""
    },
    {
      "code": "484210",
      "description": "Used Household and Office Goods Moving"
    },
    {
      "code": "484221",
      "description": "\"Bulk Liquids Trucking, Local\""
    },
    {
      "code": "484222",
      "description": "\"Dry Bulk Materials Trucking, Local\""
    },
    {
      "code": "484223",
      "description": "\"Forest Products Trucking, Local\""
    },
    {
      "code": "484229",
      "description": "\"Other Specialized Freight (except Used Goods) Trucking, Local\""
    },
    {
      "code": "484231",
      "description": "\"Bulk Liquids Trucking, Long Distance\""
    },
    {
      "code": "484232",
      "description": "\"Dry Bulk Materials Trucking, Long Distance\""
    },
    {
      "code": "484233",
      "description": "\"Forest Products Trucking, Long Distance\""
    },
    {
      "code": "484239",
      "description": "\"Other Specialized Freight (except Used Goods) Trucking, Long Distance\""
    },
    {
      "code": "485110",
      "description": "Urban Transit Systems"
    },
    {
      "code": "485210",
      "description": "Interurban and Rural Bus Transportation"
    },
    {
      "code": "485310",
      "description": "Taxi Service"
    },
    {
      "code": "485320",
      "description": "Limousine Service"
    },
    {
      "code": "485410",
      "description": "School and Employee Bus Transportation"
    },
    {
      "code": "485510",
      "description": "Charter Bus Industry"
    },
    {
      "code": "485990",
      "description": "Other Transit and Ground Passenger Transportation"
    },
    {
      "code": "486110",
      "description": "Pipeline Transportation of Crude Oil"
    },
    {
      "code": "486210",
      "description": "Pipeline Transportation of Natural Gas"
    },
    {
      "code": "486910",
      "description": "Pipeline Transportation of Refined Petroleum Products"
    },
    {
      "code": "486990",
      "description": "All Other Pipeline Transportation"
    },
    {
      "code": "487110",
      "description": "\"Scenic and Sightseeing Transportation, Land\""
    },
    {
      "code": "487210",
      "description": "\"Scenic and Sightseeing Transportation, Water\""
    },
    {
      "code": "487990",
      "description": "\"Scenic and Sightseeing Transportation, Other\""
    },
    {
      "code": "488111",
      "description": "Air Traffic Control"
    },
    {
      "code": "488119",
      "description": "Other Airport Operations"
    },
    {
      "code": "488190",
      "description": "Other Support Activities for Air Transportation"
    },
    {
      "code": "488210",
      "description": "Support Activities for Rail Transportation"
    },
    {
      "code": "488310",
      "description": "Port and Harbour Operations"
    },
    {
      "code": "488320",
      "description": "Marine Cargo Handling"
    },
    {
      "code": "488331",
      "description": "Marine Salvage Services"
    },
    {
      "code": "488332",
      "description": "Ship Piloting Services"
    },
    {
      "code": "488339",
      "description": "Other Navigational Services to Shipping"
    },
    {
      "code": "488390",
      "description": "Other Support Activities for Water Transportation"
    },
    {
      "code": "488410",
      "description": "Motor Vehicle Towing"
    },
    {
      "code": "488490",
      "description": "Other Support Activities for Road Transportation"
    },
    {
      "code": "488511",
      "description": "Marine Shipping Agencies"
    },
    {
      "code": "488519",
      "description": "Other Freight Transportation Arrangement"
    },
    {
      "code": "488990",
      "description": "Other Support Activities for Transportation"
    },
    {
      "code": "491110",
      "description": "Postal Service"
    },
    {
      "code": "492110",
      "description": "Couriers"
    },
    {
      "code": "492210",
      "description": "Local Messengers and Local Delivery"
    },
    {
      "code": "493110",
      "description": "General Warehousing and Storage"
    },
    {
      "code": "493120",
      "description": "Refrigerated Warehousing and Storage"
    },
    {
      "code": "493130",
      "description": "Farm Product Warehousing and Storage"
    },
    {
      "code": "493190",
      "description": "Other Warehousing and Storage"
    },
    {
      "code": "511110",
      "description": "Newspaper Publishers"
    },
    {
      "code": "511120",
      "description": "Periodical Publishers"
    },
    {
      "code": "511130",
      "description": "Book Publishers"
    },
    {
      "code": "511140",
      "description": "Directory and Mailing List Publishers"
    },
    {
      "code": "511190",
      "description": "Other Publishers"
    },
    {
      "code": "511210",
      "description": "Software Publishers"
    },
    {
      "code": "512110",
      "description": "Motion Picture and Video Production"
    },
    {
      "code": "512120",
      "description": "Motion Picture and Video Distribution"
    },
    {
      "code": "512130",
      "description": "Motion Picture and Video Exhibition"
    },
    {
      "code": "512190",
      "description": "Post-Production and Other Motion Picture and Video Industries"
    },
    {
      "code": "512210",
      "description": "Record Production"
    },
    {
      "code": "512220",
      "description": "Integrated Record Production/Distribution"
    },
    {
      "code": "512230",
      "description": "Music Publishers"
    },
    {
      "code": "512240",
      "description": "Sound Recording Studios"
    },
    {
      "code": "512290",
      "description": "Other Sound Recording Industries"
    },
    {
      "code": "515110",
      "description": "Radio Broadcasting"
    },
    {
      "code": "515120",
      "description": "Television Broadcasting"
    },
    {
      "code": "515210",
      "description": "Pay and Specialty Television"
    },
    {
      "code": "517111",
      "description": "Wired Telecommunications Carriers (except Cable)"
    },
    {
      "code": "517112",
      "description": "Cable and Other Program Distribution"
    },
    {
      "code": "517210",
      "description": "Wireless Telecommunications Carriers (except Satellite)"
    },
    {
      "code": "517410",
      "description": "Satellite Telecommunications"
    },
    {
      "code": "517910",
      "description": "Other Telecommunications"
    },
    {
      "code": "518210",
      "description": "\"Data Processing, Hosting, and Related Services\""
    },
    {
      "code": "519110",
      "description": "News Syndicates"
    },
    {
      "code": "519121",
      "description": "Libraries"
    },
    {
      "code": "519122",
      "description": "Archives"
    },
    {
      "code": "519130",
      "description": "\"Internet Publishing and Broadcasting, and Web Search Portals\""
    },
    {
      "code": "519190",
      "description": "All Other Information Services"
    },
    {
      "code": "521110",
      "description": "Monetary Authorities - Central Bank"
    },
    {
      "code": "522111",
      "description": "Personal and Commercial Banking Industry"
    },
    {
      "code": "522112",
      "description": "Corporate and Institutional Banking Industry"
    },
    {
      "code": "522130",
      "description": "Local Credit Unions"
    },
    {
      "code": "522190",
      "description": "Other Depository Credit Intermediation"
    },
    {
      "code": "522210",
      "description": "Credit Card Issuing"
    },
    {
      "code": "522220",
      "description": "Sales Financing"
    },
    {
      "code": "522291",
      "description": "Consumer Lending"
    },
    {
      "code": "522299",
      "description": "All Other Non-Depository Credit Intermediation"
    },
    {
      "code": "522310",
      "description": "Mortgage and Non-mortgage Loan Brokers"
    },
    {
      "code": "522321",
      "description": "Central Credit Unions"
    },
    {
      "code": "522329",
      "description": "Other Financial Transactions Processing and Clearing House Activities"
    },
    {
      "code": "522390",
      "description": "Other Activities Related to Credit Intermediation"
    },
    {
      "code": "523110",
      "description": "Investment Banking and Securities Dealing"
    },
    {
      "code": "523120",
      "description": "Securities Brokerage"
    },
    {
      "code": "523130",
      "description": "Commodity Contracts Dealing"
    },
    {
      "code": "523140",
      "description": "Commodity Contracts Brokerage"
    },
    {
      "code": "523210",
      "description": "Securities and Commodity Exchanges"
    },
    {
      "code": "523910",
      "description": "Miscellaneous Intermediation"
    },
    {
      "code": "523920",
      "description": "Portfolio Management"
    },
    {
      "code": "523930",
      "description": "Investment Advice"
    },
    {
      "code": "523990",
      "description": "All Other Financial Investment Activities"
    },
    {
      "code": "524111",
      "description": "\"Direct Individual Life, Health and Medical Insurance Carriers\""
    },
    {
      "code": "524112",
      "description": "\"Direct Group Life, Health and Medical Insurance Carriers\""
    },
    {
      "code": "524121",
      "description": "Direct General Property and Casualty Insurance Carriers"
    },
    {
      "code": "524122",
      "description": "\"Direct, Private, Automobile Insurance Carriers\""
    },
    {
      "code": "524123",
      "description": "\"Direct, Public, Automobile Insurance Carriers\""
    },
    {
      "code": "524124",
      "description": "Direct Property Insurance Carriers"
    },
    {
      "code": "524125",
      "description": "Direct Liability Insurance Carriers"
    },
    {
      "code": "524129",
      "description": "\"Other Direct Insurance (except Life, Health and Medical) Carriers\""
    },
    {
      "code": "524131",
      "description": "Life Reinsurance Carriers"
    },
    {
      "code": "524132",
      "description": "Accident and Sickness Reinsurance Carriers"
    },
    {
      "code": "524133",
      "description": "Automobile Reinsurance Carriers"
    },
    {
      "code": "524134",
      "description": "Property Reinsurance Carriers"
    },
    {
      "code": "524135",
      "description": "Liability Reinsurance Carriers"
    },
    {
      "code": "524139",
      "description": "General and Other Reinsurance Carriers"
    },
    {
      "code": "524210",
      "description": "Insurance Agencies and Brokerages"
    },
    {
      "code": "524291",
      "description": "Claims Adjusters"
    },
    {
      "code": "524299",
      "description": "All Other Insurance Related Activities"
    },
    {
      "code": "526111",
      "description": "Trusteed Pension Funds"
    },
    {
      "code": "526112",
      "description": "Non-Trusteed Pension Funds"
    },
    {
      "code": "526911",
      "description": "Equity Funds - Canadian"
    },
    {
      "code": "526912",
      "description": "Equity Funds - Foreign"
    },
    {
      "code": "526913",
      "description": "Mortgage Funds"
    },
    {
      "code": "526914",
      "description": "Money Market Funds"
    },
    {
      "code": "526915",
      "description": "Bond and Income / Dividend Funds - Canadian"
    },
    {
      "code": "526916",
      "description": "Bond and Income / Dividend Funds - Foreign"
    },
    {
      "code": "526917",
      "description": "Balanced Funds / Asset Allocation Funds"
    },
    {
      "code": "526919",
      "description": "Other Open-Ended Funds"
    },
    {
      "code": "526930",
      "description": "Segregated (except Pension) Funds"
    },
    {
      "code": "526981",
      "description": "Securitization Vehicles"
    },
    {
      "code": "526989",
      "description": "All Other Miscellaneous Funds and Financial Vehicles"
    },
    {
      "code": "531111",
      "description": "Lessors of Residential Buildings and Dwellings (except Social Housing Projects)"
    },
    {
      "code": "531112",
      "description": "Lessors of Social Housing Projects"
    },
    {
      "code": "531120",
      "description": "Lessors of Non-Residential Buildings (except Mini-Warehouses)"
    },
    {
      "code": "531130",
      "description": "Self-Storage Mini-Warehouses"
    },
    {
      "code": "531190",
      "description": "Lessors of Other Real Estate Property"
    },
    {
      "code": "531211",
      "description": "Real Estate Agents"
    },
    {
      "code": "531212",
      "description": "Offices of Real Estate Brokers"
    },
    {
      "code": "531310",
      "description": "Real Estate Property Managers"
    },
    {
      "code": "531320",
      "description": "Offices of Real Estate Appraisers"
    },
    {
      "code": "531390",
      "description": "Other Activities Related to Real Estate"
    },
    {
      "code": "532111",
      "description": "Passenger Car Rental"
    },
    {
      "code": "532112",
      "description": "Passenger Car Leasing"
    },
    {
      "code": "532120",
      "description": "\"Truck, Utility Trailer and RV (Recreational Vehicle) Rental and Leasing\""
    },
    {
      "code": "532210",
      "description": "Consumer Electronics and Appliance Rental"
    },
    {
      "code": "532220",
      "description": "Formal Wear and Costume Rental"
    },
    {
      "code": "532230",
      "description": "Video Tape and Disc Rental"
    },
    {
      "code": "532290",
      "description": "Other Consumer Goods Rental"
    },
    {
      "code": "532310",
      "description": "General Rental Centres"
    },
    {
      "code": "532410",
      "description": "\"Construction, Transportation, Mining, and Forestry Machinery and Equipment Rental and Leasing\""
    },
    {
      "code": "532420",
      "description": "Office Machinery and Equipment Rental and Leasing"
    },
    {
      "code": "532490",
      "description": "Other Commercial and Industrial Machinery and Equipment Rental and Leasing"
    },
    {
      "code": "533110",
      "description": "Lessors of Non-Financial Intangible Assets (Except Copyrighted Works)"
    },
    {
      "code": "541110",
      "description": "Offices of Lawyers"
    },
    {
      "code": "541120",
      "description": "Offices of Notaries"
    },
    {
      "code": "541190",
      "description": "Other Legal Services"
    },
    {
      "code": "541212",
      "description": "Offices of Accountants"
    },
    {
      "code": "541213",
      "description": "Tax Preparation Services"
    },
    {
      "code": "541215",
      "description": "\"Bookkeeping, Payroll and Related Services\""
    },
    {
      "code": "541310",
      "description": "Architectural Services"
    },
    {
      "code": "541320",
      "description": "Landscape Architectural Services"
    },
    {
      "code": "541330",
      "description": "Engineering Services"
    },
    {
      "code": "541340",
      "description": "Drafting Services"
    },
    {
      "code": "541350",
      "description": "Building Inspection Services"
    },
    {
      "code": "541360",
      "description": "Geophysical Surveying and Mapping Services"
    },
    {
      "code": "541370",
      "description": "Surveying and Mapping (except Geophysical) Services"
    },
    {
      "code": "541380",
      "description": "Testing Laboratories"
    },
    {
      "code": "541410",
      "description": "Interior Design Services"
    },
    {
      "code": "541420",
      "description": "Industrial Design Services"
    },
    {
      "code": "541430",
      "description": "Graphic Design Services"
    },
    {
      "code": "541490",
      "description": "Other Specialized Design Services"
    },
    {
      "code": "541510",
      "description": "Computer Systems Design and Related Services"
    },
    {
      "code": "541611",
      "description": "Administrative Management and General Management Consulting Services"
    },
    {
      "code": "541612",
      "description": "Human Resources Consulting Services"
    },
    {
      "code": "541619",
      "description": "Other Management Consulting Services"
    },
    {
      "code": "541620",
      "description": "Environmental Consulting Services"
    },
    {
      "code": "541690",
      "description": "Other Scientific and Technical Consulting Services"
    },
    {
      "code": "541710",
      "description": "\"Research and Development in the Physical, Engineering and Life Sciences\""
    },
    {
      "code": "541720",
      "description": "Research and Development in the Social Sciences and Humanities"
    },
    {
      "code": "541810",
      "description": "Advertising Agencies"
    },
    {
      "code": "541820",
      "description": "Public Relations Services"
    },
    {
      "code": "541830",
      "description": "Media Buying Agencies"
    },
    {
      "code": "541840",
      "description": "Media Representatives"
    },
    {
      "code": "541850",
      "description": "Display Advertising"
    },
    {
      "code": "541860",
      "description": "Direct Mail Advertising"
    },
    {
      "code": "541870",
      "description": "Advertising Material Distribution Services"
    },
    {
      "code": "541891",
      "description": "Specialty Advertising Distributors"
    },
    {
      "code": "541899",
      "description": "All Other Services Related to Advertising"
    },
    {
      "code": "541910",
      "description": "Marketing Research and Public Opinion Polling"
    },
    {
      "code": "541920",
      "description": "Photographic Services"
    },
    {
      "code": "541930",
      "description": "Translation and Interpretation Services"
    },
    {
      "code": "541940",
      "description": "Veterinary Services"
    },
    {
      "code": "541990",
      "description": "\"All Other Professional, Scientific and Technical Services\""
    },
    {
      "code": "551113",
      "description": "Holding Companies"
    },
    {
      "code": "551114",
      "description": "Head Offices"
    },
    {
      "code": "561110",
      "description": "Office Administrative Services"
    },
    {
      "code": "561210",
      "description": "Facilities Support Services"
    },
    {
      "code": "561310",
      "description": "Employment Placement Agencies and Executive Search Services"
    },
    {
      "code": "561320",
      "description": "Temporary Help Services"
    },
    {
      "code": "561330",
      "description": "Professional Employer Organizations"
    },
    {
      "code": "561410",
      "description": "Document Preparation Services"
    },
    {
      "code": "561420",
      "description": "Telephone Call Centres"
    },
    {
      "code": "561430",
      "description": "Business Service Centres"
    },
    {
      "code": "561440",
      "description": "Collection Agencies"
    },
    {
      "code": "561450",
      "description": "Credit Bureaus"
    },
    {
      "code": "561490",
      "description": "Other Business Support Services"
    },
    {
      "code": "561510",
      "description": "Travel Agencies"
    },
    {
      "code": "561520",
      "description": "Tour Operators"
    },
    {
      "code": "561590",
      "description": "Other Travel Arrangement and Reservation Services"
    },
    {
      "code": "561611",
      "description": "Investigation Services"
    },
    {
      "code": "561612",
      "description": "Security Guard and Patrol Services"
    },
    {
      "code": "561613",
      "description": "Armoured Car Services"
    },
    {
      "code": "561621",
      "description": "Security Systems Services (except Locksmiths)"
    },
    {
      "code": "561622",
      "description": "Locksmiths"
    },
    {
      "code": "561710",
      "description": "Exterminating and Pest Control Services"
    },
    {
      "code": "561721",
      "description": "Window Cleaning Services"
    },
    {
      "code": "561722",
      "description": "Janitorial Services (except Window Cleaning)"
    },
    {
      "code": "561730",
      "description": "Landscaping Services"
    },
    {
      "code": "561740",
      "description": "Carpet and Upholstery Cleaning Services"
    },
    {
      "code": "561791",
      "description": "Duct and Chimney Cleaning Services"
    },
    {
      "code": "561799",
      "description": "All Other Services to Buildings and Dwellings"
    },
    {
      "code": "561910",
      "description": "Packaging and Labelling Services"
    },
    {
      "code": "561920",
      "description": "Convention and Trade Show Organizers"
    },
    {
      "code": "561990",
      "description": "All Other Support Services"
    },
    {
      "code": "562110",
      "description": "Waste Collection"
    },
    {
      "code": "562210",
      "description": "Waste Treatment and Disposal"
    },
    {
      "code": "562910",
      "description": "Remediation Services"
    },
    {
      "code": "562920",
      "description": "Material Recovery Facilities"
    },
    {
      "code": "562990",
      "description": "All Other Waste Management Services"
    },
    {
      "code": "611110",
      "description": "Elementary and Secondary Schools"
    },
    {
      "code": "611210",
      "description": "Community Colleges and C.E.G.E.P.s"
    },
    {
      "code": "611310",
      "description": "Universities"
    },
    {
      "code": "611410",
      "description": "Business and Secretarial Schools"
    },
    {
      "code": "611420",
      "description": "Computer Training"
    },
    {
      "code": "611430",
      "description": "Professional and Management Development Training"
    },
    {
      "code": "611510",
      "description": "Technical and Trade Schools"
    },
    {
      "code": "611610",
      "description": "Fine Arts Schools"
    },
    {
      "code": "611620",
      "description": "Athletic Instruction"
    },
    {
      "code": "611630",
      "description": "Language Schools"
    },
    {
      "code": "611690",
      "description": "All Other Schools and Instruction"
    },
    {
      "code": "611710",
      "description": "Educational Support Services"
    },
    {
      "code": "621110",
      "description": "Offices of Physicians"
    },
    {
      "code": "621210",
      "description": "Offices of Dentists"
    },
    {
      "code": "621310",
      "description": "Offices of Chiropractors"
    },
    {
      "code": "621320",
      "description": "Offices of Optometrists"
    },
    {
      "code": "621330",
      "description": "Offices of Mental Health Practitioners (except Physicians)"
    },
    {
      "code": "621340",
      "description": "\"Offices of Physical, Occupational, and Speech Therapists and Audiologists\""
    },
    {
      "code": "621390",
      "description": "Offices of All Other Health Practitioners"
    },
    {
      "code": "621410",
      "description": "Family Planning Centres"
    },
    {
      "code": "621420",
      "description": "Out-Patient Mental Health and Substance Abuse Centres"
    },
    {
      "code": "621494",
      "description": "Community Health Centres"
    },
    {
      "code": "621499",
      "description": "All Other Out-Patient Care Centres"
    },
    {
      "code": "621510",
      "description": "Medical and Diagnostic Laboratories"
    },
    {
      "code": "621610",
      "description": "Home Health Care Services"
    },
    {
      "code": "621911",
      "description": "Ambulance (except Air Ambulance) Services"
    },
    {
      "code": "621912",
      "description": "Air Ambulance Services"
    },
    {
      "code": "621990",
      "description": "All Other Ambulatory Health Care Services"
    },
    {
      "code": "622111",
      "description": "General (except Paediatric) Hospitals"
    },
    {
      "code": "622112",
      "description": "Paediatric Hospitals"
    },
    {
      "code": "622210",
      "description": "Psychiatric and Substance Abuse Hospitals"
    },
    {
      "code": "622310",
      "description": "Specialty (except Psychiatric and Substance Abuse) Hospitals"
    },
    {
      "code": "623110",
      "description": "Nursing Care Facilities"
    },
    {
      "code": "623210",
      "description": "Residential Developmental Handicap Facilities"
    },
    {
      "code": "623221",
      "description": "Residential Substance Abuse Facilities"
    },
    {
      "code": "623222",
      "description": "Homes for the Psychiatrically Disabled"
    },
    {
      "code": "623310",
      "description": "Community Care Facilities for the Elderly"
    },
    {
      "code": "623991",
      "description": "Transition Homes for Women"
    },
    {
      "code": "623992",
      "description": "Homes for Emotionally Disturbed Children"
    },
    {
      "code": "623993",
      "description": "Homes for the Physically Handicapped or Disabled"
    },
    {
      "code": "623999",
      "description": "All Other Residential Care Facilities"
    },
    {
      "code": "624110",
      "description": "Child and Youth Services"
    },
    {
      "code": "624120",
      "description": "Services for the Elderly and Persons with Disabilities"
    },
    {
      "code": "624190",
      "description": "Other Individual and Family Services"
    },
    {
      "code": "624210",
      "description": "Community Food Services"
    },
    {
      "code": "624220",
      "description": "Community Housing Services"
    },
    {
      "code": "624230",
      "description": "Emergency and Other Relief Services"
    },
    {
      "code": "624310",
      "description": "Vocational Rehabilitation Services"
    },
    {
      "code": "624410",
      "description": "Child Day-Care Services"
    },
    {
      "code": "711111",
      "description": "Theatre (except Musical) Companies"
    },
    {
      "code": "711112",
      "description": "Musical Theatre and Opera Companies"
    },
    {
      "code": "711120",
      "description": "Dance Companies"
    },
    {
      "code": "711130",
      "description": "Musical Groups and Artists"
    },
    {
      "code": "711190",
      "description": "Other Performing Arts Companies"
    },
    {
      "code": "711211",
      "description": "Sports Teams and Clubs"
    },
    {
      "code": "711213",
      "description": "Horse Race Tracks"
    },
    {
      "code": "711218",
      "description": "Other Spectator Sports"
    },
    {
      "code": "711311",
      "description": "Live Theatres and Other Performing Arts Presenters with Facilities"
    },
    {
      "code": "711319",
      "description": "Sports Stadiums and Other Presenters with Facilities"
    },
    {
      "code": "711321",
      "description": "Performing Arts Promoters (Presenters) without Facilities"
    },
    {
      "code": "711322",
      "description": "Festivals without Facilities"
    },
    {
      "code": "711329",
      "description": "Sports Presenters and Other Presenters without Facilities"
    },
    {
      "code": "711410",
      "description": "\"Agents and Managers for Artists, Athletes, Entertainers and Other Public Figures\""
    },
    {
      "code": "711511",
      "description": "\"Independent Artists, Visual Arts\""
    },
    {
      "code": "711512",
      "description": "\"Independent Actors, Comedians and Performers\""
    },
    {
      "code": "711513",
      "description": "Independent Writers and Authors"
    },
    {
      "code": "712111",
      "description": "Non-Commercial Art Museums and Galleries"
    },
    {
      "code": "712115",
      "description": "History and Science Museums"
    },
    {
      "code": "712119",
      "description": "Other Museums"
    },
    {
      "code": "712120",
      "description": "Historic and Heritage Sites"
    },
    {
      "code": "712130",
      "description": "Zoos and Botanical Gardens"
    },
    {
      "code": "712190",
      "description": "Nature Parks and Other Similar Institutions"
    },
    {
      "code": "713110",
      "description": "Amusement and Theme Parks"
    },
    {
      "code": "713120",
      "description": "Amusement Arcades"
    },
    {
      "code": "713210",
      "description": "Casinos (except Casino Hotels)"
    },
    {
      "code": "713291",
      "description": "Lotteries"
    },
    {
      "code": "713299",
      "description": "All Other Gambling Industries"
    },
    {
      "code": "713910",
      "description": "Golf Courses and Country Clubs"
    },
    {
      "code": "713920",
      "description": "Skiing Facilities"
    },
    {
      "code": "713930",
      "description": "Marinas"
    },
    {
      "code": "713940",
      "description": "Fitness and Recreational Sports Centres"
    },
    {
      "code": "713950",
      "description": "Bowling Centres"
    },
    {
      "code": "713990",
      "description": "All Other Amusement and Recreation Industries"
    },
    {
      "code": "721111",
      "description": "Hotels"
    },
    {
      "code": "721112",
      "description": "Motor Hotels"
    },
    {
      "code": "721113",
      "description": "Resorts"
    },
    {
      "code": "721114",
      "description": "Motels"
    },
    {
      "code": "721120",
      "description": "Casino Hotels"
    },
    {
      "code": "721191",
      "description": "Bed and Breakfast"
    },
    {
      "code": "721192",
      "description": "Housekeeping Cottages and Cabins"
    },
    {
      "code": "721198",
      "description": "All Other Traveller Accommodation"
    },
    {
      "code": "721211",
      "description": "RV (Recreational Vehicle) Parks and Campgrounds"
    },
    {
      "code": "721212",
      "description": "Hunting and Fishing Camps"
    },
    {
      "code": "721213",
      "description": "Recreational (except Hunting and Fishing) and Vacation Camps"
    },
    {
      "code": "721310",
      "description": "Rooming and Boarding Houses"
    },
    {
      "code": "722110",
      "description": "Full-Service Restaurants"
    },
    {
      "code": "722210",
      "description": "Limited-Service Eating Places"
    },
    {
      "code": "722310",
      "description": "Food Service Contractors"
    },
    {
      "code": "722320",
      "description": "Caterers"
    },
    {
      "code": "722330",
      "description": "Mobile Food Services"
    },
    {
      "code": "722410",
      "description": "Drinking Places (Alcoholic Beverages)"
    },
    {
      "code": "811111",
      "description": "General Automotive Repair"
    },
    {
      "code": "811112",
      "description": "Automotive Exhaust System Repair"
    },
    {
      "code": "811119",
      "description": "Other Automotive Mechanical and Electrical Repair and Maintenance"
    },
    {
      "code": "811121",
      "description": "\"Automotive Body, Paint and Interior Repair and Maintenance\""
    },
    {
      "code": "811122",
      "description": "Automotive Glass Replacement Shops"
    },
    {
      "code": "811192",
      "description": "Car Washes"
    },
    {
      "code": "811199",
      "description": "All Other Automotive Repair and Maintenance"
    },
    {
      "code": "811210",
      "description": "Electronic and Precision Equipment Repair and Maintenance"
    },
    {
      "code": "811310",
      "description": "Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance"
    },
    {
      "code": "811411",
      "description": "Home and Garden Equipment Repair and Maintenance"
    },
    {
      "code": "811412",
      "description": "Appliance Repair and Maintenance"
    },
    {
      "code": "811420",
      "description": "Reupholstery and Furniture Repair"
    },
    {
      "code": "811430",
      "description": "Footwear and Leather Goods Repair"
    },
    {
      "code": "811490",
      "description": "Other Personal and Household Goods Repair and Maintenance"
    },
    {
      "code": "812114",
      "description": "Barber Shops"
    },
    {
      "code": "812115",
      "description": "Beauty Salons"
    },
    {
      "code": "812116",
      "description": "Unisex Hair Salons"
    },
    {
      "code": "812190",
      "description": "Other Personal Care Services"
    },
    {
      "code": "812210",
      "description": "Funeral Homes"
    },
    {
      "code": "812220",
      "description": "Cemeteries and Crematoria"
    },
    {
      "code": "812310",
      "description": "Coin-Operated Laundries and Dry Cleaners"
    },
    {
      "code": "812320",
      "description": "Dry Cleaning and Laundry Services (except Coin-Operated)"
    },
    {
      "code": "812330",
      "description": "Linen and Uniform Supply"
    },
    {
      "code": "812910",
      "description": "Pet Care (except Veterinary) Services"
    },
    {
      "code": "812921",
      "description": "Photo Finishing Laboratories (except One-Hour)"
    },
    {
      "code": "812922",
      "description": "One-Hour Photo Finishing"
    },
    {
      "code": "812930",
      "description": "Parking Lots and Garages"
    },
    {
      "code": "812990",
      "description": "All Other Personal Services"
    },
    {
      "code": "813110",
      "description": "Religious Organizations"
    },
    {
      "code": "813210",
      "description": "Grant-Making and Giving Services"
    },
    {
      "code": "813310",
      "description": "Social Advocacy Organizations"
    },
    {
      "code": "813410",
      "description": "Civic and Social Organizations"
    },
    {
      "code": "813910",
      "description": "Business Associations"
    },
    {
      "code": "813920",
      "description": "Professional Organizations"
    },
    {
      "code": "813930",
      "description": "Labour Organizations"
    },
    {
      "code": "813940",
      "description": "Political Organizations"
    },
    {
      "code": "813990",
      "description": "Other Membership Organizations"
    },
    {
      "code": "814110",
      "description": "Private Households"
    },
    {
      "code": "911110",
      "description": "Defence Services"
    },
    {
      "code": "911210",
      "description": "Federal Courts of Law"
    },
    {
      "code": "911220",
      "description": "Federal Correctional Services"
    },
    {
      "code": "911230",
      "description": "Federal Police Services"
    },
    {
      "code": "911240",
      "description": "Federal Regulatory Services"
    },
    {
      "code": "911290",
      "description": "Other Federal Protective Services"
    },
    {
      "code": "911310",
      "description": "Federal Labour and Employment Services"
    },
    {
      "code": "911320",
      "description": "Immigration Services"
    },
    {
      "code": "911390",
      "description": "\"Other Federal Labour, Employment and Immigration Services\""
    },
    {
      "code": "911410",
      "description": "Foreign Affairs"
    },
    {
      "code": "911420",
      "description": "International Assistance"
    },
    {
      "code": "911910",
      "description": "Other Federal Government Public Administration"
    },
    {
      "code": "912110",
      "description": "Provincial Courts of Law"
    },
    {
      "code": "912120",
      "description": "Provincial Correctional Services"
    },
    {
      "code": "912130",
      "description": "Provincial Police Services"
    },
    {
      "code": "912140",
      "description": "Provincial Fire-Fighting Services"
    },
    {
      "code": "912150",
      "description": "Provincial Regulatory Services"
    },
    {
      "code": "912190",
      "description": "Other Provincial Protective Services"
    },
    {
      "code": "912210",
      "description": "Provincial Labour and Employment Services"
    },
    {
      "code": "912910",
      "description": "Other Provincial and Territorial Public Administration"
    },
    {
      "code": "913110",
      "description": "Municipal Courts of Law"
    },
    {
      "code": "913120",
      "description": "Municipal Correctional Services"
    },
    {
      "code": "913130",
      "description": "Municipal Police Services"
    },
    {
      "code": "913140",
      "description": "Municipal Fire-Fighting Services"
    },
    {
      "code": "913150",
      "description": "Municipal Regulatory Services"
    },
    {
      "code": "913190",
      "description": "Other Municipal Protective Services"
    },
    {
      "code": "913910",
      "description": "\"Other Local, Municipal and Regional Public Administration\""
    },
    {
      "code": "914110",
      "description": "Aboriginal Public Administration"
    },
    {
      "code": "919110",
      "description": "International and Other Extra-Territorial Public Administration"
    }
  ];
