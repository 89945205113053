import { getFunctions, httpsCallable } from 'firebase/functions';
import { db, app } from '../../firebase'; // Ensure you have your Firebase app initialized

// Initialize Firebase Functions
const functions = getFunctions(app);

// Define a function to call the getDashboardAwardsWithFilters cloud function
export const getUsaAwardDetails = async (awardID) => {

  // Reference to the cloud function
  const getAwardDetails = httpsCallable(functions, 'getUsaAwardDetails');
  
  try {
    // Call the cloud function with the required data
    const result = await getAwardDetails({ awardID });
    
    return result.data.awardDetails; // Assuming the function returns data in result.data
  } catch (error) {
    console.error('Error calling cloud function:', error);
    throw error; // Handle or rethrow the error as needed
  }
};




// Define a function to call the getDashboardAwardsWithFilters cloud function
export const getUsaDeliveryOrders = async (awardID) => {

  // Reference to the cloud function
  const getDeliveryOrders = httpsCallable(functions, 'getUsaDeliveryOrders');
  
  try {
    // Call the cloud function with the required data
    const result = await getDeliveryOrders({ awardID });
    
    return result.data.deliveryOrders; // Assuming the function returns data in result.data
  } catch (error) {
    console.error('Error calling cloud function:', error);
    throw error; // Handle or rethrow the error as needed
  }
};





// Define a function to call the getUsaDeliveryOrderDetail cloud function
export const getUsaDeliveryOrderDetail = async (awardID) => {

  // Reference to the cloud function
  const getDeliveryOrderDetail = httpsCallable(functions, 'getUsaDeliveryOrderDetail');
  
  try {
    // Call the cloud function with the required data
    const result = await getDeliveryOrderDetail({ awardID });
    
    return result.data.deliveryOrderDetail; // Assuming the function returns data in result.data
  } catch (error) {
    console.error('Error calling cloud function:', error);
    throw error; // Handle or rethrow the error as needed
  }
};