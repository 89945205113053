import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; 
import { Box, Typography, Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, CircularProgress } from '@mui/material';
import BreadcrumbsNav from '../components/BreadcrumbsNav'; 
import { getUsaDeliveryOrderDetail } from '../networking/contract/contractCalls'; 

const DeliveryOrderDetail = () => {
  const { contractId, deliveryOrderId } = useParams(); 
  const [awardDetails, setAwardDetails] = useState(null); 
  const [agencyDetails, setAgencyDetails] = useState(null); 
  const [loading, setLoading] = useState(true); 

  const parseAwardDetails = (details) => {
    return {
      contractTitle: details.description || '',
      obligatedAmount:
        sessionStorage.getItem('selectedDashboardTab') === '0'
          ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(details.total_account_outlay)
          : Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(details.total_obligation),
      contractObligatedAmount:
        sessionStorage.getItem('selectedDashboardTab') === '0'
          ? Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(details.total_account_obligation)
          : Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(details.base_exercised_options),
      potentialAwardAmount: Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(details.base_and_all_options),
      startDate: details.period_of_performance.start_date || '',
      orderingPeriodEndDate: details.period_of_performance.end_date || '',
      placeOfPerformance: `${details.place_of_performance.city_name}, ${details.place_of_performance.state_code}`,
    };
  };

  const parseAgencyDetails = (awardDetails) => {
    return {
      agencyName: awardDetails.awarding_agency.toptier_agency.name || '',
      contractType: awardDetails.latest_transaction_contract_data.type_of_contract_pricing_description || '',
      awardType: awardDetails.type_description || '',
      recipient: awardDetails.recipient.recipient_name + '\n' + awardDetails.recipient.location.address_line1 + '\n' + awardDetails.recipient.location.city_name + ', ' + awardDetails.recipient.location.state_code + ' ' + awardDetails.recipient.location.zip5,
      uei: awardDetails.recipient.recipient_uei || '',
      naicsCode: `${awardDetails.latest_transaction_contract_data.naics}: ${awardDetails.latest_transaction_contract_data.naics_description}`,
      psc: `${awardDetails.psc_hierarchy.base_code.code}: ${awardDetails.psc_hierarchy.base_code.description}`,
      submittingAgencyCode: awardDetails.awarding_agency.subtier_agency.code || '',
      subAgency: awardDetails.awarding_agency.subtier_agency.name || '',
      awardOffice: awardDetails.awarding_agency.office_agency_name || '',
      solicitationId: awardDetails.latest_transaction_contract_data.solicitation_identifier || '',
      setAsideType: awardDetails.latest_transaction_contract_data.type_set_aside_description || '',
      solicitationProcedures: awardDetails.latest_transaction_contract_data.solicitation_procedures || '',
      extentCompleted: awardDetails.latest_transaction_contract_data.extent_competed_description || '',
      offersReceived: awardDetails.latest_transaction_contract_data.number_of_offers_received || 0,
    };
  };

  useEffect(() => {
    const fetchDeliveryOrderDetails = async () => {
      try {
        const details = await getUsaDeliveryOrderDetail(deliveryOrderId);
        console.log('Delivery Order Details:', details);

        setAwardDetails(parseAwardDetails(details));
        setAgencyDetails(parseAgencyDetails(details));
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching delivery order details:', error);
        setLoading(false); // Ensure loading is stopped in case of error
      }
    };

    if (deliveryOrderId) {
      fetchDeliveryOrderDetails();
    }
  }, [deliveryOrderId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const formattedRecipient = agencyDetails.recipient.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <BreadcrumbsNav />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6">{deliveryOrderId}</Typography>
            <Typography variant="subtitle1">{awardDetails.contractTitle}</Typography>
          </Box>

          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Award Details
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Obligated Amount</TableCell>
                    <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>{awardDetails.obligatedAmount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Contract Obligated Amount</TableCell>
                    <TableCell>{awardDetails.contractObligatedAmount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Potential Award Amount</TableCell>
                    <TableCell>{awardDetails.potentialAwardAmount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Start Date</TableCell>
                    <TableCell>{awardDetails.startDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Ordering Period End Date</TableCell>
                    <TableCell>{awardDetails.orderingPeriodEndDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Place of Performance</TableCell>
                    <TableCell>{awardDetails.placeOfPerformance}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, mb: 3 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Awarding Agency</TableCell>
                    <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>{agencyDetails.agencyName}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Contract Type</TableCell>
                    <TableCell>{agencyDetails.contractType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Award Type</TableCell>
                    <TableCell>{agencyDetails.awardType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Recipient</TableCell>
                    <TableCell>{formattedRecipient}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>UEI</TableCell>
                    <TableCell>{agencyDetails.uei}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>NAICS Code</TableCell>
                    <TableCell>{agencyDetails.naicsCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>PSC</TableCell>
                    <TableCell>{agencyDetails.psc}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Paper sx={{ p: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Agency Details</TableCell>
                    <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>{agencyDetails.agencyName}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Submitting Agency Identifier Code</TableCell>
                    <TableCell>{agencyDetails.submittingAgencyCode}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Sub-Agency</TableCell>
                    <TableCell>{agencyDetails.subAgency}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Award Office</TableCell>
                    <TableCell>{agencyDetails.awardOffice}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Solicitation ID</TableCell>
                    <TableCell>{agencyDetails.solicitationId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Set-Aside Type</TableCell>
                    <TableCell>{agencyDetails.setAsideType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Solicitation Procedures</TableCell>
                    <TableCell>{agencyDetails.solicitationProcedures}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Extent Completed</TableCell>
                    <TableCell>{agencyDetails.extentCompleted}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Number of Offers Received</TableCell>
                    <TableCell>{agencyDetails.offersReceived}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeliveryOrderDetail;