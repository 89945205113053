import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpOutline from '@mui/icons-material/HelpOutline';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import MyAccountDialog from './MyAccountDialog';
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook
import { sendSupportEmailToAdmin } from '../networking/AccountCalls';

const RightDrawer = () => {
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [supportDialogOpen, setSupportDialogOpen] = useState(false); // For support dialog
  const [accountDialogOpen, setAccountDialogOpen] = useState(false); // For My Account dialog
  const [supportMessage, setSupportMessage] = useState(''); // Support message input
  const { logout, currentUser } = useAuth(); // Get the logout function from AuthContext

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  };

  const handleAccountClick = () => {
    setAccountDialogOpen(true); // Open the account dialog
  };

  const handleCloseAccountDialog = () => {
    setAccountDialogOpen(false); // Close the account dialog
  };

  const handleSignOutClick = () => {
    setDialogOpen(true);
  };

  const handleSignOutConfirm = () => {
    logout(); // Call the logout function
    setDialogOpen(false);
    setOpen(false); // Close the drawer
  };

  const handleSignOutCancel = () => {
    setDialogOpen(false);
  };

  const handleSupportClick = () => {
    setSupportDialogOpen(true); // Open the support dialog
  };

  const handleSupportSend = async () => {
    if (!supportMessage.trim()) {
      alert("Please enter a message."); // Alert if no message is provided
      return;
    }
    try {
      await sendSupportEmailToAdmin(currentUser.email, supportMessage); // Send the email
      alert("Support request sent successfully.");
      setSupportDialogOpen(false); // Close the support dialog
      setSupportMessage(''); // Clear the message input
    } catch (error) {
      console.error("Error sending support request:", error);
      alert("Failed to send support request.");
    }
  };

  const handleSupportCancel = () => {
    setSupportDialogOpen(false); // Close the support dialog
    setSupportMessage(''); // Clear the message input
  };

  const list1 = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={handleAccountClick}>
          <ListItemIcon>
            <ManageAccounts />
          </ListItemIcon>
          <ListItemText primary="My Account" />
        </ListItem>
        <ListItem button onClick={handleSupportClick}>
          <ListItemIcon>
            <HelpOutline />
          </ListItemIcon>
          <ListItemText primary="Request Support" />
        </ListItem>
        <ListItem button onClick={handleSignOutClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
        {/* Add more list items here if needed */}
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {list1}
      </Drawer>

      {/* Confirmation Dialog for Sign Out */}
      <Dialog
        open={dialogOpen}
        onClose={handleSignOutCancel}
        aria-labelledby="sign-out-dialog-title"
        aria-describedby="sign-out-dialog-description"
      >
        <DialogTitle id="sign-out-dialog-title">{"Sign Out"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="sign-out-dialog-description">
            Are you sure you want to sign out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSignOutCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSignOutConfirm} color="primary" autoFocus>
            Sign Out
          </Button>
        </DialogActions>
      </Dialog>

      {/* Support Request Dialog */}
      <Dialog
        open={supportDialogOpen}
        onClose={handleSupportCancel}
        aria-labelledby="support-dialog-title"
      >
        <DialogTitle id="support-dialog-title">{"Request Support"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details of your issue or question, and we will get back to you as soon as possible.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="supportMessage"
            label="Support Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={supportMessage}
            onChange={(e) => setSupportMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSupportCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSupportSend} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* My Account Dialog */}
      <MyAccountDialog open={accountDialogOpen} onClose={handleCloseAccountDialog} />
    </div>
  );
};

export default RightDrawer;