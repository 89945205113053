import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({ searchQuery, setSearchQuery, onSearch }) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      label="Search"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)} // Keep updating the searchQuery state
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setSearchQuery('')} aria-label="clear search">
              <ClearIcon />
            </IconButton>
            <IconButton onClick={onSearch} aria-label="search">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;