import React, { useEffect, useState } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getUsaDeliveryOrders } from '../../networking/contract/contractCalls'; // Import the function

const DeliveryOrders = ({ internalId }) => { // Accept internalId as a prop
  const navigate = useNavigate();
  const [deliveryOrders, setDeliveryOrders] = useState([]); // State to hold delivery orders
  const [loading, setLoading] = useState(true); // State to track loading

  // Effect to fetch delivery orders when the component is shown
  useEffect(() => {
    const fetchDeliveryOrders = async () => {
      try {
        const orders = await getUsaDeliveryOrders(internalId); // Fetch the delivery orders
        console.log(JSON.stringify(orders));
        setDeliveryOrders(orders); // Set the fetched delivery orders
      } catch (error) {
        console.error('Error fetching delivery orders:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchDeliveryOrders(); // Call the function to fetch delivery orders
  }, [internalId]); // Dependency array includes internalId

  const handleDeliveryOrderClick = (contractId, deliveryOrderId) => {
    navigate(`/contract/${contractId}/delivery-order/${deliveryOrderId}`);
  };

  return (
    <Paper sx={{ p: 2 }}>
      {loading ? ( // Show loading indicator while loading
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Delivery Order</TableCell>
                <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Amount</TableCell>
                <TableCell sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>Award Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deliveryOrders.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{ cursor: 'pointer', color: 'blue' }}
                    onClick={() => handleDeliveryOrderClick(internalId, row.award_id)}
                  >
                    {row.award_id}
                  </TableCell>
                  <TableCell>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.obligated_amount)}</TableCell>
                  <TableCell>{row.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default DeliveryOrders;