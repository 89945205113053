import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const RecipientAccordion = ({ recipient, setRecipient, recipientsList, setRecipientsList }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: '#f5f5f5', // Very light gray background color
        }}
      >
        {/* Navy Blue Circle with Item Count */}
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: '#00274D', // Navy blue color
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1, // Margin right to add spacing between the badge and the text
          }}
        >
          <Typography variant="body2" sx={{ color: 'white' }}>
            {recipientsList.length}
          </Typography>
        </Box>

        <Typography>Recipient</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ mb: 2 }}>
          {/* Recipient Input Field and Buttons */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Enter Recipient"
              value={recipient} // Connect input value to state
              onChange={(e) => setRecipient(e.target.value)} // Update state on input change
            />
            <IconButton onClick={() => setRecipient('')} sx={{ ml: 1 }}>
              <ClearIcon />
            </IconButton>
          </Box>
          
          {/* Add Button Below the Input Field */}
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              if (recipient.trim()) { // Check if the input is not empty or just spaces
                setRecipientsList([...recipientsList, recipient.trim()]); // Add to recipients list
                setRecipient(''); // Clear input field after adding
              }
            }}
            sx={{ mb: 2 }}
          >
            Add
          </Button>

          {/* Display the List of Added Recipients */}
          {recipientsList.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {item}
              </Typography>
              <IconButton onClick={() => setRecipientsList(recipientsList.filter((_, i) => i !== index))}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default RecipientAccordion;