import { getFunctions, httpsCallable } from 'firebase/functions';  // Import the necessary functions
import { doc, getDoc, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db, app } from '../firebase';  // Ensure you have your Firebase app initialized

// Initialize Firebase Functions
const functions = getFunctions(app);  // Initialize Firebase Functions with the app


export const getSubscriptionStatus = async (email) => {

  // Prepare the data to be sent to Firebase Function
  const rDict = {
    receiptData: "platform",
    email: email,
    isWeb: true,
  };

  // Call the Firebase Function to validate the receipt
  try {
    const validateReceiptsIOS = httpsCallable(functions, 'validateReceiptsIOS');
    const result = await validateReceiptsIOS({ dataDict: rDict });

    // Extract the data from the function result
    const retDict = result.data;

    // Extract subscription status and description
    const isSubscribed = retDict.data.isSubscribed;
    const description = retDict.data.description;

    console.log("**** description: " + description);

    return isSubscribed; // Return the subscription status
  } catch (error) {
    console.error("Error validating iOS receipt: ", error);
    return null;
  }
};